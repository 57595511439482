import {CheckBoxField, NumberField, SelectField, TextArea, TextField} from "../../../Components/Inputs";
import {GridCell, gridConstants} from "../../../Components/Grid";
import React from "react";
import PropTypes from "prop-types";
import HtmlDisplay from "../../../Components/Display/HtmlDisplay";
import {FORMAT_LIST} from "../../Library/libraryConstants";
import {ORDER_TYPE, PLACE_THE_ORDER_STATUS_LIST} from "../orderConstants";

export const OrderProcessingForm = ({
                                        orderProcessingForm,
                                        orderDetails,
                                        formVisibility
                                    }) => {

    return <>
        {
            formVisibility.canPlaceAnOrder &&
            <>
                <GridCell>
                    <HtmlDisplay html={`<div><h3>Step 1: Place an Order </h3></div>`}/>
                </GridCell>

                <SelectField
                    {...orderProcessingForm.formatOrdered}
                    medium_columns={gridConstants.column.SIX}
                    label={`Medium Requested`}
                    options={FORMAT_LIST}/>

                <SelectField
                    {...orderProcessingForm.orderStatus}
                    medium_columns={gridConstants.column.SIX}
                    label={`Order Status`}
                    options={PLACE_THE_ORDER_STATUS_LIST}/>

                <TextField
                    {...orderProcessingForm.poNumber}
                    medium_columns={gridConstants.column.SIX}/>

                <NumberField
                    {...orderProcessingForm.cost}
                    isPositiveNumberOnly
                    medium_columns={gridConstants.column.SIX}/>
            </>
        }

        {
            formVisibility.canReceiveOrder &&
            <>
                <GridCell>
                    <HtmlDisplay html={`<div><h3>Step 2: Order Fulfillment </h3></div>`}/>
                </GridCell>

                <TextField
                    {...orderProcessingForm.volumesReceived}
                    helpText={"Enter volumes, separated by commas"}
                    medium_columns={gridConstants.column.SIX}/>

                <NumberField
                    {...orderProcessingForm.numberOfVolumesReceived}
                    isWholeNumberOnly
                    isPositiveNumberOnly
                    medium_columns={gridConstants.column.SIX}/>

                {
                    orderDetails.orderType === ORDER_TYPE.SpecialOrderRequest &&
                    <TextArea
                        {...orderProcessingForm.description}
                        medium_columns={gridConstants.column.SIX}/>
                }

                <CheckBoxField
                    {...orderProcessingForm.isOrderComplete}
                    checked={!!orderProcessingForm.isOrderComplete.value}
                    label={"Is order complete."}
                    value={orderProcessingForm.isOrderComplete.value ? "" : "true"}
                />
            </>
        }

    </>;
};

OrderProcessingForm.propTypes = {
    orderProcessingForm: PropTypes.object.isRequired,
    orderDetails: PropTypes.object.isRequired,
    formVisibility: PropTypes.object.isRequired
}