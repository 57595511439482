import PropTypes from "prop-types";
import React from "react";
import {HtmlDisplay} from "../Display";
import {GridCell} from "../Grid";
import {HelpText} from "./HelpText";
import {Notification} from "./Notification";

const InputLayout = ({
                         children,
                         className,
                         disabled,
                         helpText,
                         raiseHelpText,
                         label,
                         labelClass,
                         large_columns,
                         medium_columns,
                         name,
                         error,
                         responseFeedback,
                         showOnlyForSr = false,
                         small_columns,
                     }) => {
    const showForSr = showOnlyForSr ? "show-for-sr" : "";
    return (
        <GridCell large_columns={large_columns} medium_columns={medium_columns} small_columns={small_columns} className={className}>
            {
                label &&
                /* eslint-disable-next-line *//***********LINTER WRONG*************/
               ( <label
                    aria-disabled={disabled}
                    htmlFor={name}
                    className={`${showForSr} ${labelClass}`}
                >
                    <HtmlDisplay html={label} isInline/>
                </label>
                
                
            )
                
            }
            { (helpText && raiseHelpText) && <HelpText name={name} helpText={helpText} responseFeedback={responseFeedback}/>}
            {children}
            { (helpText && !raiseHelpText) && <HelpText name={name} helpText={helpText} responseFeedback={responseFeedback}/>}
            <Notification error={error} name={name} label={label}/>
        </GridCell>
    );
};

export const inputLayoutProps = {
    className: PropTypes.string,
    error: PropTypes.string,
    helpText: PropTypes.string,
    raiseHelpText: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    name: PropTypes.string.isRequired,
};

InputLayout.propTypes = {
    ...inputLayoutProps,
    children: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    large_columns: PropTypes.string,
    medium_columns: PropTypes.string,
    responseFeedback: PropTypes.string,
    showOnlyForSr: PropTypes.bool,
    small_columns: PropTypes.string,
};

export {InputLayout};
