import PropTypes from "prop-types";
import React from "react";
import {HtmlDisplay} from "../../../Components/Display";
import {GridCell, gridConstants, GridContainer} from "../../../Components/Grid";

export const OrderDetailsView = ({
                                     orderDetails
                                 }) => {

    return <>
        <GridContainer>
            <GridCell medium_columns={gridConstants.column.SIX}>
                <HtmlDisplay html={`<b>Details: </b>` + orderDetails.details}/>
            </GridCell>
            <GridCell medium_columns={gridConstants.column.SIX}>
                <HtmlDisplay html={`<b>Shipping: </b>` + orderDetails.shipping}/>
            </GridCell>
        </GridContainer>
    </>;
};

OrderDetailsView.propTypes = {
    orderDetails: PropTypes.object.isRequired
};