import {searchModel} from "../../../Utilities/Search";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {isArrayNullOrEmpty} from "../../../Utilities/Types/arrayUtilities";
import {dynamicSort} from "../../../Utilities/Sort";
import {ORDER_TYPE} from "../orderConstants";

export const orderSearchModel = (userId, orderType = `${ORDER_TYPE.Order}`, itemName = "", studentName = "", shippingLocationId = "", publisherName = "", isbn = "", format = "", districtId = "", patronRole = "") => {
    return Object.assign({}, searchModel(userId.toString(), "itemName", false, "itemName"), {
        itemName,
        orderType,
        studentName,
        shippingLocationId,
        publisherName,
        isbn,
        format,
        districtId,
        patronRole,
        ClearOrderSearch: clearOrderSearch,
        ApplySorting: applySorting,
    });
};

function clearOrderSearch() {
    this.orderType = "";
    this.itemName = "";
    this.studentName = "";
    this.shippingLocationId = "";
    this.publisherName = "";
    this.isbn = "";
    this.format = "";
    this.districtId = "";
    this.patronRole = "";
    this.ClearSearch(false);
}

function applySorting(orderSearchCriteria, orders) {

    if (isNullOrUndefined(orders) || isArrayNullOrEmpty(orders))
        return orders;

    return orders.sort(dynamicSort(orderSearchCriteria.sortByColumn, orderSearchCriteria.sortDescending));
}

