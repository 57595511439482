import React from "react";
import PropTypes from "prop-types";
import {GridCell, GridTable} from "../../Components/Grid";
import {LibraryItemCopyDisplay} from "./LibraryItemCopyDisplay";
import {LibraryItemCopyEdit} from "./LibraryItemCopyEdit";

export const LibraryItemCopy = ({
                                    editCopyId,
                                    handleDone,
                                    isEditing,
                                    itemForm,
                                    setEditCopyId,
                                }) => {

    const handleCancel = () => setEditCopyId("");
    const hasCopies = itemForm?.copies?.value?.length > 0;

    return <>
        <GridCell>
            <GridTable>
                <thead>
                <tr>
                    <th className={`small-cell`}>Copy&nbsp;#</th>
                    <th>Barcode</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th className={`action-cell`}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                    hasCopies &&
                    itemForm?.copies?.value?.map((copy) => {
                        const copyId = copy.copyId;

                        return editCopyId === copyId ?
                            <LibraryItemCopyEdit
                                copy={copy}
                                handleCancel={handleCancel}
                                handleDone={handleDone}
                                key={copyId}
                            /> :
                            <LibraryItemCopyDisplay
                                copy={copy}
                                handleEdit={isEditing ? null : setEditCopyId}
                                key={copyId}
                            />
                    })
                }
                {
                    !hasCopies &&
                    <tr>
                        <td colSpan={5} className={`text-center`}>No inventory yet.</td>
                    </tr>
                }
                </tbody>
            </GridTable>
        </GridCell>
    </>;
};

LibraryItemCopy.propTypes = {
    editCopyId: PropTypes.string.isRequired,
    handleDone: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    itemForm: PropTypes.object.isRequired,
    setEditCopyId: PropTypes.func.isRequired,
};