import {
	apiConstants,
	config,
	downloadBlob,
	mockResolveApiCall,
	requestOptions,
	requestTypes,
} from "../../Utilities/Api";


//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerReportApi {
	static exportReport(reportSearchCriteria) {
		const {reportType, startDate, endDate} = reportSearchCriteria;
		
		return downloadBlob(
			requestOptions(`${config.URL}reports/${reportType}/export?startDate=${startDate}&endDate=${endDate}`,
				requestTypes.GET,
				null,
				apiConstants.successMessage.report.REPORT_DOWNLOADED));
	}

}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockReportApi {
	static exportReport() {
		return mockResolveApiCall(true, apiConstants.successMessage.report.REPORT_DOWNLOADED);
	}

}

const reportApi = config.environment.MODE === config.modes.SERVER ? ServerReportApi : MockReportApi;
export {reportApi};
