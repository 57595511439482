import React from 'react';
import PropTypes from 'prop-types';
import {GridCell, gridConstants} from "../../Components/Grid";
import {Link} from "../Link";
import { icons } from '../../Containers/Constants/tileConstants';


export const Tile = ({
                         children,
                         medium_columns = gridConstants.column.SIX,
                         isClickable = true,
                         label,
                         to,
                         iconName
                     }) => {

    const classes = isClickable ? `tile is-clickable` : `tile`;      
    const icon = icons[iconName];

    return <>
        <GridCell medium_columns={medium_columns}>
            <Link to={to} className={classes}>
                    { 
                    icon && 
                    <img
                    src={icon} 
                    alt={`${label} icon`} 
                    className="tile__icon"
                    />
                }
                <div className={"tile__header"}>
                    {label}
                </div>
                <div className={"tile__content"}>
                    {children}
                </div>
            </Link>
        </GridCell>
    </>;
};

Tile.propTypes = {
    children: PropTypes.any,
    medium_columns : PropTypes.string,
    isClickable: PropTypes.bool,
    label: PropTypes.string,
    to: PropTypes.string,
    iconName: PropTypes.string.isRequired
};
