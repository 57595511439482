import PropTypes from "prop-types";
import React from "react";
import {loanQueueRow} from "../Library/libraryFactory";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import {GridTable} from "../../Components/Grid";
import {LoanQueueRow} from "./LoanQueueRow";

export const LoanQueueTable = ({
                                   handleLoanQueueItem,
                                   handleSetLoanSearchCriteria,
                                   handleRemoveFromQueue,
                                   heading = "Queue",
                                   loanSearchCriteria,
                                   queue,
                               }) => {


    const handleOrderClick = (event) => {
        event.preventDefault();
        let criteria = {...loanSearchCriteria};
        criteria.UpdateSorting(event.target.dataset.id);
        criteria.loadSearchResults = false;
        handleSetLoanSearchCriteria(criteria);
    };

    const isQueueEmpty = isArrayNullOrEmpty(queue);

    return <>
        <header>
            <h2>{heading}</h2>
        </header>
        <GridTable parentClass={`margin--topNone`}>
            <thead>
            <tr>
                <th onClick={handleOrderClick} className="is-clickable" data-id="title">Title</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="patronName">Patron</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="shippingLocationName">Shipping
                    Location
                </th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="studentName">Student</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="status">Status</th>
                <th scope={"col"} className={"align-center"}>
                    Actions
                </th>
            </tr>
            </thead>
            <tbody>
            {
                !isQueueEmpty && queue &&
                queue.map((queuedItem) => {
                    const {catalogItem, queueItemId} = queuedItem;
                    const loan = loanQueueRow({...catalogItem, ...queuedItem});
                    const handleDelete = () => handleRemoveFromQueue(queueItemId);
                    const handleLoan = () => handleLoanQueueItem(queueItemId, loan.loanStatus);
                    return <LoanQueueRow
                        key={queueItemId}
                        handleDelete={handleDelete}
                        handleLoan={handleLoan}
                        loan={loan}
                    />;
                })
            }
            {
                isQueueEmpty &&
                <tr data-testid={`no-order-row`}>
                    <td colSpan={6} className={`text-center`}>No results found.</td>
                </tr>
            }
            </tbody>
        </GridTable>
    </>
        ;
};

LoanQueueTable.propTypes = {
    handleLoanQueueItem: PropTypes.func.isRequired,
    handleSetLoanSearchCriteria: PropTypes.func.isRequired,
    handleRemoveFromQueue: PropTypes.func.isRequired,
    heading: PropTypes.string,
    loanSearchCriteria: PropTypes.object.isRequired,
    queue: PropTypes.array.isRequired,
};