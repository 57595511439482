import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {
    clearInputFormErrorFields,
    convertInputFormToObject,
    createYesNoList, inputFormIsValid
} from "../../../Components/Inputs/inputUtility";
import {
    RadioList,
    SelectField,
    TextField,
    DateTimePickerField,
    CheckBoxField, Button,
} from "../../../Components/Inputs";
import {createListFromArrayOfObjects} from "../../../Utilities/Types/listUtilities";
import {
    VISUALLY_IMPAIRED_STUDENT_PLAN_LIST,
    PERIPHERAL_FIELD_LOSS_LIST,
    EDUCATIONAL_FACILITY_LIST,
    GRADE_LEVEL_LIST,
    PRIMARY_READING_MEDIUM_LIST,
    SECONDARY_READING_MEDIUM_LIST,
    OTHER_READING_MEDIUM_LIST,
    LANGUAGE_LIST,
    COMPLETE_READING_MEDIUMS,
    VISUAL_ACUITY_LIST,
    STUDENT_APPROVAL_STATUS_LIST,
    STUDENT_STATUS_LIST,
    STUDENT_INACTIVE_REASON_LIST,
    STUDENT_APPROVAL_STATUS,
    STUDENT_STATUS,
    STUDENT_ELIGIBILITY_LIST
} from "../studentConstants";
import {GridCell, GridContainer} from "../../../Components/Grid";
import {StudentRegistrationDuplicateStudentsView} from "./StudentRegistrationDuplicateStudentsView";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {StudentRegistrationFormChangeLog} from "./StudentRegistrationFormChangeLog";
import {isFimcAdmin} from "../../../Utilities/LocalStorage/storageUtilities";


export const StudentRegistrationForm = ({
                                            checkAndSetError,
                                            checkForDuplicateStudents,
                                            districts,
                                            duplicateStudents,
                                            formVisibility,
                                            isEditing,
                                            isLoading,
                                            patronDistrict,
                                            patronName,
                                            patronRole,
                                            readOnly,
                                            setWarnings,
                                            studentDetailsForm
                                        }) => {

    const hasCheckedDuplicatesOnce = useRef(false);

    const yesNoList = createYesNoList();

    const studentChangeLogModalProps = {
        isEditing: isEditing,
        inputForm: studentDetailsForm,
    };

    let canEditDistrict = isNullOrUndefined(patronDistrict) || isFimcAdmin();
    let isNotFimcAdmin = !isFimcAdmin();

    const defaultFacility = (() => {
        let facilityValue;

        switch (patronRole) {
            case 1:
                facilityValue = "4";
                break;
            case 2:
                facilityValue = "1";
                break;
            case 3:
                facilityValue = "2";
                break;
            case 4:
                facilityValue = "3";
                break;
            case 5:
                facilityValue = "5";
                break;
            default:
                facilityValue = null;
        }

        return facilityValue
            ? EDUCATIONAL_FACILITY_LIST.find((facility) => facility.value === facilityValue)
            : null;
    })();


    const getReadingMediumOptions = (field, fullOptions) => {
        const allFields = [
            "primaryReadingMedium",
            "secondaryReadingMedium",
            "otherReadingMedium",
        ];
        let options = fullOptions;
        allFields.forEach((fieldName) => {
            if (
                studentDetailsForm[fieldName].value &&
                fieldName !== field &&
                studentDetailsForm[fieldName].value !==
                COMPLETE_READING_MEDIUMS.NotApplicable.id
            ) {
                options = options.filter(
                    (option) => option.value !== studentDetailsForm[fieldName].value
                );
            }
        });

        return options;
    };

    function calculateAge(birthdate) {
        const today = new Date();
        const birthDate = new Date(birthdate.value);
        if (birthDate > today) {
            return "The date you entered is invalid.";
        }

        let ageInYears = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        const dayDifference = today.getDate() - birthDate.getDate();

        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            ageInYears--;
        }

        if (ageInYears > 0) {
            const ageLabel = ageInYears === 1 ? " year" : " years";
            return `This student is ${ageInYears}${ageLabel} old.`;
        }

        const months = (today.getFullYear() - birthDate.getFullYear()) * 12 + monthDifference - (dayDifference < 0 ? 1 : 0);
        const monthLabel = months === 1 ? " month" : " months";
        return `This student is ${months}${monthLabel} old.`;
    }

    const handleApprovalStatusChange = (event) => {

        const updatedStudentDetailsForm = {...studentDetailsForm};

        if (event.target.value === STUDENT_APPROVAL_STATUS.Approved.toString()) {
            updatedStudentDetailsForm.studentStatus.value = STUDENT_STATUS.Active.id;
            updatedStudentDetailsForm.studentInactiveReason.value = null;
        }
        updatedStudentDetailsForm.studentApprovalStatus.setValue(event.target.value);

        studentDetailsForm.setValue({...updatedStudentDetailsForm})
    };

    const handleClickCheckForDuplicateStudents = (event) => {
        event.preventDefault();

        if (isFormValidForDuplicateStudentsCheck()) {
            hasCheckedDuplicatesOnce.current = true;
            const registerModel = convertInputFormToObject(studentDetailsForm);
            checkForDuplicateStudents(registerModel);

            const updatedStudentDetailsForm = {...studentDetailsForm};
            updatedStudentDetailsForm.overrideCheck.value = false;
            studentDetailsForm.setValue({...updatedStudentDetailsForm})
        }
    };

    const isFormValidForDuplicateStudentsCheck = () => {
        clearInputFormErrorFields(studentDetailsForm);

        checkAndSetError([
            studentDetailsForm.lastName,
            studentDetailsForm.firstName,
        ]);

        let isValid = inputFormIsValid(studentDetailsForm);

        if (!isValid)
            setWarnings([
                `${studentDetailsForm.studentStatus.error} Missing required items.  Please review your responses and try submitting again.`,
            ]);

        return isValid;
    };

    useEffect(() => {
        const updatedStudentDetailsForm = {...studentDetailsForm};

        let formChanged = false;

        if (studentDetailsForm.hasSignedParentalConsent.value && studentDetailsForm.understandsNoAphAccess.value) {
            updatedStudentDetailsForm.understandsNoAphAccess.value = false;
            formChanged = true;
        }
        if (!formVisibility.isDualSensoryImpaired && studentDetailsForm.isFaviRegistered.value) {
            updatedStudentDetailsForm.isFaviRegistered.value = "";
            formChanged = true;
        }
        if (formVisibility.isNeuroImpairment && studentDetailsForm.hasVisualImpairment.value) {
            updatedStudentDetailsForm.hasVisualImpairment.value = "";
            formChanged = true;
        }
        if (!formVisibility.isAdGradeLevel && studentDetailsForm.hasInstructionalHours.value) {
            updatedStudentDetailsForm.hasInstructionalHours.value = "";
            formChanged = true;
        }
        if (!formVisibility.isHomeSchooledStudent && studentDetailsForm.hasHomeSchoolRequirementAgreement.value) {
            updatedStudentDetailsForm.hasHomeSchoolRequirementAgreement.value = "";
            formChanged = true;
        }
        if (!formVisibility.isInactive && studentDetailsForm.studentInactiveReason.value) {
            updatedStudentDetailsForm.studentInactiveReason.value = null;
            formChanged = true;
        }

        if (formVisibility.hideReadingMediums) {
            if (studentDetailsForm.secondaryReadingMedium.value !== COMPLETE_READING_MEDIUMS.NotApplicable.id) {
                updatedStudentDetailsForm.secondaryReadingMedium.value = COMPLETE_READING_MEDIUMS.NotApplicable.id;
                formChanged = true;
            }
            if (studentDetailsForm.otherReadingMedium.value !== COMPLETE_READING_MEDIUMS.NotApplicable.id) {
                updatedStudentDetailsForm.otherReadingMedium.value = COMPLETE_READING_MEDIUMS.NotApplicable.id;
                formChanged = true;
            }
        }

        if (!canEditDistrict && !studentDetailsForm.districtId.value) {
            updatedStudentDetailsForm.districtId.value = patronDistrict;
            formChanged = true;
        }

        if (isNotFimcAdmin && !studentDetailsForm.educationalFacility.value) {
            updatedStudentDetailsForm.educationalFacility.value = patronRole;
            formChanged = true;
        }

        if (formChanged) {
            studentDetailsForm.setValue({...updatedStudentDetailsForm})
        }
    }, [studentDetailsForm, formVisibility, formVisibility.hideReadingMediums]);

    return (
        <>
            <RadioList
                {...studentDetailsForm.hasNeurologicalImpairment}
                disabled={readOnly}
                legend={`Does this child have a neurological, cortical, or cerebral VI (e.g. brain injury or dysfunction) as indicated by medical documentation?`}
                options={yesNoList}
            />

            {
                !formVisibility.isNeuroImpairment &&
                !isNullOrUndefined(studentDetailsForm.hasNeurologicalImpairment.value) &&
                <RadioList
                    {...studentDetailsForm.hasVisualImpairment}
                    disabled={readOnly}
                    legend={`Is there a medical report on file indicating a VI?`}
                    options={yesNoList}
                />
            }

            {
                formVisibility.isNotNeuroImpairmentAndMedicalReport &&
                <GridCell>
                    <p className="student-reg --warning text-center">
                        You must select &lsquo;Yes&rsquo; to one of the two previous
                        questions to continue.
                    </p>
                </GridCell>
            }

            {
                formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                <>
                    <StudentRegistrationFormChangeLog
                        {...studentChangeLogModalProps}
                        fieldName={studentDetailsForm.firstName.name}>
                        <TextField {...studentDetailsForm.firstName}
                                   disabled={readOnly}
                                   label={`First Name`}/>
                    </StudentRegistrationFormChangeLog>

                    <StudentRegistrationFormChangeLog
                        {...studentChangeLogModalProps}
                        fieldName={studentDetailsForm.middleName.name}>
                        <TextField {...studentDetailsForm.middleName}
                                   disabled={readOnly}
                                   label={`Middle Name (optional)`}/>
                    </StudentRegistrationFormChangeLog>

                    <StudentRegistrationFormChangeLog
                        {...studentChangeLogModalProps}
                        fieldName={studentDetailsForm.lastName.name}>
                        <TextField
                            {...studentDetailsForm.lastName}
                            disabled={readOnly}
                            label={`Last Name`}/>
                    </StudentRegistrationFormChangeLog>

                    <StudentRegistrationFormChangeLog
                        {...studentChangeLogModalProps}
                        fieldName={studentDetailsForm.dateOfBirth.name}>
                        <DateTimePickerField
                            {...studentDetailsForm.dateOfBirth}
                            disabled={readOnly}
                            label={`Date of Birth`}
                        />
                    </StudentRegistrationFormChangeLog>

                    {
                        formVisibility.hasBirthdate &&
                        <GridCell>
                            <p>
                                <strong>
                                    {calculateAge(studentDetailsForm.dateOfBirth)}

                                </strong>
                            </p>
                        </GridCell>
                    }

                    <StudentRegistrationFormChangeLog
                        {...studentChangeLogModalProps}
                        fieldName={studentDetailsForm.gradeLevel.name}>
                        <SelectField
                            {...studentDetailsForm.gradeLevel}
                            helpText={`<p><b>Infants (IP)</b> are children of preschool age served by infant programs (birth to age 3).<br/> <b> Prekindergarten and Preschool Students (PS) </b> are children of preschool age served by preschool programs. <br/>
 <b> Kindergarten (KG) </b> students are children enrolled in kindergarten classes. <br/><b>Grades 1-11</b> are students of school age as determined by state law, in regular academic grades 1 through 11. Select specific grade placement.<br/><b>Grade 12</b> are students who are in grade 12 or are continuing to receive special education services under the Individuals with Disabilities Education Act (IDEA) until the student turns age 22. <br/><b>Adult Students (AD)</b> are students ages 22 and older by the first Monday in January. They must be reported as adults, even if they are in grade 12. (Note requirement of at least 120 hours of documented educational instruction during the preceding calendar year.)</p>`}
                            label={`Grade Level`}
                            options={GRADE_LEVEL_LIST}
                        />
                    </StudentRegistrationFormChangeLog>

                    {
                        formVisibility.showAgeOverride &&
                        <CheckBoxField
                            {...studentDetailsForm.gradeAgeConfirmation}
                            checked={!!studentDetailsForm.gradeAgeConfirmation.value}
                            disabled={readOnly}
                            label={
                                `I, ${patronName}, hereby confirm that the grade level and age of the student are accurate.`
                            }
                            value={studentDetailsForm.gradeAgeConfirmation.value ? "" : "true"}
                        />
                    }
                </>
            }

            {
                formVisibility.isNeurologicalImpairmentOrIsMedicalReport && isFimcAdmin() &&
                <>
                    <GridContainer className={`horizontal-center`} hasMarginX hasMarginY>
                        <Button
                            isPrimary
                            label={`Check For Existing Student`}
                            name={`btnCheckForExistingStudent`}
                            onClick={handleClickCheckForDuplicateStudents}
                            disabled={isLoading || (!formVisibility.isFormDataChanged && hasCheckedDuplicatesOnce.current) || readOnly}
                        />
                    </GridContainer>

                    {
                        formVisibility.hasDuplicate && !formVisibility.isFormDataChanged && isFimcAdmin() &&
                        <>
                           <GridCell className="duplicates">
                           <CheckBoxField
                                {...studentDetailsForm.overrideCheck}
                                checked={!!studentDetailsForm.overrideCheck.value}
                                disabled={readOnly}
                                label={"I have reviewed the potential duplicates below and the student that I have entered is not a duplicate. Selecting a student from the title below will override the student information you entered."}
                                value={studentDetailsForm.overrideCheck.value ? "" : "true"}
                                
                            />
                           </GridCell>

                            {
                                !formVisibility.isOverrideOrNoDuplicate &&
                                <StudentRegistrationDuplicateStudentsView
                                    duplicateStudents={duplicateStudents}
                                />
                            }
                        </>
                    }
                </>
            }

            {
                ((formVisibility.isOverrideOrNoDuplicate && !formVisibility.isFormDataChanged) || !isFimcAdmin()) &&
                <>
                    {
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <>
                            <StudentRegistrationFormChangeLog
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.writtenPlan.name}>
                                <SelectField
                                    {...studentDetailsForm.writtenPlan}
                                    disabled={readOnly}
                                    label={`Which type of written plan addressing the unique needs of the student with VI is on file?`}
                                    options={VISUALLY_IMPAIRED_STUDENT_PLAN_LIST}
                                />
                            </StudentRegistrationFormChangeLog>

                            <StudentRegistrationFormChangeLog
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.districtId.name}>
                                <SelectField
                                    {...studentDetailsForm.districtId}
                                    disabled={!canEditDistrict}
                                    helpText={`<p class="raiseHelpText">This reflects the district where the student is enrolled and the primary organization responsible for providing educational services.</p>`}
                                    raiseHelpText={true}
                                    label={`District`}
                                    options={createListFromArrayOfObjects(
                                        districts,
                                        "id",
                                        "districtName"
                                    )}
                                    {...(canEditDistrict ? {} : {defaultOption: patronDistrict})}


                                />
                            </StudentRegistrationFormChangeLog>
                        </>
                    }

                    {
                        formVisibility.isMedicalReport &&
                        <>
                            <StudentRegistrationFormChangeLog
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.distanceVisualAcuityRight.name}>
                                <SelectField
                                    {...studentDetailsForm.distanceVisualAcuityRight}
                                    disabled={readOnly}
                                    helpText={`<p class="raiseHelpText">Provide distance visual acuity with correction using Snellen charts or equivalents (20/50, 20/400, NLP, CF, HM, etc.). </p>`}
                                    raiseHelpText={true}
                                    label={`Distance Visual Acuity Right`}
                                    options={VISUAL_ACUITY_LIST}
                                />
                            </StudentRegistrationFormChangeLog>

                            <StudentRegistrationFormChangeLog
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.distanceVisualAcuityLeft.name}>
                                <SelectField
                                    {...studentDetailsForm.distanceVisualAcuityLeft}
                                    disabled={readOnly}
                                    helpText={`<p class="raiseHelpText">Provide distance visual acuity with correction using Snellen charts or equivalents (20/50, 20/400, NLP, CF, HM, etc.). </p>`}
                                    raiseHelpText={true}
                                    label={`Distance Visual Acuity Left`}
                                    options={VISUAL_ACUITY_LIST}
                                />
                            </StudentRegistrationFormChangeLog>

                            <StudentRegistrationFormChangeLog
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.peripheralFieldLoss.name}>
                                <SelectField
                                    {...studentDetailsForm.peripheralFieldLoss}
                                    disabled={readOnly}
                                    helpText={`<p class="raiseHelpText" >If the student has a restricted visual field of 20 degrees or less, enter that information (e.g. VF 20, VF 6) if provided by the medical documentation. This information is essential if both acuities are better than 20/200. If the medical eye report does not indicate any of these peripheral field restrictions, leave this section blank.</p>`}
                                    raiseHelpText={true}
                                    label={`Peripheral Field Loss`}
                                    options={PERIPHERAL_FIELD_LOSS_LIST}
                                />
                            </StudentRegistrationFormChangeLog>
                        </>
                    }

                    {
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <StudentRegistrationFormChangeLog
                            {...studentChangeLogModalProps}
                            fieldName={studentDetailsForm.educationalFacility.name}>
                            <SelectField
                                {...studentDetailsForm.educationalFacility}
                                disabled={readOnly || isNotFimcAdmin}
                                label={`Education Facility Type`}
                                options={EDUCATIONAL_FACILITY_LIST}
                                value={isNotFimcAdmin ? defaultFacility?.value : studentDetailsForm.educationalFacility.value}
                            />
                        </StudentRegistrationFormChangeLog>
                    }

                    {
                        formVisibility.isHomeSchooledStudent &&
                        <RadioList
                            {...studentDetailsForm.hasHomeSchoolRequirementAgreement}
                            disabled={readOnly}
                            legend={`As the parent of a homeschooled student, I have met all the requirements in Section 1002.01, Florida Statutes (F.S.) <a href='http://www.leg.state.fl.us/statutes/index.cfm?mode=View%20Statutes&SubMenu=1&App_mode=Display_Statute&Search_String=1002.01&URL=1000-1099/1002/Sections/1002.01.html' target='_blank'>Student and Parental Rights and Educational Choices</a>.`}
                            options={yesNoList}
                        />
                    }

                    {
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <>
                            <StudentRegistrationFormChangeLog
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.isFlviRegistered.name}>
                                <RadioList
                                    {...studentDetailsForm.isFlviRegistered}
                                    disabled={readOnly}
                                    legend={`Is the student registered with the Florida Low Vision Initiative (FLVI)? Visit the <a href='https://www.livebinders.com/play/play?id=2678832#anchor' target='_blank'>FLORIDA LOW VISION INITIATIVE - LiveBinder</a> to learn more about eligibility and services.`}
                                    options={yesNoList}
                                />
                            </StudentRegistrationFormChangeLog>

                            <StudentRegistrationFormChangeLog
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.isDualSensoryImpaired.name}>
                                <RadioList
                                    {...studentDetailsForm.isDualSensoryImpaired}
                                    disabled={readOnly}
                                    legend={`Does the student have a dual sensory impairment? `}
                                    options={yesNoList}
                                />
                            </StudentRegistrationFormChangeLog>
                        </>
                    }

                    {
                        formVisibility.isDualSensoryImpaired &&
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <StudentRegistrationFormChangeLog
                            {...studentChangeLogModalProps}
                            fieldName={studentDetailsForm.isFaviRegistered.name}>
                            <RadioList
                                {...studentDetailsForm.isFaviRegistered}
                                disabled={readOnly}
                                legend={`Is the student registered with the Florida & Virgin Islands Deafblind Collaborative (FAVI)?  Visit the <a href='https://deafblind.ufl.edu/' target='_blank'>FAVI DeafBlind Collaborative</a> to learn more about their Census and resources.`}
                                options={yesNoList}
                            />
                        </StudentRegistrationFormChangeLog>
                    }

                    {
                        formVisibility.isAdGradeLevel &&
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <StudentRegistrationFormChangeLog
                            {...studentChangeLogModalProps}
                            fieldName={studentDetailsForm.hasInstructionalHours.name}>
                            <RadioList
                                {...studentDetailsForm.hasInstructionalHours}
                                disabled={readOnly}
                                legend={`Was the student registered for at least 120 hours of documented educational instruction during the preceding calendar year? `}
                                options={yesNoList}
                            />
                        </StudentRegistrationFormChangeLog>
                    }

                    {
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <>
                            <StudentRegistrationFormChangeLog
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.primaryReadingMedium.name}>
                                <SelectField
                                    {...studentDetailsForm.primaryReadingMedium}
                                    helpText={`<p> <b>Auditory Readers</b> are students primarily using a reader or auditory materials in their studies.<br/> <b>Braille Readers</b> are students primarily using braille in their studies.<br/><b>Pre-readers</b> are all infants and preschoolers, students working on or toward a readiness level, or older students with reading potential. <br/> <b>Symbolic readers</b> are students that access printed materials with tangible two- or three-dimensional symbols. Examples of these symbols are photographs, line pictures, real objects, miniature objects, partial objects, etc. These symbols may or may not have braille or print attached, but the student needs the additional symbol to access the learning material. <br /> <b>Visual Readers</b> are students primarily using print in their studies.<br /> <br /> <b>Note: All students in infant and preschool programs MUST be reported as pre-readers.</b></p>`}
                                    label={`Primary Reading Medium`}
                                    options={getReadingMediumOptions(
                                        "primaryReadingMedium",
                                        PRIMARY_READING_MEDIUM_LIST
                                    )}
                                />
                            </StudentRegistrationFormChangeLog>

                            {
                                !formVisibility.hideReadingMediums &&
                                <>
                                    <StudentRegistrationFormChangeLog
                                        {...studentChangeLogModalProps}
                                        fieldName={studentDetailsForm.secondaryReadingMedium.name}>
                                        <SelectField
                                            {...studentDetailsForm.secondaryReadingMedium}
                                            label={`Secondary Reading Medium`}
                                            options={getReadingMediumOptions(
                                                "secondaryReadingMedium",
                                                SECONDARY_READING_MEDIUM_LIST
                                            )}
                                        />
                                    </StudentRegistrationFormChangeLog>

                                    <StudentRegistrationFormChangeLog
                                        {...studentChangeLogModalProps}
                                        fieldName={studentDetailsForm.otherReadingMedium.name}>
                                        <SelectField
                                            {...studentDetailsForm.otherReadingMedium}
                                            disabled={readOnly}
                                            label={`Other Reading Medium`}
                                            options={getReadingMediumOptions(
                                                "otherReadingMedium",
                                                OTHER_READING_MEDIUM_LIST
                                            )}
                                        />
                                    </StudentRegistrationFormChangeLog>
                                </>
                            }
                        </>
                    }

                    {
                        isEditing && formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <>
                            {
                                formVisibility.canEditApprovalStatus &&
                                <StudentRegistrationFormChangeLog
                                    {...studentChangeLogModalProps}
                                    fieldName={studentDetailsForm.studentApprovalStatus.name}>
                                    <SelectField
                                        {...studentDetailsForm.studentApprovalStatus}
                                        includeDefaultOption={false}
                                        options={STUDENT_APPROVAL_STATUS_LIST}
                                        onChange={handleApprovalStatusChange}
                                    />
                                </StudentRegistrationFormChangeLog>
                            }

                            {
                                formVisibility.showEligibility &&
                                <SelectField
                                    {...studentDetailsForm.eligibility}
                                    label={`Eligibility`}
                                    options={STUDENT_ELIGIBILITY_LIST}
                                />
                            }

                            <StudentRegistrationFormChangeLog
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.studentStatus.name}>
                                <SelectField
                                    {...studentDetailsForm.studentStatus}
                                    includeDefaultOption={false}
                                    options={STUDENT_STATUS_LIST}
                                />
                            </StudentRegistrationFormChangeLog>

                            {
                                formVisibility.isInactive &&
                                <StudentRegistrationFormChangeLog
                                    {...studentChangeLogModalProps}
                                    fieldName={studentDetailsForm.studentInactiveReason.name}>
                                    <SelectField
                                        {...studentDetailsForm.studentInactiveReason}
                                        includeDefaultOption
                                        options={STUDENT_INACTIVE_REASON_LIST}
                                    />
                                </StudentRegistrationFormChangeLog>
                            }
                        </>
                    }

                    {
                        formVisibility.isNeurologicalImpairmentOrIsMedicalReport &&
                        <>
                            <StudentRegistrationFormChangeLog
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.language.name}>
                                <SelectField
                                    {...studentDetailsForm.language}
                                    disabled={readOnly}
                                    label={`Language`}
                                    options={LANGUAGE_LIST}
                                />
                            </StudentRegistrationFormChangeLog>

                            <StudentRegistrationFormChangeLog
                                {...studentChangeLogModalProps}
                                fieldName={studentDetailsForm.noteToFimc.name}>
                                <TextField
                                    {...studentDetailsForm.noteToFimc}
                                    disabled={readOnly}
                                    helpText={`<p class="raiseHelpText">Please use this field to identify or cross reference if the student is a twin, triplet, etc. Also include any secondary visual factors in this field.</p>`}
                                    raiseHelpText={true}
                                    label={`Note to FIMC-VI (optional)`}
                                />
                            </StudentRegistrationFormChangeLog>

                            <CheckBoxField
                            {...studentDetailsForm.certification}
                            checked={!!studentDetailsForm.certification.value}
                            disabled={readOnly}
                            label={
                                `I, ${patronName}, hereby certify that the information above is accurate and fully documented`
                            }
                            value={studentDetailsForm.certification.value ? "" : "true"}
                        />

                        </>
                    }
                </>
            }
        </>
    );
};

StudentRegistrationForm.propTypes = {
    checkAndSetError: PropTypes.func.isRequired,
    checkForDuplicateStudents: PropTypes.func.isRequired,
    districts: PropTypes.arrayOf(PropTypes.object),
    duplicateStudents: PropTypes.arrayOf(PropTypes.object),
    formVisibility: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    patronDistrict: PropTypes.string,
    patronName: PropTypes.string.isRequired,
    patronRole: PropTypes.number.isRequired,
    readOnly: PropTypes.bool.isRequired,
    setWarnings: PropTypes.func.isRequired,
    studentDetailsForm: PropTypes.object.isRequired
};
