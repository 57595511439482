import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LayoutFullBleed} from "../../Components/Layout";
import {Button, TextField} from "../../Components/Inputs";
import {GridCell, gridConstants, GridContainer} from "../../Components/Grid";
import libraryCatalogApi from "./libraryCatalogApi";
import {catalogItemModel} from "./libraryFactory";
import {useInput} from "../../Components/Inputs/inputUtility";
import {filterCatalog, getSystemTypeTextByValue} from "./libraryUtility";
import {LinkButton} from "../../Components/Link";
import {locations} from "../../Utilities/Location";
import {Allow, policyEvents} from "../../Components/Authorize";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import libraryQueueApi from "./libraryQueueApi";
import {LibraryCatalogItem} from "./LibraryCatalogItem";
import {isObjectNullOrEmpty} from "../../Utilities/Types/objectUtilities";

export const LibraryCatalogContainer = ({
                                            handleApiCall,
                                            handleSetLibrarySearchCriteria,
                                            librarySearchCriteria,
                                            redirect,
                                            systemTypeId,
                                        }) => {
    const [resources, setResources] = useState([]);

    const [filteredResources, setFilteredResources] = useState([]);
    const keywordSearch = useInput("");
    const pageTitle = getSystemTypeTextByValue(systemTypeId);

    const handleEdit = (catalogId, catalogFormatId) => {
        const path = catalogFormatId
            ? locations.LIBRARY_FORMAT.getLink([catalogId, catalogFormatId]).to
            : locations.LIBRARY_ITEM.getLink([catalogId, systemTypeId]).to;
        redirect(path);
    }

    const handleOrderRequest = (catalogId, catalogFormatId) => {
        redirect(locations.ORDER_REQUEST.getLink([catalogId, catalogFormatId]).to);
    }

    const handleSearchCatalog = () => {
        const result = filterCatalog(resources, keywordSearch.value) || [];
        setFilteredResources(result);
    }

    const handleAddToQueue = async (catalogFormatId) => {
        handleApiCall(() => libraryQueueApi.addToQueue(catalogFormatId), getResources);
    }

    const getResources = () => {
        handleApiCall(() => libraryCatalogApi.getResources(librarySearchCriteria), (results) => setResources(results));
    }

    const handleUpdateSystemType = () => {
        if (isObjectNullOrEmpty(librarySearchCriteria)) return;
        const updatedLibrarySearchCriteria = {...librarySearchCriteria};
        updatedLibrarySearchCriteria?.Search({systemType: systemTypeId});
        handleSetLibrarySearchCriteria(updatedLibrarySearchCriteria);
    };

    useEffect(() => {
        if (systemTypeId === librarySearchCriteria.systemType)
            getResources();
        else
            handleUpdateSystemType();

    }, [librarySearchCriteria, systemTypeId]);

    useEffect(() => {
        handleSearchCatalog();
    }, [resources, keywordSearch.value]);

    return (
      <>
        <PageTitle h1={pageTitle} />

        <div className={`searchBox`}>
          <div className={`searchBox__title`}>Search the catalog</div>
          <GridContainer hasMarginX hasMarginY className={`searchBox__input`}>
            <TextField
              labelClass={`show-for-sr`}
              name={`txtSearch`}
              label={`Search`}
              placeholder={`keyword`}
              large_columns={gridConstants.column.TEN}
              medium_columns={gridConstants.column.EIGHT}
              {...keywordSearch}
            />
            <GridCell
              large_columns={gridConstants.column.TWO}
              medium_columns={gridConstants.column.FOUR}
            >
              <Button
                label={`Search`}
                name={`btnSearch`}
                onClick={handleSearchCatalog}
                isPrimary
              />
            </GridCell>
          </GridContainer>
        </div>

        <GridContainer> 
          <GridCell
            className={`margin--topNone margin--bottomNone`}
          >
            <hr className={`margin--topNone`} />
            <LinkButton
              {...locations.LIBRARY_WHATS_NEW.getLink(systemTypeId)}
            />
            <LinkButton {...locations.LIBRARY_QUEUE.getLink(systemTypeId)} />

            <Allow policyEvent={policyEvents.MANAGE_CATALOG}>
              <LinkButton
                {...locations.LIBRARY_ITEM.getLink([undefined, systemTypeId])}
                label={`Add New Item`}
              />
              <LinkButton
                {...locations.LIBRARY_PUBLISHER.getLink([
                  undefined,
                  systemTypeId,
                ])}
              />
              <LinkButton
                {...locations.LIBRARY_VENDOR.getLink([undefined, systemTypeId])}
              />
            </Allow>
          </GridCell>
        </GridContainer>

        <LayoutFullBleed className={`catalog`}>
          <header>
            <h2>Search Results</h2>
          </header>

          {filteredResources.map((resource) => {
            const item = catalogItemModel(resource);
            return (
              <LibraryCatalogItem
                key={item.catalogId}
                handleAddToQueue={handleAddToQueue}
                handleEdit={handleEdit}
                handleOrderRequest={handleOrderRequest}
                {...item}
              />
            );
          })}
          {isArrayNullOrEmpty(filteredResources) && (
            <GridCell
              className={`text-center margin--topNone margin--bottomNone`}
            >
              <p className={`text-center`}>No results.</p>
              <LinkButton
                {...locations.ORDER_REQUEST.link}
                label={`Special Order Request`}
              />
            </GridCell>
          )}
        </LayoutFullBleed>
      </>
    );
};

LibraryCatalogContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    handleSetLibrarySearchCriteria: PropTypes.func.isRequired,
    librarySearchCriteria: PropTypes.object,
    redirect: PropTypes.func.isRequired,
    systemTypeId: PropTypes.string.isRequired,
};