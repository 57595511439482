import {
    apiConstants,
    config,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************

class serverQueueApi {
    static getQueue(loanSearchCriteria)
    {
        const queryParameters = new URLSearchParams(loanSearchCriteria).toString();
        return sendRequest(
            requestOptions(config.URL + `loan/?${queryParameters}`,
                requestTypes.GET,
            ),
        );
    }

    static getDropdownLists(systemTypeId)
    {
        return sendRequest(
            requestOptions(config.URL + `loan/${systemTypeId}/dropdown-lists`,
                requestTypes.GET,
            ),
        );
    }

    static loan(queueId, loanStatus)
    {
        return sendRequest(
            requestOptions(config.URL + `loan/` + queueId,
                requestTypes.POST,
                null,
                apiConstants.successMessage.loan.PROCESS + `"${loanStatus}".`,
            ),
        );
    }
}

const libraryQueueApi = config.environment.MODE === config.modes.SERVER ? serverQueueApi : serverQueueApi;
export default libraryQueueApi;