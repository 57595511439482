import React from "react";
import PropTypes from "prop-types";
import {
    ButtonLinkStyle,
} from "../../../Components/Inputs";
import HtmlDisplay from "../../../Components/Display/HtmlDisplay";
import GridContainer from "../../../Components/Grid/GridContainer";
import {gridConstants} from "../../../Components/Grid";
import GridCell from "../../../Components/Grid/GridCell";

export const OrderPrintBarcode = ({
                                      barcodes
                                  }) => {

    const handlePrintBarcodeClick = () => {
    }

    return <>
        {
            barcodes.map((barcode, index) => {
                return <GridCell key={`${barcode}-${index}`}>
                    <GridContainer
                        medium_columns={gridConstants.column.TWELVE}>
                        <HtmlDisplay html={`<b>` + barcode + `</b>&nbsp;&nbsp;`}/>
                        <ButtonLinkStyle
                            isPrimary isCollapsed
                            label={'Print Barcode'}
                            name={`btnPrintBarcode`}
                            onClick={handlePrintBarcodeClick}/>
                    </GridContainer>
                </GridCell>;
            })
        }
    </>;
};

OrderPrintBarcode.propTypes = {
    barcodes: PropTypes.array.isRequired,
}