import {createArrayFromObject} from "../Types/arrayUtilities";
import {createLocation} from "./locationUtilities";
import {SYSTEM_TYPES} from "../../Containers/Library/libraryConstants";
import {getSystemTypeTextByValue} from "../../Containers/Library/libraryUtility";

export const locationBehaviors = {
    doNotRedirectBack: "doNotRedirectBack",
    redirectToDashboard: "redirectToDashboardIfAuthenticated",
    requiresAuthentication: "requiresAuthentication",
    requiresAdminAuthentication: "requiresAdminAuthentication",
};

export const params = {
    libraryCatalogFormatId: ":catalogFormatId",
    libraryCatalogItemId: ":catalogItemId",
    libraryPublisherId: ":libraryPublisherId",
    libraryVendorId: ":libraryVendorId",
    libraryCatalogFormat: ":format",
    orderId: ":orderId",
    patronId: ":patronId",
    studentId: ":studentId",
    systemTypeId: ":systemTypeId",
    updatePasswordToken: ":updatePasswordToken",
}

export const locations = {

    // GENERAL LOCATIONS
    LANDING: createLocation(() => `/home`, `Home`, []),
    REGISTER: createLocation(() => `/register`, `Register`, []),
    SIGN_IN: createLocation(() => `/sign-in`, `Sign in`, [locationBehaviors.redirectToDashboard]),
    SIGN_OUT: createLocation(() => `/goodbye`, `Sign out`, [locationBehaviors.doNotRedirectBack]),
    DASHBOARD: createLocation(() => `/dashboard`, `Dashboard`, [locationBehaviors.requiresAuthentication]),

    //Library
    LIBRARY_CATALOG: createLocation((systemTypeId = params.systemTypeId) => `/library-catalog/${systemTypeId}`, `Library Catalog`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_TEXTBOOKS: createLocation(() => `/library-catalog/${SYSTEM_TYPES.Textbooks}`, `${getSystemTypeTextByValue(SYSTEM_TYPES.Textbooks)}`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_LOAN: createLocation(() => `/library-catalog/${SYSTEM_TYPES.LoanLibrary}`, `${getSystemTypeTextByValue(SYSTEM_TYPES.LoanLibrary)}`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_APH: createLocation(() => `/library-catalog/${SYSTEM_TYPES.APH}`, `${getSystemTypeTextByValue(SYSTEM_TYPES.APH)}`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_BRAILLERS: createLocation(() => `/library-catalog/${SYSTEM_TYPES.BrailleMachines}`, `${getSystemTypeTextByValue(SYSTEM_TYPES.BrailleMachines)}`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_FORMAT: createLocation((catalogItemId = params.libraryCatalogItemId, catalogFormatId = params.libraryCatalogFormatId) => `/library-item/${catalogItemId}/library-format/${catalogFormatId}`, `Library Item`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_ITEM: createLocation((catalogItemId = params.libraryCatalogItemId, systemTypeId = params.systemTypeId) => `/library-item/${catalogItemId}/${systemTypeId}`, `Library Item`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_PUBLISHER: createLocation((libraryPublisherId = params.libraryPublisherId, systemTypeId = params.systemTypeId) => `/library-publisher/${libraryPublisherId}/${systemTypeId}`, `Manage Publishers`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_QUEUE: createLocation((systemTypeId = params.systemTypeId) => `/library-queue/${systemTypeId}`, `Queue`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_VENDOR: createLocation((libraryVendorId = params.libraryVendorId, systemTypeId = params.systemTypeId) => `/library-vendor/${libraryVendorId}/${systemTypeId}`, `Manage Vendors`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_WHATS_NEW: createLocation((systemTypeId = params.systemTypeId) => `/library-new/${systemTypeId}`, `What's New`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_WHATS_NEW_TEXTBOOKS: createLocation(() => `/library-new/${SYSTEM_TYPES.Textbooks}`, `What's New`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_WHATS_NEW_LOAN: createLocation(() => `/library-new/${SYSTEM_TYPES.LoanLibrary}`, `What's New`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_WHATS_NEW_APH: createLocation(() => `/library-new/${SYSTEM_TYPES.APH}`, `What's New`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_WHATS_NEW_BRAILLERS: createLocation(() => `/library-new/${SYSTEM_TYPES.BrailleMachines}`, `What's New`, [locationBehaviors.requiresAuthentication]),

    //Loan
    LOAN_MANAGEMENT: createLocation(() => `/loans/`, `Loan Management`, [locationBehaviors.requiresAuthentication]),
    CHECK_IN: createLocation(() => `/check-in/`, `Check In`, [locationBehaviors.requiresAuthentication]),

    //Order
    ORDER_REQUEST: createLocation((catalogItemId = params.libraryCatalogItemId, catalogFormatId = params.libraryCatalogFormatId) => `/order-request/${catalogItemId}/${catalogFormatId}`, `Order Request`, [locationBehaviors.requiresAuthentication]),
    ORDER_VIEW: createLocation((orderId = params.orderId, catalogItemId = params.libraryCatalogItemId) => `/order-view/${orderId}/${catalogItemId}`, `Order View`, [locationBehaviors.requiresAuthentication]),
    ORDER_MANAGEMENT: createLocation(() => `/order-management`, `Order Management`, [locationBehaviors.requiresAuthentication]),
    ORDER_PROCESSING: createLocation((orderId = params.orderId) => `/order-processing/${orderId}`, `Order Request`, [locationBehaviors.requiresAuthentication]),
    SHIPPING_LOCATION_ADD: createLocation(() => `/shipping-location-add`, `Add Shipping Location`, [locationBehaviors.requiresAuthentication]),

    //Passwords
    FORGOT_PASSWORD: createLocation(() => `/forgot-password`, `Forgot Password`, [locationBehaviors.redirectToDashboard]),
    UPDATE_PASSWORD: createLocation((updatePasswordToken = params.updatePasswordToken) => `/update-password/${updatePasswordToken}`, `Update Password`, [locationBehaviors.redirectToDashboard]),

    //User
    USER_MANAGEMENT: createLocation(() => `/user-management`, `User Management`, [locationBehaviors.requiresAuthentication]),
    USER_PROFILE: createLocation((patronId = params.patronId) => `/user-profile/${patronId}`, `User Profile`, [locationBehaviors.requiresAuthentication]),

    //Student
    REGISTER_STUDENT: createLocation(() => `/register-student/new`, `Register Student`, [locationBehaviors.requiresAuthentication]),
    EDIT_STUDENT: createLocation((studentId = params.studentId) => `/edit-student/${studentId}`, `Edit Student`, [locationBehaviors.requiresAuthentication]),
    STUDENT_MANAGEMENT: createLocation(() => `/student-management`, `Student Management`, [locationBehaviors.requiresAuthentication]),

    //Reports
    REPORTS: createLocation(() => `/reports`, `Reports`, [locationBehaviors.requiresAuthentication]),

    // Sad paths
    NOT_AUTHORIZED: createLocation(() => `/notAuthorized`, `Not Authorized`, [locationBehaviors.requiresAuthentication, locationBehaviors.doNotRedirectBack]),
    NOT_FOUND: createLocation(() => `/pageNotFound`, `Not Found`, [locationBehaviors.doNotRedirectBack]),

};

export const allLocations = createArrayFromObject(locations);

