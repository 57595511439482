import PropTypes from "prop-types";
import React from "react";
import {HtmlDisplay} from "../Display";
import {GridCell} from "../Grid";
import {HelpText} from "./HelpText";
import {Notification} from "./Notification";
import {notificationSuffix, helpTextSuffix} from "./inputConstants";

export const FieldsetLayout = ({
                                   children,
                                   className="",
                                   disabled,
                                   hasMultipleInputs,
                                   helpText,
                                   isInline,
                                   legend,
                                   legendClass,
                                   name,
                                   error,
                                   responseFeedback,
                               }) => {

    const setClasses = `${isInline ? "fieldset--inline" : ""} ${className}`;
    const legendClasses = `${hasMultipleInputs ? "" : "label"} ${legendClass}`;

    return (
        <GridCell>
            <fieldset
                className={setClasses}
                disabled={disabled}
                aria-invalid={!!error}
                aria-errormessage={`${name}${notificationSuffix}`}
                aria-describedby={`${name}${helpTextSuffix}`}
            >
                <legend className={legendClasses}>
                    <HtmlDisplay html={legend} isInline/>
                </legend>
                {children}
            </fieldset>
            <HelpText name={name} helpText={helpText} responseFeedback={responseFeedback}/>
            <Notification error={error} name={name} label={legend}/>
        </GridCell>
    );
};
export const fieldsetLayoutProps = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    hasMultipleInputs: PropTypes.bool,
    helpText: PropTypes.any,
    isInline: PropTypes.bool,
    legend: PropTypes.string.isRequired,
    legendClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    responseFeedback: PropTypes.string,
    listHasTextField: PropTypes.array,
};

FieldsetLayout.propTypes = {
    children: PropTypes.any,
    ...fieldsetLayoutProps,
};