import React, {createContext, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
    desistLogin,
    getLoggedInUser,
    isAdmin,
    isLoggedIn,
    persistLogin
} from "../../../Utilities/LocalStorage/storageUtilities";
import {isObjectNullOrEmpty} from "../../../Utilities/Types/objectUtilities";
import {userSearchModel} from "../../../Containers/User/userFactorySearch";
import {isValidUser} from "../../../Containers/User/userUtilities";
import {userModel} from "../../../Containers/User/userFactory";
import {studentSearchModel} from "../../../Containers/Student/studentSearchFactory";
import userApi from "../../../Containers/User/userApi";
import {useAppContext} from "./AppContext";
import {orderSearchModel} from "../../../Containers/Order/OrderManagement/orderSearchFactory";
import {loanSearchModel} from "../../../Containers/Loan/loanSearchFactory";
import {librarySearchModel} from "../../../Containers/Library/librarySearchFactory";

export const UserContext = createContext({});
export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({children}) => {
    const [user, setUser] = useState(userModel());
    const {handleApiCall} = useAppContext();

    const isUserAdmin = isAdmin(user);
    const isUserLoggedIn = isLoggedIn();
    const userId = getLoggedInUser();
    const searchProps = [userId];

    const updateSearchCriteria = (userId, searchCriteria, setSearchCriteria, searchModel) => {
        if (!userId) return;

        const searchCriteriaUserId = searchCriteria ? searchCriteria.pageId : userId.toString();

        if ((isObjectNullOrEmpty(searchCriteria) || searchCriteria.pageId !== searchCriteriaUserId)) {
            setSearchCriteria(searchModel(userId));
        }
    }

    const [userSearchCriteria, setUserSearchCriteria] = useState(null);
    const handleSetUserSearchCriteria = (criteria) => setUserSearchCriteria(criteria);
    const updateUserSearchCriteria = () => updateSearchCriteria(...searchProps, userSearchCriteria,
        setUserSearchCriteria, userSearchModel);

    const [studentSearchCriteria, setStudentSearchCriteria] = useState(null);
    const handleSetStudentSearchCriteria = (criteria) => setStudentSearchCriteria(criteria);
    const updateStudentSearchCriteria = () => updateSearchCriteria(...searchProps, studentSearchCriteria,
        setStudentSearchCriteria, studentSearchModel);

    const [orderSearchCriteria, setOrderSearchCriteria] = useState(null);
    const handleSetOrderSearchCriteria = (criteria) => setOrderSearchCriteria(criteria);
    const updateOrderSearchCriteria = () => updateSearchCriteria(...searchProps, orderSearchCriteria,
        setOrderSearchCriteria, orderSearchModel);

    //loan
    const [loanSearchCriteria, setLoanSearchCriteria] = useState({});
    const handleSetLoanSearchCriteria = (criteria) => setLoanSearchCriteria(criteria);
    const updateLoanSearchCriteria = () => updateSearchCriteria(...searchProps, loanSearchCriteria,
        setLoanSearchCriteria, loanSearchModel);

    //library
    const [librarySearchCriteria, setLibrarySearchCriteria] = useState({});
    const handleSetLibrarySearchCriteria = (criteria) => setLibrarySearchCriteria(criteria);
    const updateLibrarySearchCriteria = () => updateSearchCriteria(...searchProps, librarySearchCriteria,
        setLibrarySearchCriteria, librarySearchModel);


    const setUserIfValid = (user) => {
        user = userModel(user);
        if (isValidUser(user)) {
            setUser(user);
            return true;
        }

        return false;
    };

    const setLoggedInFalse = () => {
        desistLogin();
        setUser(userModel());
        return false;
    };

    const setLoggedInTrue = (user) => {
        if (!isValidUser(user))
            return setLoggedInFalse();
        persistLogin(user.userId, user.email, user.fullName, user.roleId, user.canAssignDelegate);
        setUser(userModel());

        if (user.notifier)
            user.notifier.notify();

        return true;
    };

    const fetchUser = () => {
        //todo update once the api is available and get with the team to inform about the need for this api
        return handleApiCall(userApi.getUser, setUserIfValid, setLoggedInFalse);
    };


    useEffect(() => {
        updateOrderSearchCriteria();
        updateLibrarySearchCriteria();
        updateLoanSearchCriteria();
        updateStudentSearchCriteria();
        updateUserSearchCriteria();
        // eslint-disable-next-line
    }, [userId]);


    return (
        <UserContext.Provider
            value={{
                fetchUser,
                handleSetLibrarySearchCriteria,
                handleSetLoanSearchCriteria,
                handleSetOrderSearchCriteria,
                handleSetStudentSearchCriteria,
                handleSetUserSearchCriteria,
                isAdmin: isUserAdmin,
                isLoggedIn: isUserLoggedIn,
                setLoggedInFalse,
                setLoggedInTrue,
                librarySearchCriteria,
                loanSearchCriteria,
                orderSearchCriteria,
                studentSearchCriteria,
                user,
                userId,
                userSearchCriteria
            }}>
            {children}
        </UserContext.Provider>
    )
};

UserContextProvider.propTypes = {
    children: PropTypes.any.isRequired,
};