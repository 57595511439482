import React from 'react';
import PropTypes from 'prop-types';
import {Tile} from "../../Components/Tile/Tile";

export const DashboardTile = ({
                                  description,
                                  label,
                                  to,
                                  iconName,
                              }) => {
    return <>
        <Tile to={to} label={label} iconName={iconName}>
            <p>{description}</p>
        </Tile>
    </>;
};

DashboardTile.propTypes = {
    description: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    iconName: PropTypes.string.isRequired
};
