import PropTypes from "prop-types";
import {PageTitle} from "../../../Components/Landmarks";
import React, {useEffect, useState} from "react";
import {isGuidEmpty, isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {OrderProcessingView} from "./OrderProcessingView";
import {OrderDetailsView} from "./OrderDetailsView";
import OrderProcessingApi from "./orderProcessingApi";
import {
    order, placeAnOrderModel, receiveOrderModel
} from "./orderProcessingFactory";

export const OrderProcessingContainer = ({
                                             orderId,
                                             handleApiCall,
                                             handleReturnRedirect
                                         }) => {

    const [orderDetails, setOrderDetails] = useState(null);

    const handleReturn = () => handleReturnRedirect();

    const handlePlaceAnOrder = async (placeAnOrderDetails) => {

        const sanitizedModel = placeAnOrderModel(placeAnOrderDetails);
        if (orderDetails.alternateFormat !== sanitizedModel.alternateFormat)
            sanitizedModel.isAlternateFormatOrdered = true;

        handleApiCall(() => OrderProcessingApi.placeAnOrder(sanitizedModel), loadOrderResult);
    };

    const handleReceiveOrder = async (receiveOrderDetails) => {

        const sanitizedModel = receiveOrderModel(receiveOrderDetails);

        handleApiCall(() => OrderProcessingApi.receiveOrder(sanitizedModel), loadOrderResult);
    };

    const handleFulfillOrder = async () => {
        handleApiCall(() => OrderProcessingApi.fulfillOrder(orderDetails.orderId), loadOrderResult);
    };

    const loadOrderResult = (result) => {
        const orderDetails = order(result);
        setOrderDetails(orderDetails);
    };

    useEffect(() => {

        const getOrderDetails = () => handleApiCall(() => OrderProcessingApi.getOrder(orderId), loadOrderResult);

        if (orderId && !isGuidEmpty(orderId))
            getOrderDetails();

    }, [orderId]);


    return <>
        {
            !isNullOrUndefined(orderDetails) &&
            <>
                <PageTitle h1={`Process Order`}/>
                <OrderDetailsView orderDetails={orderDetails}/>
                <OrderProcessingView
                    orderDetails={orderDetails}
                    handleReturn={handleReturn}
                    handlePlaceAnOrder={handlePlaceAnOrder}
                    handleFulfillOrder={handleFulfillOrder}
                    handleReceiveOrder={handleReceiveOrder}/>
            </>
        }

    </>;
};

OrderProcessingContainer.propTypes = {
    orderId: PropTypes.string.isRequired,
    handleApiCall: PropTypes.func.isRequired,
    handleReturnRedirect: PropTypes.func.isRequired
};