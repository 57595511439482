import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import StudentRegistrationApi from "./studentRegistrationApi";
import {StudentRegistrationView} from "./StudentRegistrationView";
import {PageTitle} from "../../../Components/Landmarks";
import {locations} from "../../../Utilities/Location";
import {studentDetailsModel} from "../studentDetailsFactory";
import StudentApi from "../studentApi";
import {isObjectNullOrEmpty} from "../../../Utilities/Types/objectUtilities";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {studentModel} from "../StudentApproval/studentApprovalFactory";
import {NO_API_RESULTS} from "../studentConstants";
import {isCensusMonthAndNotAdmin} from "../studentUtilities";
import StudentCensusApi from "../studentCensusApi";

export const StudentRegistrationContainer = ({
                                                 handleApiCall,
                                                 isLoading,
                                                 setWarnings,
                                                 districts,
                                                 redirect,
                                                 user,
                                                 studentId
                                             }) => {

    const [studentDetails, setStudentDetails] = useState(null);
    const [previousStudentDetails, setPreviousStudentDetails] = useState(null);
    const [duplicateStudents, setDuplicateStudents] = useState(NO_API_RESULTS);
    const [censusEndDate, setCensusEndDate] = useState([]);

    const isEditing = !isNullOrUndefined(studentId);
    const readOnly = isCensusMonthAndNotAdmin(censusEndDate);

    const handleRegisterStudent = async (registerModel) => {
        if (!isEditing)
            registerModel.userId = user.userId;

        const sanitizedModel = studentDetailsModel(registerModel);

        if (!isEditing)
            handleApiCall(() => StudentRegistrationApi.attemptRegister(sanitizedModel), () => {
                redirect(locations.STUDENT_MANAGEMENT.path);
            });
        else {
            handleApiCall(() => StudentRegistrationApi.updateStudent(sanitizedModel), () => {
                redirect(locations.STUDENT_MANAGEMENT.path);
            });
        }
    };

    const checkForDuplicateStudents = async (registerModel) => {
        setPreviousStudentDetails(registerModel);
        setDuplicateStudents(null);
        const sanitizedModel = studentDetailsModel(registerModel);

        handleApiCall(() => StudentRegistrationApi.getDuplicateStudents(sanitizedModel), loadDuplicateStudents);
    }

    const loadDuplicateStudents = (result) => {
        if (result !== null)
            setDuplicateStudents(result.map(studentModel));
    }

    const loadStudentDetailsResult = (result) => {
        const studentDetails = studentDetailsModel({
            ...result,
            studentId: result.id,
            isEligible: true,
        });
        setStudentDetails(studentDetails);
        setPreviousStudentDetails(studentDetails);

    };

    useEffect(() => {
        const loadStudentDetails = () => {
            handleApiCall(() => StudentApi.getStudentDetails(studentId), loadStudentDetailsResult)
        };

        handleApiCall(() => StudentCensusApi.getManualCensusEndDate(), setCensusEndDate);

        if (isEditing) {
            setDuplicateStudents([]);
            loadStudentDetails();
        } else {
            setStudentDetails(studentDetailsModel());
            setPreviousStudentDetails(null);
        }

        // eslint-disable-next-line
    }, [studentId]);

    if (!districts || !user)
        return null;

    return <>
        <PageTitle h1={`Register Student`}/>

        {!isObjectNullOrEmpty(studentDetails) &&
            <StudentRegistrationView
                districts={districts}
                duplicateStudents={duplicateStudents}
                handleRegisterStudent={handleRegisterStudent}
                isEditing={isEditing}
                isLoading={isLoading}
                checkForDuplicateStudents={checkForDuplicateStudents}
                patronDistrict={user.districtId}
                patronName={user.fullName}
                patronRole={user.patronRole}
                previousStudentDetails={previousStudentDetails}
                readOnly={readOnly}
                redirect={redirect}
                setWarnings={setWarnings}
                studentDetails={studentDetails}
            />
        }
    </>;
};

StudentRegistrationContainer.propTypes = {
    districts: PropTypes.array,
    handleApiCall: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
    user: PropTypes.object,
    studentId: PropTypes.string,
};
