import React from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import {locations} from "../../Utilities/Location";
import {DashboardContainer} from "../../Containers/Dashboard/";
import {LandingContainer} from "../../Containers/Landing/";
import {
    LibraryCatalogContainer,
    LibraryCatalogWhatsNewContainer,
    LibraryItemFormatContainer,
    LibraryItemContainer,
    LibraryPublisherContainer,
    LibraryQueueContainer,
    LibraryVendorContainer
} from "../../Containers/Library";
import {NotFoundContainer} from "../../Containers/NotFound/";
import {PageSetup} from "../../Containers/PageSetup/";
import {PasswordRequestContainer, PasswordUpdateContainer} from "../../Containers/Password";
import {RegisterContainer} from "../../Containers/Register/";
import {ReportsContainer} from "../../Containers/Reports";
import {SignInContainer} from "../../Containers/SignIn/";
import {SignOutContainer} from "../../Containers/SignOut/";
import {StudentApprovalContainer, StudentRegistrationContainer} from "../../Containers/Student/";
import {UserApprovalContainer, UserProfileContainer} from "../../Containers/User/";
import { OrderManagementContainer, OrderProcessingContainer, OrderRequestContainer, ShippingLocationAddContainer} from "../../Containers/Order/";
import {LoanQueueContainer} from "../../Containers/Loan";
import { CheckInContainer } from "../../Containers/CheckIn";


const SiteRoutes = () => {

    const Dashboard = <PageSetup component={DashboardContainer} path={locations.DASHBOARD.path} showSignOutLink/>;
    const LandingPage = <PageSetup component={LandingContainer} path={locations.LANDING.path} showSignOutLink
                                   showDashboardLink/>;

    //LIBRARY
    const LibraryCatalogWhatsNewPage = <PageSetup component={LibraryCatalogWhatsNewContainer}
                                                  path={locations.LIBRARY_CATALOG.path}
                                                  showSignOutLink showCatalogLink/>;
    const LibraryCatalogPage = <PageSetup component={LibraryCatalogContainer} path={locations.LIBRARY_CATALOG.path}
                                          showSignOutLink showDashboardLink/>;
    const LibraryFormatPage = <PageSetup component={LibraryItemFormatContainer} path={locations.LIBRARY_FORMAT.path}
                                         showSignOutLink showCatalogLink/>;
    const LibraryItemPage = <PageSetup component={LibraryItemContainer} path={locations.LIBRARY_ITEM.path}
                                       showSignOutLink showCatalogLink/>;
    const LibraryPublisherPage = <PageSetup component={LibraryPublisherContainer}
                                            path={locations.LIBRARY_PUBLISHER.path}
                                            showSignOutLink showCatalogLink/>;
    const LibraryQueuePage = <PageSetup component={LibraryQueueContainer} path={locations.LIBRARY_QUEUE.path}
                                        showSignOutLink showCatalogLink/>;
    const LibraryVendorPage = <PageSetup component={LibraryVendorContainer} path={locations.LIBRARY_VENDOR.path}
                                         showSignOutLink showCatalogLink/>;
    //LOAN
    const LoanProcessingPage = <PageSetup component={LoanQueueContainer} path={locations.LOAN_MANAGEMENT.path}
                                          showSignOutLink showDashboardLink />;
    const CheckInPage = <PageSetup component={CheckInContainer} path={locations.CHECK_IN.path} showSignOutLink showDashboardLink/>;
                                          


    //Order
    const OrderRequestPage = <PageSetup component={OrderRequestContainer} path={locations.ORDER_REQUEST.path}
                                        showSignOutLink showCatalogLink/>;
    const OrderViewPage = <PageSetup component={OrderRequestContainer} path={locations.ORDER_VIEW.path}
                                     showSignOutLink showCatalogLink/>;
    const OrderManagementPage = <PageSetup component={OrderManagementContainer} path={locations.ORDER_MANAGEMENT.path}
                                           showSignOutLink showDashboardLink/>;
    const OrderProcessingPage = <PageSetup component={OrderProcessingContainer} path={locations.ORDER_PROCESSING.path}
                                           showSignOutLink showDashboardLink/>;
    const ShippingLocationAddPage = <PageSetup component={ShippingLocationAddContainer}
                                               path={locations.SHIPPING_LOCATION_ADD.path}
                                               showSignOutLink showCatalogLink/>;


    //AUTHENTICATION
    const RegistrationPage = <PageSetup component={RegisterContainer} path={locations.REGISTER.path} showSignInLink/>;
    const SignInPage = <PageSetup component={SignInContainer} path={locations.SIGN_IN.path} showLandingLink/>;
    const SignOutPage = <PageSetup component={SignOutContainer} path={locations.SIGN_OUT.path}/>;
    const UpdatePasswordPage = <PageSetup component={PasswordUpdateContainer} path={locations.UPDATE_PASSWORD.path}
                                          showSignInLink/>;
    const ForgotPasswordPage = <PageSetup component={PasswordRequestContainer} path={locations.FORGOT_PASSWORD.path}
                                          showSignInLink/>;

    //USER
    const UserApprovalPage = <PageSetup component={UserApprovalContainer} path={locations.USER_MANAGEMENT.path}
                                        showDashboardLink showSignOutLink/>;
    const UserProfilePage = <PageSetup component={UserProfileContainer}
                                       path={locations.USER_PROFILE.path} showDashboardLink showSignOutLink/>;
    //Student
    const StudentApprovalPage = <PageSetup component={StudentApprovalContainer} path={locations.STUDENT_MANAGEMENT.path}
                                           showDashboardLink showSignOutLink/>;
    const StudentRegistrationPage = <PageSetup component={StudentRegistrationContainer}
                                               path={locations.REGISTER_STUDENT.path} showDashboardLink
                                               showSignOutLink/>;
    const EditStudentPage = <PageSetup component={StudentRegistrationContainer}
                                       path={locations.EDIT_STUDENT.path} showDashboardLink showSignOutLink/>;
    //REPORTS
    const ReportsPage = <PageSetup component={ReportsContainer} path={locations.REPORTS.path} showDashboardLink
                                   showSignOutLink/>;

    // *********************** LAST: NOT FOUND ***********************
    const NotFoundPage = <PageSetup component={NotFoundContainer} path={locations.NOT_FOUND.path}/>;

    return (
        <Routes>
            <Route path={locations.SIGN_IN.path} element={SignInPage}/>
            <Route path={locations.SIGN_OUT.path} element={SignOutPage}/>
            <Route path={locations.REGISTER.path} element={RegistrationPage}/>
            <Route path={locations.LANDING.path} element={LandingPage}/>

            {/* Library */}
            <Route path={locations.LIBRARY_WHATS_NEW.path} element={LibraryCatalogWhatsNewPage}/>
            <Route path={locations.LIBRARY_CATALOG.path} element={LibraryCatalogPage}/>
            <Route path={locations.LIBRARY_FORMAT.path} element={LibraryFormatPage}/>
            <Route path={locations.LIBRARY_ITEM.path} element={LibraryItemPage}/>
            <Route path={locations.LIBRARY_PUBLISHER.path} element={LibraryPublisherPage}/>
            <Route path={locations.LIBRARY_QUEUE.path} element={LibraryQueuePage}/>
            <Route path={locations.LIBRARY_VENDOR.path} element={LibraryVendorPage}/>

            {/* Loan */}
            <Route path={locations.LOAN_MANAGEMENT.path} element={LoanProcessingPage}/>
            <Route path={locations.CHECK_IN.path} element={CheckInPage}/>


            {/* Order Request */}
            <Route path={locations.ORDER_REQUEST.path} element={OrderRequestPage}/>
            <Route path={locations.ORDER_VIEW.path} element={OrderViewPage}/>
            <Route path={locations.ORDER_MANAGEMENT.path} element={OrderManagementPage}/>
            <Route path={locations.ORDER_PROCESSING.path} element={OrderProcessingPage}/>
            <Route path={locations.SHIPPING_LOCATION_ADD.path} element={ShippingLocationAddPage}/>

            {/* Password */}
            <Route path={locations.FORGOT_PASSWORD.path} element={ForgotPasswordPage}/>
            <Route path={locations.UPDATE_PASSWORD.path} element={UpdatePasswordPage}/>
            <Route path={locations.DASHBOARD.path} element={Dashboard}/>

            {/* User */}
            <Route path={locations.USER_MANAGEMENT.path} element={UserApprovalPage}/>
            <Route path={locations.USER_PROFILE.path} element={UserProfilePage}/>

            {/* Student */}
            <Route path={locations.STUDENT_MANAGEMENT.path} element={StudentApprovalPage}/>
            <Route path={locations.REGISTER_STUDENT.path} element={StudentRegistrationPage}/>
            <Route path={locations.EDIT_STUDENT.path} element={EditStudentPage}/>

            {/* Reports */}
            <Route path={locations.REPORTS.path} element={ReportsPage}/>

            {/* Sad Routes */}
            <Route exact path="/" element={<Navigate to={locations.LANDING.path} replace/>}/>
            <Route path="/*" element={NotFoundPage}/>
            <Route exact path="*" element={<Navigate to={locations.LANDING.path} replace/>}/>
        </Routes>
    );
};

export {SiteRoutes};