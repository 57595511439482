import {createDescriptionObjectForList, createListFromObject} from "../Utilities/Types/listUtilities";

export const jobTitles = [
    
    {
        jobID: 3,
        jobTitle: 'Charter school employee',
    },
    {
        jobID: 5,
        jobTitle: 'DBS or Agency staff member',
    },
    {
        jobID: 6,
        jobTitle: 'FIMC-VI Staff',
    }, 
    {
        jobID: 1,
        jobTitle: 'Parent',
    },
    
    {
        jobID: 4,
        jobTitle: 'Private school employee',
    },  
    {
        jobID: 2,
        jobTitle: 'School district employee',
    },  
    {
        jobID: 10,
        jobTitle: 'Other',
    }
];



export const PARENT_TYPES= {
    Charter: createDescriptionObjectForList("3", "Charter"),
    Homeschool: createDescriptionObjectForList("1", "Homeschool"),
    Private: createDescriptionObjectForList("2", "Private"),
    Public: createDescriptionObjectForList("4", "Public")
};
export const PARENT_TYPES_LIST= createListFromObject(PARENT_TYPES);


export const  DISTRICT_EMPLOYEE_TYPES = {
    DistrictPersonnel: createDescriptionObjectForList("7", "District personnel"),
    ESEDirector: createDescriptionObjectForList("5", "ESE Director"),
    GenEdESETeacherOrRelatedServiceProvider: createDescriptionObjectForList("10", "Gen ed/ESE teacher or related service provider"),
    LATS: createDescriptionObjectForList("12", "LATS"),
    Paraprofessional: createDescriptionObjectForList("9", "Paraprofessional"),
    SchoolAdministrator: createDescriptionObjectForList("25", "School administrator"),
    SchoolPsychologist: createDescriptionObjectForList("11", "School psychologist"),
    TVIAndOrOAndM: createDescriptionObjectForList("8", "TVI and/or O&M"),
};

export const DISTRICT_EMPLOYEE_TYPES_LIST = createListFromObject(DISTRICT_EMPLOYEE_TYPES);
export const  CHARTER_SCHOOL_EMPLOYEE_TYPES = {
 
    GenEdESETeacherOrRelatedServiceProvider: createDescriptionObjectForList("10", "Gen ed/ESE teacher or related service provider"),
    LATS: createDescriptionObjectForList("12", "LATS"),
    Paraprofessional: createDescriptionObjectForList("9", "Paraprofessional"),
    SchoolAdministrator: createDescriptionObjectForList("25", "School administrator"),
    SchoolPersonnel: createDescriptionObjectForList("13", "School personnel"),
    SchoolPsychologist: createDescriptionObjectForList("11", "School psychologist"),
    TVIAndOrOAndM: createDescriptionObjectForList("8", "TVI and/or O&M"),
};

export const CHARTER_SCHOOL_EMPLOYEE_TYPES_LIST = createListFromObject(CHARTER_SCHOOL_EMPLOYEE_TYPES);

export const  PRIVATE_SCHOOL_EMPLOYEE_TYPES = {
    GenEdESETeacherOrRelatedServiceProvider: createDescriptionObjectForList("10", "Gen ed/ESE teacher or related service provider"),
    Paraprofessional: createDescriptionObjectForList("9", "Paraprofessional"),
    SchoolAdministrator: createDescriptionObjectForList("25", "School administrator"),
    SchoolPersonnel: createDescriptionObjectForList("13", "School personnel"),
    SchoolPsychologist: createDescriptionObjectForList("11", "School psychologist"),
    TVIAndOrOAndM: createDescriptionObjectForList("8", "TVI and/or O&M"),
};

export const PRIVATE_SCHOOL_EMPLOYEE_TYPES_LIST = createListFromObject(PRIVATE_SCHOOL_EMPLOYEE_TYPES);

export const  AGENCY_STAFF_MEMBER_TYPES = {
    Administrator: createDescriptionObjectForList("6", "Administrator"),
    ServiceProvider: createDescriptionObjectForList("15", "Service provider"),
    StaffMember: createDescriptionObjectForList("14", "Staff member"),
};

export const AGENCY_STAFF_MEMBER_TYPES_LIST = createListFromObject(AGENCY_STAFF_MEMBER_TYPES);

export const  FIMC_SUB_TYPES = {
    AccessibleMediaProducer: createDescriptionObjectForList("23", "Accessible Media Producer"),
    Administrator: createDescriptionObjectForList("6", "Administrator"),
    BrailleSpecialist: createDescriptionObjectForList("24", "Braille Specialist"),
    Manager: createDescriptionObjectForList("22", "Manager"),
};

export const FIMC_SUB_TYPES_LIST = createListFromObject(FIMC_SUB_TYPES);

export const  OTHER_TYPES = {
    BusinessOrCommunityMember: createDescriptionObjectForList("16", "Business/community member"),
    CollegeOrUniversityFaculty: createDescriptionObjectForList("20", "College/university Student"),
    CollegeOrUniversityStudent: createDescriptionObjectForList("19", "College/university Student"),
    DiscretionaryProjectStaff: createDescriptionObjectForList("18", "Discretionary project staff"),
    FloridaDepartmentOfEducationStaff: createDescriptionObjectForList("21", "Florida Department of Education staff"),
    VAVFMember: createDescriptionObjectForList("17", "VAVF member"),
};

export const OTHER_TYPES_LIST = createListFromObject(OTHER_TYPES);

export const CHARTER_TYPE = {
    CharterSchool: createDescriptionObjectForList("2", "Charter School"),
    CharterLEA: createDescriptionObjectForList("1", "Charter LEA"),
};
export const CHARTER_TYPE_LIST = createListFromObject(CHARTER_TYPE);

export const createSubtypeListFromPatronRole = (jobTitle) => {
    switch (jobTitle) {
        case 1:
            return PARENT_TYPES_LIST;
        case 2:
            return DISTRICT_EMPLOYEE_TYPES_LIST;
        case 3:
            return CHARTER_SCHOOL_EMPLOYEE_TYPES_LIST;
        case 4:
            return PRIVATE_SCHOOL_EMPLOYEE_TYPES_LIST;
        case 5:
            return AGENCY_STAFF_MEMBER_TYPES_LIST;
        case 6:
            return FIMC_SUB_TYPES_LIST;
        case 10:
            return OTHER_TYPES_LIST;
        default:
            return [];
    }
}

export const PatronRoleSubtypesMap = [
    { value: 'Homeschool', key: 1 },
    { value: 'Private', key: 2 },
    { value: 'Charter', key: 3 },
    { value: 'Public', key: 4 },
    { value: 'ESE Director', key: 5 },
    { value: 'Administrator', key: 6 },
    { value: 'District personnel', key: 7 },
    { value: 'TVI and/or O&M', key: 8 },
    { value: 'Paraprofessional', key: 9 },
    { value: 'Gen ed/ESE teacher or related service provider', key: 10 },
    { value: 'School psychologist', key: 11 },
    { value: 'LATS', key: 12 },
    { value: 'School personnel', key: 13 },
    { value: 'Staff member', key: 14 },
    { value: 'Service provider', key: 15 },
    { value: 'Business/community member', key: 16 },
    { value: 'VAVF member', key: 17 },
    { value: 'Discretionary project staff', key: 18 },
    { value: 'College/university student', key: 19 },
    { value: 'College/university faculty', key: 20 },
    { value: 'Florida Department of Education staff', key: 21  },
    { value: 'Manager', key: 22  },
    { value: 'Accessible Media Producer', key: 23  },
    { value: 'Braille Specialist', key: 24  },
    { value: 'School administrator', key: 25  },
  ];

  export const JOB_ROLES = {

    Parent: '1',
    District: '2',
    Charter: '3',
    Private: '4',
    Agency: '5',
    Fimc: '6',
    Other: '10', 
    
    CHARTER_STATUS: {
        'Charter LEA': '1',
        'Charter School': '2'
    },

    FORM_NAME: {
        "1": 'parentType',
        "2": 'districtEmployeeType',
        "3": 'charterEmployeeType',
        "4": 'privateEmployeeType',
        "5": 'agencyStaffMemberType',
        "6": 'FimcType',
        "10": 'otherType',
    },

  }
  
