import {apiConstants, config, downloadBlob, requestOptions, requestTypes, sendRequest} from "../../Utilities/Api";

class ServerStudentCensusApi {
    static getManualCensusEndDate() {
        return sendRequest(
            requestOptions(config.URL + `census`,
                requestTypes.GET,
            ),
        );
    }

    static prepareStudentsForCensus() {
        return sendRequest(
            requestOptions(config.URL + `census`,
                requestTypes.PUT,
            ),
        );
    }

    static setManualCensusEndDate() {
        return sendRequest(
            requestOptions(config.URL + `census`,
                requestTypes.POST,
            ),
        );
    }

    static exportStudentsReport(studentSearchCriteria) {
        const queryParameters = new URLSearchParams(studentSearchCriteria).toString();

        return downloadBlob(
            requestOptions(`${config.URL}reports/students_census/export?${queryParameters}`,
                requestTypes.GET,
                null,
                apiConstants.successMessage.report.REPORT_DOWNLOADED));
    }
}

const StudentCensusApi = config.environment.MODE === config.modes.SERVER ? ServerStudentCensusApi : ServerStudentCensusApi;
export default StudentCensusApi;