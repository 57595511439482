import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    convertInputFormToObject,
    InputForm,
    useInputForm
} from "../../Components/Inputs";
import {clearInputFormFields} from "../../Components/Inputs/inputUtility";
import {GridCell, gridConstants, GridContainer} from "../../Components/Grid";
import SearchableSingleSelectField from "../../Components/Inputs/SearchableSingleSelectField";

const LoanSearchFormView = ({
                                 handleSetLoanSearchCriteria,
                                 isLoading,
                                 loanSearchCriteria,
                                 patrons=[],
                                 shippingLocations=[],
                                 students=[]
                             }) => {

    const searchForm = useInputForm(loanSearchCriteria);

    const handleClearClick = () => {
        clearInputFormFields(searchForm);
        const updatedLoanSearchCriteria = {...loanSearchCriteria};
        updatedLoanSearchCriteria.ClearOrderSearch();
    };

    const handleSearchClick = () => {
        let criteria = convertInputFormToObject(searchForm);
        const updatedLoanSearchCriteria = {...loanSearchCriteria};
        updatedLoanSearchCriteria.Search(criteria);
        handleSetLoanSearchCriteria(updatedLoanSearchCriteria);
    };

    const inputSharedSettings = {
        medium_columns: gridConstants.column.FOUR,
    };

    return <>
        <InputForm name={"loanSearch"} hasPaddingY>

            <SearchableSingleSelectField
                {...searchForm.userId}
                {...inputSharedSettings}
                includeDefaultOption
                label={'Patron'}
                options={patrons}
            />

            <SearchableSingleSelectField
                {...searchForm.shippingLocationId}
                {...inputSharedSettings}
                includeDefaultOption
                label={'Shipping Locations'}
                options={shippingLocations}
            />

            <SearchableSingleSelectField
                {...searchForm.studentId}
                {...inputSharedSettings}
                includeDefaultOption
                label={'Student'}
                options={students}
            />

            <GridCell medium_offset={gridConstants.column.THREE}>
                <GridContainer hasPaddingX hasPaddingY isFlush>

                    <GridCell medium_columns={gridConstants.column.THREE}>
                        <Button
                            disabled={isLoading}
                            label="Search"
                            name="btnSearchLoan"
                            onClick={handleSearchClick}
                            isPrimary
                        />
                    </GridCell>

                    <GridCell medium_columns={gridConstants.column.THREE}>
                        <Button
                            disabled={isLoading}
                            name="btnClearLoanSearch"
                            onClick={handleClearClick}
                            label="Clear"
                        />
                    </GridCell>

                </GridContainer>
            </GridCell>

        </InputForm>
    </>;
};

LoanSearchFormView.propTypes = {
    handleSetLoanSearchCriteria: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loanSearchCriteria: PropTypes.object.isRequired,
    patrons: PropTypes.array.isRequired,
    shippingLocations: PropTypes.array.isRequired,
    students: PropTypes.array.isRequired,
};

export default LoanSearchFormView;
