import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { GridCell, GridContainer, gridConstants } from '../../Components/Grid'
import { TextField, Button, SwitchFieldset, SelectField } from '../../Components/Inputs'
import { CHECKIN_TYPES_LIST, CHECKIN_TYPES } from './checkInConstants'
import { volumeDetailsModel } from './checkInDetailsFactory'


export const CheckInFormView = ({
                                    checkInForm,
                                    handleSubmit,
                                    updateVolumes
                                }) => {

    const [checkIn, setCheckIn] = useState(false);
    const [volumes, setVolumes] = useState([]);


    const handleCheckInSwitch = (event) => {
        const isChecked = event.target.checked;
        setCheckIn(isChecked);

        if (isChecked) {
            setVolumes([volumeDetailsModel()]);
            checkInForm.copyBarcode.setValue('');
            checkInForm.shelfLocation.setValue('');
            checkInForm.copyBarcode.setError('');
            checkInForm.shelfLocation.setError('');
        } else {
            setVolumes([]);
            checkInForm.volumes.setValue([]);
        }
    };

    const handleVolumeChange = (index, field, value) => {
        const updatedVolumes = [...volumes];
        updatedVolumes[index] = { ...updatedVolumes[index], [field]: value };
        setVolumes(updatedVolumes);
        checkInForm.volumes.setValue(updatedVolumes);

    };
    const addVolume = () => {
        setVolumes((prevVolumes) => {
            const newVolumes = [...prevVolumes, volumeDetailsModel()];
            updateVolumes(newVolumes); 
            return newVolumes;
        });
    };
    
    const removeVolume = (index) => {
        setVolumes((prevVolumes) => {
            if (prevVolumes.length > 1) {
                const updatedVolumes = prevVolumes.filter((_, i) => i !== index);
                updateVolumes(updatedVolumes); 
                return updatedVolumes;
            }
            return prevVolumes;
        });
    };




  return <>
    <GridCell >
        <SwitchFieldset legend={"Check In Individual Volumes"} label={"Check In Individual Volumes"} name='Check in' onChange={handleCheckInSwitch}/>

       {!checkIn && ( 
        <>
            <h2>Full Copy Check In</h2>
            <TextField {...checkInForm.copyBarcode}/> 
            <TextField {...checkInForm.shelfLocation}/> 
        </>  
        )}
       
        {checkIn && (
            <>
                <h2> Individual Volumes Check In</h2>
                  {volumes.map((volume, index) => (
                         
                    <GridContainer key={index} isFlush hasMarginX large_columns={gridConstants.column.TWELVE} 
                    >
                        <TextField
                            name={`barcode-${index}`}
                            label = "Barcode"
                            large_columns={gridConstants.column.SIX}
                            onChange={(e) =>
                                handleVolumeChange(index, 'barcode', e.target.value)
                            }
                            value={volume.barcode}
                            />
                        
                        <TextField
                            name={`shelfLocation-${index}`}
                            label="Shelf Location"
                            value={volume.shelfLocation}
                            onChange={(e) =>
                                handleVolumeChange(index, 'shelfLocation', e.target.value)
                            }
                            large_columns={gridConstants.column.SIX}
                           
                            
                            
                        /> 
                        <SelectField
                            name={`status-${index}`}
                            label="Status"
                            options={CHECKIN_TYPES_LIST}
                            value={volume.status}
                            onChange={(e) =>
                                handleVolumeChange(index, 'status', e.target.value)
                            }
                            defaultOptionValue={CHECKIN_TYPES.Available}
                            defaultOption={"Available"}
                            includeDefaultOption={false}
                        />
                        <GridCell>
                            <Button
                            label="Remove"
                            onClick={() => removeVolume(index)}
                            isCollapsed
                            name='removeVolume'
                            disabled={volumes.length === 1}
                            large_columns={gridConstants.column.TWELVE}
                        />
                        </GridCell>
                    
                    </GridContainer>
                            
                        
                ))}

                <Button  label="Add Volume" onClick={addVolume} isCollapsed name='addVolume' />
             
            </>  
        )}

        <Button
            name='submitCheckIn'
            label={"Submit Check In"}
            onClick={(event) => handleSubmit(event, checkIn)} 
            isCollapsed
        />
    </GridCell>
  </>
    
}

CheckInFormView.propTypes = {
    checkInForm: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    updateVolumes: PropTypes.func.isRequired,
}

