import {convertCamelCaseToSpaceDelimited, convertUnderscoreToDash, isNullOrUndefined} from "../commonUtilities";
import {isArrayNullOrEmpty} from "./arrayUtilities";
import {objectHasProperty} from "./objectUtilities.js";
import {isArray, isString} from "./typeUtilities";

export function isInSelectList(list, string) {
    return !!list.find((item) => item.value === string || item.text === string);
}

export function createDescriptionObjectForList(id, description) {
    return {
        id,
        description
    };
}

export function createListFromArray(array) {
    //refactor to expression, dry
    let list = [];

    if (isArrayNullOrEmpty(array)) return list;

    array.map((item) => {
        return list.push(createListItem(item, convertCamelCaseToSpaceDelimited(item)));
    });

    return list;
}

export const createListFromArrayOfObjects = (array = [], valueProperty = "", textProperty = "") => {
    let list = [];

    if (isArray(array)) {
        for (const item of array) {
            if (objectHasProperty(item, valueProperty) && objectHasProperty(item, textProperty))
                list.push(createListItem(item[valueProperty], item[textProperty]));
        }
    }
    return list;
};

export function createListFromObject(object, usePropertyValueForText = false, hasZeroAsId = false) {
    let result = [];
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            const value = (!object[property].id && !hasZeroAsId) ? object[property] : object[property].id;
            const id = (!object[property].id && !hasZeroAsId) ? property : object[property].id;
            const listText = usePropertyValueForText ? object[property] : convertPropertyNames(property);
            const description = !object[property].description ? listText : object[property].description;

            result.push(createListItem(value, description, id));
        }
    }
    return result;
}

export function createListFromObjectWhereValueIsPropertyName(object) {
    let result = [];
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            result.push(createListItem(property, object[property], property));
        }
    }
    return result;
}

function convertPropertyNames(property) {
    property = convertCamelCaseToSpaceDelimited(property);
    property = convertUnderscoreToDash(property);
    return property;
}

export function createListItem(value, text, id) {
    return {
        text: text ? text : value,
        value: value,
        id: id ? id : value,
    };
}

export const createArchivableListFromArray = (items = [], value) => {
    let list = [];

    if (isString(value) && value.length > 1 && !items.includes(value)) list.push(createListItem(value, `${value} (archived)`));

    if (isArray(items))
        items.map((item) => {
            return list.push(createListItem(item));
        });
    return list;
};

export function getTextByValue(list, value) {
    value = isNullOrUndefined(value) ? 0 : value;
    const result = list.find(l => l.value.toString() === value.toString());
    return result ? result.text : "";
}

export function createListIfIdArray(selectedItemsIds, fullList) {
    if (!selectedItemsIds) return [];

    let list = [];

    if (fullList) {
        for (let listItem of fullList) {
            const itemExists = selectedItemsIds.find(f => f === listItem.id);
            if (!itemExists)
                continue;

            const selectListItem = createListItem(listItem.id, listItem.text);
            list.push(selectListItem);
        }
    }

    return list;
}

export function getObjectFromObjectId(id, objectList, objectId = "id") {
    return objectList.find(o => o[objectId] === id);
}
