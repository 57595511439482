import React from "react";
import PropTypes from "prop-types";
import {ExternalLink} from "../../../Components/Link";
import {CheckBoxField} from "../../../Components/Inputs";

export const StudentRegistrationEligibilityRequirements = ({readOnly,studentDetailsForm}) => {
    return (
      <>
        
         
            
       <p>     To register a student with FIMC-VI, the student must meet the
       following eligibility requirements:</p>
            <ol className="numbered-list">
              <li>
                According to FERPA and HIPAA requirements, the district has a
                signed
                <ExternalLink
                  label={`Parental/Guardian Consent to Release Student Information`}
                  url={`https://hillsborough.sharepoint.com/:f:/s/FIMC-VI_ProfDev-WebsiteLive/EuRYl-e8PxBNjpPuXFOGvZ8BJ2XeMYqmEIZ_mMEZLB5VMg?e=urM4FB`}
                />
                form on file to share student identifiable information with
                FIMC-VI and the American Printing House for the Blind (APH).
                <ul>
                  <li>
                    For students under the age of 18, the consent form must be
                    signed by a parent/legal guardian.
                  </li>
                  <li>
                    For students who have reached the age of majority, the
                    consent form must be signed by the student.
                  </li>
                </ul>
              </li>

              <li>
                The student is enrolled in a formally organized public or
                private, nonprofit educational program of less than college
                level.
                <ul>
                  <li>
                    Educational programs include public, private and parochial
                    schools, as well as vocational and rehabilitation programs
                    for adults, and detention centers.
                  </li>
                  <li>
                    Infants, toddlers, preschool, homebound and homeschooled
                    students are also included.
                  </li>
                </ul>
              </li>

              <li>
                The student has a written education plan (IEP, IFSP, SP, IRP,
                504 Plan, etc.).
                <ul>
                  <li>
                    For homeschool students, the requirements in Section
                    1002.01, Florida Statutes (F.S.)
                    <ExternalLink
                      label={`Student and Parental Rights and Educational Choices`}
                      url={`http://www.leg.state.fl.us/statutes/index.cfm?mode=View%20Statutes&SubMenu=1&App_mode=Display_Statute&Search_String=1002.01&URL=1000-1099/1002/Sections/1002.01.html`}
                    />
                    have been met.
                  </li>
                </ul>
              </li>
              <li>
                The student is a student with a visual impairment (VI) as
                specified in
                <ExternalLink
                  label={`Rule 6A-6.03014, Florida Administrative Code (F.A.C.)`}
                  url={`https://olo.sdhc.k12.fl.us/images/6A-6.03014.pdf`}
                />
                , or is a student with a dual sensory impairment as specified in
                <ExternalLink
                  label={`Rule 6A-6.03022, F.A.C.`}
                  url={`https://olo.sdhc.k12.fl.us/images/6A-6.03022.pdf`}
                />
                ;
              </li>
            </ol>
        
        


        <CheckBoxField
          {...studentDetailsForm.isEligible}
          checked={!!studentDetailsForm.isEligible.value}
          disabled={readOnly || studentDetailsForm.understandsNoAphAccess.value}
          label={` I confirm that the student meets the four eligibility requirements outlined above.`}
          value={studentDetailsForm.isEligible.value ? "" : "true"}
        />
         <CheckBoxField
                            {...studentDetailsForm.understandsNoAphAccess}
                            checked={!!studentDetailsForm.understandsNoAphAccess.value}
                            disabled={readOnly || studentDetailsForm.isEligible.value}
                            label={
                                "The district does not have a signed Parental/Guardian Consent to Release Student Information form on file, however the student meets criteria # 2, #3, and #4 outlined above. I understand that I will not be able to order APH products for this student, but I will be able to order textbooks. The student profile may be updated once parental consent is obtained."
                            }
                            value={studentDetailsForm.understandsNoAphAccess.value ? "" : "true"}
                        />
      </>
    );
};

StudentRegistrationEligibilityRequirements.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    studentDetailsForm: PropTypes.object.isRequired
};
