import {
    createDescriptionObjectForList,
    createListFromObject,
} from "../../Utilities/Types/listUtilities";

export const ORDER_TYPE = {
    Order: 0,
    SpecialOrderRequest: 1,
};

export const ORDER_TYPE_LIST = createListFromObject(ORDER_TYPE);

export const PLACE_THE_ORDER_STATUS = {
    ProduceInternally: createDescriptionObjectForList("2", "Produce Internally"),
    GeneratePurchaseOrder: createDescriptionObjectForList("3", "Generate Purchase Order"),
    OrderSentToVendor: createDescriptionObjectForList("4", "Order Sent To Vendor"),
};
export const PLACE_THE_ORDER_STATUS_LIST = createListFromObject(PLACE_THE_ORDER_STATUS);

export const ORDER_FULFILLMENT_STATUS = {
    PartialOrderReceived: createDescriptionObjectForList("5", "Partial Order Received"),
    FullOrderReceived: createDescriptionObjectForList("6", "Full Order Received"),
};
export const ORDER_FULFILLMENT_STATUS_LIST = createListFromObject(PLACE_THE_ORDER_STATUS);

export const ORDER_STATUS = {
    OrderReceivedFromPatron: createDescriptionObjectForList("1", "Order Received From Patron"),
    ...PLACE_THE_ORDER_STATUS,
    ...ORDER_FULFILLMENT_STATUS,
    AddToCatalogInventory: createDescriptionObjectForList("7", "Add To Catalog Inventory"),
    PrintBarCode: createDescriptionObjectForList("8", "Print BarCode"),
    PrintMailingLabel: createDescriptionObjectForList("9", "Print Mailing Label"),
    ReadyToMail: createDescriptionObjectForList("10", "Ready To Mail"),
    Mailed: createDescriptionObjectForList("11", "Mailed"),
    OrderComplete: createDescriptionObjectForList("12", "Order Complete"),
    OrderCancelledByPatron: createDescriptionObjectForList("13", "Order Cancelled By Patron"),
    OrderCancelledByFimc: createDescriptionObjectForList("14", "Order Cancelled By Fimc"),
};
export const ORDER_STATUS_LIST = createListFromObject(ORDER_STATUS);
