import textbooksIcon from "../../images/Textbooks.svg";
import loanLibraryIcon from "../../images/Loan-Library.svg";
import aphIcon from "../../images/APH.svg";
import brailleMachinesIcon from "../../images/Braille-Machines.svg";
import loanManagementIcon from "../../images/Loan-Management.svg";
import orderManagementIcon from "../../images/Order-Management.svg";
import checkInIcon from "../../images/Check-In.svg";
import studentManagementIcon from "../../images/Student-Management.svg";
import userManagementIcon from "../../images/User-Management.svg";
import reportsIcon from "../../images/Reports.svg";

export const icons = {
    Textbooks: textbooksIcon,
    "Loan-Library": loanLibraryIcon,
    APH: aphIcon,
    "Braille-Machines": brailleMachinesIcon,
    "Loan-Management": loanManagementIcon,
    "Order-Management": orderManagementIcon,
    "Check-In": checkInIcon,
    "Student-Management": studentManagementIcon,
    "User-Management": userManagementIcon,
    Reports: reportsIcon,
  };