import PropTypes from "prop-types";
import React from "react";
import {catalogQueueModel} from "./libraryFactory";
import {LibraryQueueItem} from "./LibraryQueueItem";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import {ButtonLinkStyle} from "../../Components/Inputs";

export const LibraryQueueList = ({
                                     handleDeselectAll,
                                     handleRemoveFromQueue,
                                     handleSelectAll,
                                     handleSelectedItemsChange,
                                     handleViewOrder,
                                     heading = "Pending",
                                     queue,
                                     selectedItems,
                                 }) => {
    const isQueueEmpty = isArrayNullOrEmpty(queue);

    return <>
        <header>
            <h2>{heading}</h2>
        </header>
        {
            !isQueueEmpty && handleSelectAll && handleDeselectAll &&
            <>
                <ButtonLinkStyle isCollapsed onClick={handleSelectAll}  name={`btnSelectAll`} label={`Select All`} /> /&nbsp;
                <ButtonLinkStyle isCollapsed onClick={handleDeselectAll} name={`btnDeselectAll`} label={`Deselect All`} />
            </>
        }
        {
            !isQueueEmpty && queue &&
            queue.map((queuedItem) => {
                const {catalogItem, queueItemId} = queuedItem;
                const queueModel = catalogQueueModel({...catalogItem, ...queuedItem});
                const handleDelete = () => handleRemoveFromQueue(queueItemId);
                const isSelected = selectedItems?.has(queueItemId);
                return <LibraryQueueItem
                    key={queueItemId}
                    {...queueModel}
                    handleDelete={handleDelete}
                    handleViewOrder={handleViewOrder}
                    handleSelectedItemsChange={handleSelectedItemsChange}
                    isSelected={isSelected}
                    queueItemId={handleViewOrder ? "" : queueItemId}
                />;
            })
        }
        {
            isQueueEmpty &&
            <p className={`text-center`}>No results.</p>
        }
        <br/>
        <br/>
    </>;
};

LibraryQueueList.propTypes = {
    handleDeselectAll: PropTypes.func,
    handleRemoveFromQueue: PropTypes.func.isRequired,
    handleSelectAll: PropTypes.func,
    handleSelectedItemsChange: PropTypes.func,
    handleViewOrder: PropTypes.func,
    heading: PropTypes.string,
    queue: PropTypes.array.isRequired,
    selectedItems: PropTypes.object,
};