import React from "react";
import {ButtonIconEdit} from "../../../Components/Inputs";
import {HtmlDisplay} from "../../../Components/Display";
import {locations} from "../../../Utilities/Location";
import PropTypes from "prop-types";
import {
    getCompleteReadingMediumsTextByValue,
    getEligibilityTextByValue,
    getGradeLevelTextByValue,
    getStudentApprovalStatusTextByValue,
} from "../studentUtilities";

const StudentApprovalRow = ({redirect, student}) => {


    const handleEditStudentDetailsClick = (event, studentId) => {
        event.preventDefault();
        redirect(locations.EDIT_STUDENT.getPath(studentId));
    };


    return (
        <tr id={student.studentId} data-testid={student.studentId}>
            <td>
                {student.fullName}
            </td>
            <td>
                {student.dateOfBirth}
            </td>
            <td>
                {student.dateCreatedUtc || ``}
            </td>
            <td>
                {student.dateModifiedUtc || ``}
            </td>
            <td>
                {getGradeLevelTextByValue(student.gradeLevel)}
            </td>
            <td>
                {getCompleteReadingMediumsTextByValue(student.primaryReadingMedium)}
            </td>
            <td>
                {student.districtName || ``}
            </td>
            <td>
                {student.userFullName || ``}
            </td>
            <td>
                {student.hasSignedParentalConsentOutput}
            </td>
            <td className={student.studentApprovalStatusName}>
                <HtmlDisplay
                    html={getStudentApprovalStatusTextByValue(student.studentApprovalStatus)}
                    name={`approval-status-${student.studentId}`}/>
            </td>

            <td>
                {getEligibilityTextByValue(student.eligibility || ``)}
            </td>
            <td className={`align-center`}>
                    <ButtonIconEdit
                        inputDescription={`for ${student.firstName} ${student.lastName}`}
                        onClick={(event) => handleEditStudentDetailsClick(event, student.studentId)}
                    />
            </td>
        </tr>
    );
};

StudentApprovalRow.PropType = {
    redirect: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}

export default StudentApprovalRow;