import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LibraryPublisherView} from "./LibraryPublisherView";
import {locations} from "../../Utilities/Location";
import libraryCatalogApi from "./libraryCatalogApi";
import {emptyPublisherModel} from "./libraryFactory";
import {SelectField} from "../../Components/Inputs";
import {createListFromArrayOfObjects} from "../../Utilities/Types/listUtilities";
import {emptyGuid} from "../../Utilities/commonUtilities";


export const LibraryPublisherContainer = ({
                                              handleApiCall,
                                              libraryPublisherId,
                                              redirect,
                                              systemTypeId,
                                          }) => {
    const [publisher, setPublisher] = useState();
    const [publishers, setPublishers] = useState();
    const publisherList = createListFromArrayOfObjects(publishers, "publisherId", "name");

    const handleChangePublisher = (event) => {
        const path = locations.LIBRARY_PUBLISHER.getLink(event.target.value).to;
        redirect(path);
    }

    const handleReturn = () => redirect(locations.LIBRARY_CATALOG.getLink(systemTypeId).to);

    const handleSavePublisher = async (publisher) => {
        return handleApiCall(() => libraryCatalogApi.savePublisher(publisher),
            handleReturn);
    };

    useEffect(() => {
        const getPublishers = () => {
            handleApiCall(() => libraryCatalogApi.getPublishers(), (results) => setPublishers(results));
        }

        const getPublisher = () => {
            const publisher = publishers?.find(v => v.publisherId === libraryPublisherId) || emptyPublisherModel;
            setPublisher(publisher);
        }

        if (!publishers)
            getPublishers();
        else if (libraryPublisherId)
            getPublisher();

    }, [publishers, libraryPublisherId]);

    if (!publisher) return <></>;

    return <>
        <PageTitle h1={`Manage Publishers`}/>
        <SelectField
            defaultOption={"-- Create New Publisher --"}
            defaultOptionValue={emptyGuid}
            label={"Select Publisher to Manage"}
            name={'PublisherList'}
            onChange={handleChangePublisher}
            options={publisherList}
            value={libraryPublisherId}
        />
        <LibraryPublisherView
            handleSavePublisher={handleSavePublisher}
            handleReturn={handleReturn}
            publisher={publisher}
        />
    </>;
};

LibraryPublisherContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    libraryPublisherId: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
    systemTypeId: PropTypes.string.isRequired,
};