import PropTypes from "prop-types";
import React from "react";
import {InputLayout} from "./InputLayout";
import {helpTextSuffix, notificationSuffix} from "./inputConstants";

export class TextArea extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onInput = this.onInput.bind(this);
    }

    onChange(event) {
        this.props.onChange(event);
    }

    onInput(event) {
        this.onChange = null;
        this.props.onChange(event);
    }

    render() {
        let {
            disabled,
            helpText,
            label,
            name,
            placeholder,
            value,
            error,
            responseFeedback,
        } = this.props;

        return (
            <InputLayout
                disabled={disabled}
                helpText={helpText}
                label={label}
                name={name}
                error={error}
                responseFeedback={responseFeedback}
            >
					<textarea
                        aria-disabled={disabled}
                        aria-invalid={!!error}
                        aria-errormessage={`${name}${notificationSuffix}`}
                        aria-describedby={`${name}${helpTextSuffix}`}
                        name={name}
                        id={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={this.onChange}
                        onInput={this.onInput}
                        readOnly={disabled}
                    />
            </InputLayout>
        );
    }
}

TextArea.propTypes = {
    disabled: PropTypes.bool,
    helpText: PropTypes.any,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
    responseFeedback: PropTypes.string,
};

TextArea.defaultProps = {
    onChange: () => {
    },
    value: "",
};