import React from 'react'
import PropTypes from "prop-types";
import {Button, DateTimePickerField, SelectField} from '../../Components/Inputs'
import {GridCell, gridConstants} from "../../Components/Grid";
import { REPORT_TYPES_LIST } from './reportsConstants';


export const ReportsFormView = ({
                                    handleExportReport,
                                    reportForm
                                }) => {

    return <>
        <GridCell>
            <SelectField
                {...reportForm.reportType}
                options={REPORT_TYPES_LIST}
                includeDefaultOption={false}
            />
            <DateTimePickerField  {...reportForm.startDate} medium_columns={gridConstants.column.SIX} />
           
           
            <DateTimePickerField {...reportForm.endDate} medium_columns={gridConstants.column.SIX}/>


           <Button
                isCollapsed
                name='exportReport'
                onClick={handleExportReport}
                label={"Export Report"}
            />
        </GridCell>
    </>
}

ReportsFormView.propTypes = {
    handleExportReport: PropTypes.func.isRequired,
    reportForm: PropTypes.object.isRequired,
}