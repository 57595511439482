import {searchModel} from "../../Utilities/Search";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import {dynamicSort} from "../../Utilities/Sort";

export const loanSearchModel = (userId = "", studentId = "", shippingLocationId = "") => {
    return Object.assign({}, searchModel(userId.toString(), "shippingLocationName", false, "shippingLocationName"), {
        shippingLocationId,
        studentId,
        userId,
        ClearOrderSearch: clearOrderSearch,
        ApplySorting: applySorting,
    });
};

function clearOrderSearch() {
    this.shippingLocationId = "";
    this.studentId = "";
    this.userId = "";
    this.ClearSearch(false);
}

function applySorting(loanSearchCriteria, loans) {
    if (isNullOrUndefined(loans) || isArrayNullOrEmpty(loans))
        return loans;

    return loans.sort(dynamicSort(loanSearchCriteria.sortByColumn, loanSearchCriteria.sortDescending));
}

