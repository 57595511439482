import React from "react";
import PropTypes from "prop-types";
import {Allow, policyEvents} from "../../Components/Authorize";
import {
    ButtonIconApprove,
    ButtonIconDelete,
    ButtonIconProgress,
} from "../../Components/Inputs/Buttons/ButtonIcon/ButtonIcon";
import {LOAN_STATUS} from "./loanConstants";

export const LoanQueueRow = ({
                                 handleDelete,
                                 handleLoan,
                                 loan
                             }) => {

    return (
        <tr id={loan.id}>
            <td>
                {loan.title}
            </td>
            <td>
                {loan.patron}
            </td>
            <td>
                {loan.shippingLocation}
            </td>
            <td>
                {loan.student}
            </td>
            <td>
                {loan.loanStatusText}
            </td>

            <td className={`align-center`}>
                <Allow policyEvent={policyEvents.MANAGE_LOANS}>
                    {
                        loan.loanStatus !== LOAN_STATUS.ApproveForCheckout &&
                        handleLoan &&
                        <ButtonIconProgress
                            inputDescription={`for ${loan.itemName}`}
                            onClick={handleLoan}
                        />
                    }
                    {
                        loan.loanStatus === LOAN_STATUS.ApproveForCheckout &&
                        <>
                        {
                            handleLoan &&
                            <ButtonIconApprove
                                inputDescription={`for ${loan.itemName}`}
                                onClick={handleLoan}
                            />
                        }
                        {
                            handleDelete &&
                            <ButtonIconDelete
                                inputDescription={`for ${loan.itemName}`}
                                onClick={handleDelete}
                            />
                        }
                        </>
                    }
                </Allow>
            </td>
        </tr>
    );
};

LoanQueueRow.PropType = {
    handleDelete: PropTypes.func,
    handleLoan: PropTypes.func,
    loan: PropTypes.object.isRequired,
}