import {createShipping} from "../../Library/libraryFactory";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {
    getFormatTextByValue,
    getGraveLevelTextByValue,
    getOrderStatusTextByValue,
    getOrderTypeTextByValue
} from "../orderUtility";
import {convertDateToPrintString} from "../../../Utilities/Types/dateUtilities";
import {ORDER_STATUS} from "../orderConstants";
import {isObjectNullOrEmpty} from "../../../Utilities/Types/objectUtilities";

function createDetails({
                           alternateCopyright,
                           alternateFormat,
                           author,
                           copyright,
                           format,
                           gradeLevel,
                           isbn,
                           itemName,
                           needFrom,
                           needTo,
                           orderStatus,
                           orderType,
                           publisherName,
                           seriesTitle,
                           student,

                           poNumber,
                           cost,
                           dateOrderSentToVendor,
                           dateOrderReceivedFromVendor,
                           numberOfVolumesReceived,
                           totalNumberOfVolumesReceived,
                       }) {
    let details = "";

    const hasStudent = !isNullOrUndefined(student);
    const hasNeedFrom = !isNullOrUndefined(needFrom);
    const hasNeedTo = !isNullOrUndefined(needTo);
    const hasMediumRequested = !isNullOrUndefined(format);
    const hasPublisher = !isNullOrUndefined(publisherName);
    const hasCopyright = !isNullOrUndefined(copyright);
    const hasAuthor = !isNullOrUndefined(author);
    const hasSeriesTitle = !isNullOrUndefined(seriesTitle);
    const hasAlternateCopyRight = !isNullOrUndefined(alternateCopyright);
    const hasGradeLevel = !isNullOrUndefined(gradeLevel);
    const hasAlternateMedium = !isNullOrUndefined(alternateFormat);
    const hasOrderStatus = !isNullOrUndefined(orderStatus);
    const hasPONumber = !isNullOrUndefined(poNumber);
    const hasCost = !isNullOrUndefined(cost);
    const hasDateOrderSentToVendor = !isNullOrUndefined(dateOrderSentToVendor);
    const hasDateOrderReceivedFromVendor = !isNullOrUndefined(dateOrderReceivedFromVendor);
    const hasNumberOfVolumesReceived = !isNullOrUndefined(numberOfVolumesReceived);
    const hasTotalNumberOfVolumesReceived = !isNullOrUndefined(totalNumberOfVolumesReceived);

    details += `<div><b>ItemName:</b> ${itemName}</div>`;
    details += `<div><b>Order Type:</b> ${getOrderTypeTextByValue(orderType)}</div>`;
    details += hasStudent ? `<div><b>Student:</b> ${createStudent(student)}</div>` : "";
    details += `<div><b>Isbn:</b> ${isbn}</div>`;
    details += hasNeedFrom ? `<div><b>Need From:</b> ${convertDateToPrintString(needFrom)}</div>` : "";
    details += hasNeedTo ? `<div><b>Need To:</b> ${convertDateToPrintString(needTo)}</div>` : "";
    details += hasMediumRequested ? `<div><b>Medium Requested:</b> ${getFormatTextByValue(format)}</div>` : "";
    details += hasPublisher ? `<div><b>Publihser:</b> ${publisherName}</div>` : "";
    details += hasCopyright ? `<div><b>Copyright:</b> ${convertDateToPrintString(copyright)}</div>` : "";
    details += hasAuthor ? `<div><b>Author:</b> ${author}</div>` : "";
    details += hasSeriesTitle ? `<div><b>Series Title:</b> ${seriesTitle}</div>` : "";
    details += hasAlternateCopyRight ? `<div><b>Alternate Copyright:</b> ${alternateCopyright}</div>` : "";
    details += hasAlternateMedium ? `<div><b>Alternate Medium:</b> ${getFormatTextByValue(alternateFormat)}</div>` : "";
    details += hasGradeLevel ? `<div><b>Grade Level:</b> ${getGraveLevelTextByValue(gradeLevel)}</div>` : "";
    details += hasOrderStatus ? `<div><b>Order Status:</b> ${getOrderStatusTextByValue(orderStatus)}</div>` : "";

    if (orderStatus.toString() !== ORDER_STATUS.OrderReceivedFromPatron.id.toString()) {
        details += `<br/><div><b>Order Processing Details:</b></div>`;
        details += hasPONumber ? `<div><b>PO Number:</b> ${poNumber}</div>` : "";
        details += hasCost ? `<div><b>Cost:</b> ${cost}</div>` : "";
        details += hasDateOrderSentToVendor ? `<div><b>Date Order Received From Vendor:</b> ${convertDateToPrintString(dateOrderReceivedFromVendor)}</div>` : "";
        details += hasDateOrderReceivedFromVendor ? `<div><b>Date Order Received From Vendor:</b> ${convertDateToPrintString(dateOrderReceivedFromVendor)}</div>` : "";
        details += hasNumberOfVolumesReceived ? `<div><b>Number Of Volumes Received:</b> ${numberOfVolumesReceived}</div>` : "";
        details += hasTotalNumberOfVolumesReceived ? `<div><b>Total Number Of Volumes Received:</b> ${totalNumberOfVolumesReceived}</div>` : "";
    }
    return details;
}

function createStudent(student) {
    return `${student.firstName || ""} ${student.middleName || ""} ${student.lastName || ""}`;
}


export const order = ({
                          orderId,
                          alternateCopyright,
                          alternateFormat,
                          author,
                          catalogFormatId,
                          copyright,
                          format,
                          gradeLevel,
                          isbn,
                          itemName,
                          needFrom,
                          needTo,
                          orderType,
                          publisherId,
                          publisherName,
                          queueStatus,
                          seriesTitle,
                          shippingLocationName,
                          shippingLocationId,
                          specialInstructions,
                          studentId,
                          studentName,
                          patron,
                          shippingLocation,
                          student,
                          orderStatus,
                          poNumber,
                          cost,
                          dateOrderSentToVendor,
                          dateOrderReceivedFromVendor,
                          numberOfVolumesReceived,
                          totalNumberOfVolumesReceived,
                          isOrderComplete,
                          copyId,
                          volumes,
                      } = {}) => ({
    orderId,
    alternateCopyright,
    alternateFormat,
    author,
    catalogFormatId,
    copyright,
    format,
    gradeLevel,
    isbn,
    itemName,
    needFrom,
    needTo,
    orderType,
    publisherId,
    publisherName,
    queueStatus,
    seriesTitle,
    shippingLocationName,
    shippingLocationId,
    specialInstructions: specialInstructions || ``,
    studentId,
    studentName,
    orderStatus,
    poNumber,
    cost,
    dateOrderSentToVendor,
    dateOrderReceivedFromVendor,
    numberOfVolumesReceived,
    totalNumberOfVolumesReceived,
    isOrderComplete: isNullOrUndefined(isOrderComplete) || isObjectNullOrEmpty(isOrderComplete) ? false: isOrderComplete,
    copyId,
    volumes,
    details: createDetails({
        alternateCopyright,
        alternateFormat,
        author,
        copyright,
        format,
        gradeLevel,
        isbn,
        itemName,
        needFrom,
        needTo,
        orderStatus,
        orderType,
        publisherName,
        seriesTitle,
        student,
        poNumber,
        cost,
        dateOrderSentToVendor,
        dateOrderReceivedFromVendor,
        numberOfVolumesReceived,
        totalNumberOfVolumesReceived,
    }),
    shipping: createShipping({patron, shippingLocation, student})
});

export const placeAnOrderModel = ({
                                         orderId,
                                         formatOrdered,
                                         orderStatus,
                                         isAlternateFormatOrdered = false,
                                         poNumber,
                                         cost
                                     } = {}) => ({
    orderId,
    formatOrdered,
    orderStatus,
    isAlternateFormatOrdered,
    poNumber,
    cost,
});

export const receiveOrderModel = ({
                                          orderId,
                                          orderType,
                                          description,
                                          numberOfVolumesReceived,
                                          volumesReceived,
                                          isOrderComplete,
                                      } = {}) => ({
    orderId,
    orderType,
    description,
    numberOfVolumesReceived,
    volumesReceived,
    isOrderComplete,
});

export const orderProcessingModel = ({
                                         orderId,
                                         formatOrdered,
                                         orderStatus,
                                         isAlternateFormatOrdered = false,
                                         poNumber,
                                         cost,
                                         orderType,
                                         description,
                                         numberOfVolumesReceived,
                                         volumesReceived,
                                         isOrderComplete,
                                     } = {}) => ({
    orderId,
    formatOrdered,
    orderStatus,
    isAlternateFormatOrdered,
    poNumber,
    cost,
    orderType,
    description,
    numberOfVolumesReceived,
    volumesReceived,
    isOrderComplete,
});




