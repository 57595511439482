import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LibraryItemView} from "./LibraryItemView";
import {locations} from "../../Utilities/Location";
import libraryCatalogApi from "./libraryCatalogApi";
import {emptyResourceModel} from "./libraryFactory";
import {emptyGuid} from "../../Utilities/commonUtilities";

export const LibraryItemContainer = ({
                                         handleApiCall,
                                         catalogItemId,
                                         redirect,
                                         systemTypeId,
                                     }) => {
    const [item, setItem] = useState();

    const handleReturn = () => {
        const systemType = systemTypeId || item?.systemType;

        redirect(locations.LIBRARY_CATALOG.getLink(systemType).to)
    };

    const handleSaveResource = async (resource) => {

        return handleApiCall(() => libraryCatalogApi.saveResource(resource),
            handleReturn);
    };

    useEffect(() => {
        const newItem = {
            ...emptyResourceModel,
            systemType: systemTypeId
        }
        const getResource = () => {
            handleApiCall(() => libraryCatalogApi.getResource(catalogItemId), (results = newItem) => setItem(results));
        }

        if (catalogItemId !== emptyGuid && !item)
            getResource();
        else
            setItem(newItem);

    }, [catalogItemId]);

    if (!item) return <></>;

    return <>
        <PageTitle h1={`Library Item`}/>
        <LibraryItemView
            handleSaveResource={handleSaveResource}
            handleReturn={handleReturn}
            item={item}
        />
    </>;
};

LibraryItemContainer.propTypes = {
    catalogItemId: PropTypes.string,
    handleApiCall: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    systemTypeId: PropTypes.string,
};