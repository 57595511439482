import {ORDER_STATUS} from "../orderConstants";

export const orderProcessingFormVisibility = ({
                                                  orderDetails
                                              } = {}) => ({

    canPlaceAnOrder: orderDetails.orderStatus.toString() === ORDER_STATUS.OrderReceivedFromPatron.id.toString(),
    canReceiveOrder: [ORDER_STATUS.ProduceInternally.id, ORDER_STATUS.GeneratePurchaseOrder.id, ORDER_STATUS.OrderSentToVendor.id, ORDER_STATUS.PartialOrderReceived.id, ORDER_STATUS.Mailed.id].includes(orderDetails.orderStatus.toString()),
    canFulfillOrder: Number(orderDetails.orderStatus) >= Number(ORDER_STATUS.PartialOrderReceived.id) && orderDetails.orderStatus.toString() !== ORDER_STATUS.Mailed.id.toString(),
    isOrderComplete: orderDetails.orderStatus.toString() === ORDER_STATUS.OrderComplete.id.toString(),
    displayBarcode: orderDetails.orderStatus.toString() === ORDER_STATUS.AddToCatalogInventory.id.toString()
});