import FileSaver from "file-saver";
import {api as config} from "../../../SiteConfig/siteConstants";
import DownloadUtility from "./downloadUtility"

const blobRequestExport = (notificationFactory, maxRetryCount= config.RETRIES, retryTimeout = 150) => {
	async function send(ajaxRequestOptions) {
		try {
			const fetchOptions = {
				method: ajaxRequestOptions.type,
				headers: ajaxRequestOptions.headers,
				body: ajaxRequestOptions.data
			};

			for (let i = 0; i < maxRetryCount; i++) {
				const response = await fetch(ajaxRequestOptions.url, {
						...fetchOptions,
						credentials: 'include', 
						headers: {
							...fetchOptions.headers,
							'Content-Type': ajaxRequestOptions.contentType || 'application/json'}
					});

				if (response.ok) {
					const blob = await response.blob();

					let filename = DownloadUtility.getFilenameFromHeader(response);
		
					FileSaver.saveAs(blob, filename);

					if (ajaxRequestOptions.successMessage) {
						notificationFactory.createSuccess(ajaxRequestOptions.successMessage).notify();
					}

					return blob;
				} else {
					if (i < maxRetryCount - 1) {
						console.log(response);
						await new Promise(resolve => setTimeout(resolve, retryTimeout)); // Pause before retry
						
					} else {
						const errorText = await response.text();
						notificationFactory.createErrorsFromXhrObject(errorText).notify();
					}
				}
			}
		} catch (error) {
			notificationFactory.createError("Error fetching data").notify(); // Handle error
			throw error;
		}
	}

	return { send };
};

export default blobRequestExport;
