import PropTypes from "prop-types";
import React from "react";
import {HtmlDisplay} from "../Display";
import {GridCell} from "../Grid";
import {inputType, notificationSuffix} from "./inputConstants";
import {Notification} from "./Notification";

export const CheckBoxItem = ({
                                 checked = false,
                                 disabled,
                                 hasTextField,
                                 hideLabel,
                                 label,
                                 labelClass,
                                 name,
                                 onChange,
                                 onChangeOther,
                                 value,
                                 otherTextValue,
                             }) => {

    const otherTextInputName = `${name}_text`;
    labelClass += hideLabel ? " is-visuallyhidden " : labelClass;

    return (
        <span className={`flex-container`}>
            <input
                aria-disabled={disabled}
                type={inputType.CHECKBOX}
                data-testid={name}
                readOnly={disabled}
                disabled={disabled}
                name={name}
                id={name}
                checked={checked}
                onChange={onChange}
                value={value}
            />
            {/* eslint-disable-next-line *//***********LINTER WRONG*************/}
            <label aria-disabled={disabled} className={`${labelClass}`} htmlFor={name}>
        		<HtmlDisplay html={label} isInline/>
			</label>
            {
                hasTextField &&
                checked &&
                <>
                    <label className={`show-for-sr`} htmlFor={otherTextInputName}>{value}, requires additional
                        details</label>
                    <input
                        aria-disabled={disabled}
                        type={inputType.TEXT}
                        name={otherTextInputName}
                        data-testid={otherTextInputName}
                        id={otherTextInputName}
                        value={otherTextValue}
                        onChange={(event) => onChangeOther(value, event.target.value)}
                        readOnly={disabled}
                    />
                </>
            }
		</span>
    );
};
export const CheckBoxField = (props) => {
    const {error, name, label} = props;
    return (
        <GridCell
            aria-invalid={!!error}
            aria-errormessage={`${name}${notificationSuffix}`}
        >
            <CheckBoxItem {...props} />
            <Notification error={error} name={name} label={label}/>
        </GridCell>
    );
};

const checkBoxPropTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    hasTextField: PropTypes.bool,
    hideLabel: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onChangeOther: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    otherTextValue: PropTypes.string,
};

CheckBoxItem.propTypes = {
    ...checkBoxPropTypes,
};

CheckBoxField.propTypes = {
    ...checkBoxPropTypes,
    error: PropTypes.string,
};

