import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LayoutFullBleed} from "../../Components/Layout";
import loanApi from "./loanApi";
import {LoanQueueTable} from "./LoanQueueTable";
import libraryQueueApi from "../Library/libraryQueueApi";
import LoanSearchFormView from "./LoanSearchFormView";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import {createListFromArrayOfObjects} from "../../Utilities/Types/listUtilities";
import {getLoanStatusTextByValue} from "./loanUtility";

export const LoanQueueContainer = ({
                                       handleApiCall,
                                       handleSetLoanSearchCriteria,
                                       isLoading,
                                       loanSearchCriteria,
                                       systemTypeId
                                   }) => {

    const [queue, setQueue] = useState([]);
    const [dropDowns, setDropDowns] = useState(null);

    const handleGetQueue = () => {
        handleApiCall(() => loanApi.getQueue(loanSearchCriteria), (results) => setQueue(results));
    }

    const handleGetDropDowns = () => {
        handleApiCall(() => loanApi.getDropdownLists(systemTypeId), (results) => setDropDowns(results));
    }

    const handleRemoveFromQueue = (queuedItemId) => {
        handleApiCall(() => libraryQueueApi.removeFromQueue(queuedItemId), handleGetQueue);
    }

    const handleLoanQueueItem = (queuedItemId, loanStatus) => {
        const statusText = getLoanStatusTextByValue(loanStatus + 1);
        handleApiCall(() => loanApi.loan(queuedItemId, statusText), handleGetQueue);
    }

    useEffect(() => {
        handleGetQueue();
        handleGetDropDowns();
    }, [loanSearchCriteria]);

    if (isNullOrUndefined(dropDowns)) return;

    const patrons = createListFromArrayOfObjects(dropDowns.patrons, "userId", "fullName");
    const shippingLocations = createListFromArrayOfObjects(dropDowns.shippingLocations, "shippingLocationId", "name");
    const students = createListFromArrayOfObjects(dropDowns.students, "studentId", "fullName");

    return <>
        <PageTitle h1={`Manage Loans`}/>

        <LoanSearchFormView
            handleSetLoanSearchCriteria={handleSetLoanSearchCriteria}
            isLoading={isLoading}
            loanSearchCriteria={loanSearchCriteria}
            patrons={patrons}
            shippingLocations={shippingLocations}
            students={students}
        />

        <LayoutFullBleed className={`catalog`}>
            {
                queue &&
                <LoanQueueTable
                    handleLoanQueueItem={handleLoanQueueItem}
                    handleRemoveFromQueue={handleRemoveFromQueue}
                    handleSetLoanSearchCriteria={handleSetLoanSearchCriteria}
                    loanSearchCriteria={loanSearchCriteria}
                    queue={queue}
                />
            }
        </LayoutFullBleed>
    </>;
};

LoanQueueContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    handleSetLoanSearchCriteria: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    loanSearchCriteria: PropTypes.object,
    systemTypeId: PropTypes.string.isRequired,
};