import React from 'react'
import PropTypes from 'prop-types';
import {reportApi} from "./reportApi";
import {PageTitle} from '../../Components/Landmarks';
import {ReportsFormView} from './ReportsFormView';
import {InputForm, useInputForm} from '../../Components/Inputs';
import { reportsDetailsModel } from './reportsDetailsFactory';


export const ReportsContainer = ({
                                     handleApiCall,
                                 }) => {

    const reportForm = useInputForm(reportsDetailsModel());
   
    const handleExportReport = (event) => {
        event.preventDefault();
        const reportSearchCriteria = {
            reportType: reportForm.reportType.value,
            startDate: reportForm.startDate.value,
            endDate: reportForm.endDate.value
        };

        handleApiCall(() => reportApi.exportReport(reportSearchCriteria));
    };

    return <>
        <PageTitle h1={`Reports`}/>
        <InputForm name={"Reports"}>
            <ReportsFormView
                handleExportReport={handleExportReport}
                reportForm={reportForm}
            />
        </InputForm>
    </>
}

ReportsContainer.propTypes = {
    reportSearchCriteria: PropTypes.object,
    handleApiCall: PropTypes.func
}

