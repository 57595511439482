import {getTextByValue} from "../../Utilities/Types/listUtilities";
import {ORDER_STATUS, ORDER_STATUS_LIST, ORDER_TYPE_LIST} from "./orderConstants";
import {GRADE_LEVEL_LIST} from "../Student/studentConstants";
import {FORMAT_LIST} from "../Library/libraryConstants";

export const getOrderTypeTextByValue = (value = "") => getTextByValue(ORDER_TYPE_LIST, value);

export const getOrderStatusTextByValue = (value = "") => getTextByValue(ORDER_STATUS_LIST, value);
export const getGraveLevelTextByValue = (value = "") => getTextByValue(GRADE_LEVEL_LIST, value);
export const getFormatTextByValue = (value = "") => getTextByValue(FORMAT_LIST, value);

export const getButtonLabelFromOrderStatus = (orderStatus) => {
    switch (orderStatus.toString()) {
        case ORDER_STATUS.PartialOrderReceived.id :
        case ORDER_STATUS.FullOrderReceived.id:
        case ORDER_STATUS.AddToCatalogInventory.id:
            return "Next";
        case ORDER_STATUS.PrintBarCode.id:
            return getOrderStatusTextByValue(ORDER_STATUS.PrintMailingLabel.id);
        case ORDER_STATUS.PrintMailingLabel.id:
            return getOrderStatusTextByValue(ORDER_STATUS.ReadyToMail.id);
        case ORDER_STATUS.ReadyToMail.id:
            return getOrderStatusTextByValue(ORDER_STATUS.Mailed.id);
    }
}