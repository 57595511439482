import {
    apiConstants,
    config,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************

class serverCatalogApi {
    static getResources(librarySearchCriteria)
    {
        const queryParameters = new URLSearchParams(librarySearchCriteria).toString();
        return sendRequest(
            requestOptions(config.URL + `catalog/?${queryParameters}`,
                requestTypes.GET,
            ),
        );
    }

    static getResource(catalogId)
    {
        return sendRequest(
            requestOptions(config.URL + `catalog/${catalogId}`,
                requestTypes.GET,
            ),
        );
    }

    static getPublishers()
    {
        return sendRequest(
            requestOptions(config.URL + `catalog/publishers`,
                requestTypes.GET,
            ),
        );
    }

    static getVendors()
    {
        return sendRequest(
            requestOptions(config.URL + `catalog/vendors`,
                requestTypes.GET,
            ),
        );
    }

    static getWhatsNew(systemTypeId)
    {
        return sendRequest(
            requestOptions(config.URL + `whatsnew/${systemTypeId}`,
                requestTypes.GET,
            ),
        );
    }

    static saveFormat(format)
    {
        const requestType = format.catalogFormatId ? requestTypes.PUT : requestTypes.POST;
        const requestMessage = format.catalogFormatId
            ? apiConstants.successMessage.catalog.CATALOG_ITEM_UPDATED
            : apiConstants.successMessage.catalog.CATALOG_ITEM_ADDED;

        return sendRequest(
            requestOptions(config.URL + `catalog/formats`,
                requestType,
                JSON.stringify(format),
                requestMessage,
            ),
        );
    }

    static saveResource(resource)
    {
        const requestType = resource.catalogId ? requestTypes.PUT : requestTypes.POST;
        const requestMessage = resource.catalogId
            ? apiConstants.successMessage.catalog.CATALOG_ITEM_UPDATED
            : apiConstants.successMessage.catalog.CATALOG_ITEM_ADDED;

        return sendRequest(
            requestOptions(config.URL + `catalog`,
                requestType,
                JSON.stringify(resource),
                requestMessage,
            ),
        );
    }

    static savePublisher(publisher)
    {
        const requestType = publisher.publisherId ? requestTypes.PUT : requestTypes.POST;
        const requestMessage = publisher.publisherId
            ? apiConstants.successMessage.catalog.CATALOG_PUBLISHER_UPDATED
            : apiConstants.successMessage.catalog.CATALOG_PUBLISHER_ADDED;

        return sendRequest(
            requestOptions(config.URL + `catalog/publishers`,
                requestType,
                JSON.stringify(publisher),
                requestMessage,
            ),
        );
    }

    static saveVendor(vendor)
    {
        const requestType = vendor.vendorId ? requestTypes.PUT : requestTypes.POST;
        const requestMessage = vendor.vendorId
            ? apiConstants.successMessage.catalog.CATALOG_VENDOR_UPDATED
            : apiConstants.successMessage.catalog.CATALOG_VENDOR_ADDED;

        return sendRequest(
            requestOptions(config.URL + `catalog/vendors`,
                requestType,
                JSON.stringify(vendor),
                requestMessage,
            ),
        );
    }
}

const libraryCatalogApi = config.environment.MODE === config.modes.SERVER ? serverCatalogApi : serverCatalogApi;
export default libraryCatalogApi;