import PropTypes from "prop-types";
import {Button, DateTimePickerField, SelectField, TextArea, TextField} from "../../../Components/Inputs";
import {GridCell, gridConstants} from "../../../Components/Grid";
import React from "react";
import {FORMAT_LIST, GRADE_LEVEL_LIST} from "../../Library/libraryConstants";
import SearchableSingleSelectField from "../../../Components/Inputs/SearchableSingleSelectField";
import {isAdmin} from "../../../Utilities/LocalStorage/storageUtilities";
import {isObjectNullOrEmpty} from "../../../Utilities/Types/objectUtilities";

export const OrderRequestForm = ({
                                     handleAddLocationClick,
                                     handleRemoveLocationClick,
                                     shippingLocations,
                                     shippingLocationsDetails,
                                     orderRequestForm,
                                     publishers,
                                     students,
                                     userId,
                                     viewOnly
                                 }) => {

    const selectedShippingLocationUserId = shippingLocationsDetails?.find(s => s.shippingLocationId === orderRequestForm.shippingLocationId.value)?.userId;

    return <>
        <SearchableSingleSelectField
            {...orderRequestForm.shippingLocationId}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}
            includeDefaultOption
            label={'Active Location List'}
            options={shippingLocations}
        />

        <SelectField
            {...orderRequestForm.studentId}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}
            label={`Student`}
            options={students}/>
        {
            !viewOnly &&
            <>
                <GridCell medium_columns={gridConstants.column.THREE}>
                    <Button
                        disabled={isObjectNullOrEmpty(selectedShippingLocationUserId) || userId !== selectedShippingLocationUserId && !isAdmin()}
                        label="Remove Location"
                        name="btnRemoveLocation"
                        onClick={handleRemoveLocationClick}
                    />
                </GridCell>

                <GridCell medium_columns={gridConstants.column.THREE}>
                    <Button
                        label="Add Location"
                        name="btnAddLocation"
                        onClick={handleAddLocationClick}
                    />
                </GridCell>
            </>
        }

        <hr/>

        <TextField
            {...orderRequestForm.itemName}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}
            helpText={`<p>Please enter a book title, device, or supply description.</p>`}/>

        <TextField
            {...orderRequestForm.isbn}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}/>

        <DateTimePickerField
            {...orderRequestForm.needFrom}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}/>

        <DateTimePickerField
            {...orderRequestForm.needTo}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}/>

        <SelectField
            {...orderRequestForm.format}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}
            label={`Medium Requested`}
            options={FORMAT_LIST}/>

        <SelectField
            {...orderRequestForm.publisherId}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}
            label={`Publisher`}
            options={publishers}/>

        <DateTimePickerField
            {...orderRequestForm.copyright}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}/>

        <TextField
            {...orderRequestForm.author}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}/>

        <TextField
            {...orderRequestForm.seriesTitle}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}/>

        <DateTimePickerField
            {...orderRequestForm.alternateCopyright}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}/>

        <SelectField
            {...orderRequestForm.gradeLevel}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}
            options={GRADE_LEVEL_LIST}/>

        <SelectField
            {...orderRequestForm.alternateFormat}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}
            label={`Alternate Medium`}
            options={FORMAT_LIST}/>

        <TextArea
            {...orderRequestForm.specialInstructions}
            disabled={viewOnly}
            medium_columns={gridConstants.column.SIX}/>
    </>;
};

OrderRequestForm.propTypes = {
    handleAddLocationClick: PropTypes.func.isRequired,
    handleRemoveLocationClick: PropTypes.func.isRequired,
    shippingLocations: PropTypes.array.isRequired,
    shippingLocationsDetails: PropTypes.array.isRequired,
    orderRequestForm: PropTypes.object.isRequired,
    publishers: PropTypes.array.isRequired,
    students: PropTypes.array.isRequired,
    userId: PropTypes.string.isRequired,
    viewOnly: PropTypes.bool
};