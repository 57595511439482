import React from 'react'
import PropTypes from 'prop-types';
import { PageTitle } from '../../Components/Landmarks';
import { InputForm, useInputForm } from '../../Components/Inputs';
import { CheckInFormView } from './CheckInFormView';
import { checkInDetailsModel } from './checkInDetailsFactory';
import { LayoutMedium } from '../../Components/Layout';
import {checkInApi} from './checkInApi';

export const CheckInContainer = (
    {
        handleApiCall,
    }
) => {

    const checkInForm = useInputForm(checkInDetailsModel());


    const validateForm = (checkInForm, checkIn) => {
        let formValid = true;

        if(!checkIn){

            if (checkInForm.copyBarcode.value === '') {
                checkInForm.copyBarcode.setError('Copy Barcode is required');
                formValid = false;
            }
    
            if (checkInForm.shelfLocation.value === '') {
                checkInForm.shelfLocation.setError('Shelf Location is required.');
                formValid = false;
            }

        }
        

        return formValid;

    }

    const updateVolumes = (newVolumes) => {
        checkInForm.volumes.setValue(newVolumes); // Update the form state
    };

    const preparePayload = (form) => {
        return {
            copyBarcode: form.copyBarcode.value,
            shelfLocation: form.shelfLocation.value,
            volumes: form.volumes.value.map(volume => ({
                barcode: volume.barcode,
                shelfLocation: volume.shelfLocation,
                status: volume.status,
            })),
        };
    };

    const handleSubmit = (event, checkIn) => {

        let formValid = validateForm(checkInForm, checkIn);
        if(formValid){
            const payload = preparePayload(checkInForm);
            handleApiCall(() => checkInApi.checkInItems(payload));

        }
        event.preventDefault();
       
    };

    return <>
    <LayoutMedium>
         <PageTitle h1={`Check In`}/>
        <InputForm name={"Check In"}>
            <CheckInFormView
                checkInForm={checkInForm}     
                handleSubmit={handleSubmit}  
                updateVolumes={updateVolumes}        
            />
        </InputForm>
    </LayoutMedium>
       
    </>
}

CheckInContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired, 
}