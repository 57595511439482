import { createListFromObject } from "../../Utilities/Types/listUtilities";

export const REPORT_TYPES = {
    Patrons: "patrons",
    Students: "students",
    Orders: "orders",
    LoanLibrary: "loan-library"

};

export const REPORT_TYPES_LIST = createListFromObject(REPORT_TYPES);