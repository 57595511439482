import {ORDER_TYPE} from "../orderConstants";

export const orderRequestModel = ({
                                      alternateCopyright,
                                      alternateFormat,
                                      author,
                                      catalogFormatId,
                                      copyright,
                                      format,
                                      gradeLevel,
                                      isbn,
                                      itemName,
                                      needFrom,
                                      needTo,
                                      orderType = ORDER_TYPE.SpecialOrderRequest,
                                      publisherId,
                                      seriesTitle,
                                      shippingLocationId,
                                      specialInstructions = ``,
                                      studentId
                                  } = {}) => ({
    alternateCopyright,
    alternateFormat,
    author,
    catalogFormatId,
    copyright,
    format,
    gradeLevel,
    isbn,
    itemName,
    needFrom,
    needTo,
    orderType,
    publisherId,
    seriesTitle,
    shippingLocationId,
    specialInstructions,
    studentId
});


export const orderEditViewModel = ({
                                       alternateCopyright,
                                       alternateFormat,
                                       author,
                                       catalogFormatId,
                                       copyright,
                                       format,
                                       gradeLevel,
                                       isbn,
                                       itemName,
                                       needFrom,
                                       needTo,
                                       orderId,
                                       orderType = ORDER_TYPE.SpecialOrderRequest,
                                       publisherId,
                                       queueStatus,
                                       seriesTitle,
                                       shippingLocationId,
                                       specialInstructions,
                                       studentId
                                   } = {}) => ({

    alternateCopyright,
    alternateFormat,
    author,
    catalogFormatId,
    copyright,
    format,
    gradeLevel,
    isbn,
    itemName,
    needFrom,
    needTo,
    orderId,
    orderType,
    publisherId,
    queueStatus,
    seriesTitle,
    shippingLocationId,
    specialInstructions: specialInstructions || ``,
    studentId
});

