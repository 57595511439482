import React from "react";
import PropTypes from "prop-types";
import {HtmlDisplay} from "../../Components/Display";
import {Button, ButtonLinkStyle} from "../../Components/Inputs";
import {GridCell, gridConstants, GridContainer} from "../../Components/Grid";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import {ORDER_TYPE} from "../Order/orderConstants";
import {CheckBoxItem} from "../../Components/Inputs/CheckboxField";

export const LibraryQueueItem = ({
                                     catalogId,
                                     details,
                                     handleDelete,
                                     handleViewOrder,
                                     handleSelectedItemsChange,
                                     isSelected,
                                     orderId,
                                     orderType,
                                     queueItemId,
                                     title,
                                 }) => {


    const handleClickViewOrder = () => handleViewOrder(orderId, catalogId);

    return <>
        <GridContainer className={`catalog__item`} hasMarginX>

            <GridCell small_columns={gridConstants.column.TWO} medium_columns={gridConstants.column.ONE}>
                {
                    queueItemId &&
                    <CheckBoxItem checked={isSelected} hideLabel label={`Select ${title}`} name={`cb${queueItemId}`}
                                  onChange={handleSelectedItemsChange} value={queueItemId}/>
                }
            </GridCell>
            <GridCell small_columns={gridConstants.column.TEN} medium_columns={gridConstants.column.ELEVEN}>
                <h3>{title}</h3>

                <HtmlDisplay html={details}/>
                {
                    handleDelete && isNullOrUndefined(orderId) &&
                    <Button isCollapsed label={`Cancel Request`} name={`btnDelete${catalogId}`} onClick={handleDelete}/>
                }
                {
                    handleViewOrder && !isNullOrUndefined(orderId) &&
                    <ButtonLinkStyle isCollapsed
                                     label={orderType === ORDER_TYPE.SpecialOrderRequest.toString() ? `(View Special Order)` : `View Order`}
                                     name={`btnViewOrder${catalogId}`}
                                     onClick={handleClickViewOrder}/>
                }

            </GridCell>
        </GridContainer>
    </>;
};

LibraryQueueItem.propTypes = {
    catalogId: PropTypes.string,
    details: PropTypes.string,
    handleDelete: PropTypes.func,
    handleSelectedItemsChange: PropTypes.func,
    handleViewOrder: PropTypes.func,
    isSelected: PropTypes.bool,
    orderId: PropTypes.string,
    orderType: PropTypes.number,
    queueItemId: PropTypes.string,
    title: PropTypes.string,
};