import {searchModel} from "../../Utilities/Search";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import {dynamicSort} from "../../Utilities/Sort";

export const librarySearchModel = (userId = "", systemType = "") => {
    return Object.assign({}, searchModel(userId.toString(), "itemName", false, "itemName"), {
        systemType,
        ClearOrderSearch: clearOrderSearch,
        ApplySorting: applySorting,
    });
};

function clearOrderSearch() {
    this.systemType = "";
    this.ClearSearch(false);
}

function applySorting(librarySearchCriteria, items) {
    if (isNullOrUndefined(items) || isArrayNullOrEmpty(items))
        return items;

    return items.sort(dynamicSort(librarySearchCriteria.sortByColumn, librarySearchCriteria.sortDescending));
}

