import {apiConstants, config, requestOptions, requestTypes, sendRequest} from "../../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************
class serverOrderProcessingApi {
    static getOrder(orderId)
    {
        return sendRequest(
            requestOptions(config.URL + `orders/${orderId}`,
                requestTypes.GET,
            ),
        );
    }

    static placeAnOrder(placeAnOrder)
    {
        return sendRequest(
            requestOptions(config.URL + `orders`,
                requestTypes.PUT,
                JSON.stringify(placeAnOrder),
                apiConstants.successMessage.order.PLACE_AN_ORDER,
            ),
        );
    }

    static receiveOrder(receiveOrderModel)
    {
        return sendRequest(
            requestOptions(config.URL + `order-fulfilment`,
                requestTypes.POST,
                JSON.stringify(receiveOrderModel),
                apiConstants.successMessage.order.RECEIVED_ORDER,
            ),
        );
    }

    static fulfillOrder(orderId)
    {
        return sendRequest(
            requestOptions(config.URL + `order-fulfilment/${orderId}`,
                requestTypes.PUT,
                apiConstants.successMessage.order.FULFILL_ORDER,
            ),
        );
    }

}

const orderProcessingApi = config.environment.MODE === config.modes.SERVER ? serverOrderProcessingApi : serverOrderProcessingApi;
export default orderProcessingApi;