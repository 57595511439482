import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {gridConstants, GridContainer} from "../../../Components/Grid";
import GridCell from "../../../Components/Grid/GridCell";

import {createYesNoObject, reinitializeInputFormFields, useInputForm} from "../../../Components/Inputs/inputUtility";
import {isTrimmedStringEmpty} from "../../../Utilities/commonUtilities";
import {
    Button,
    clearInputFormErrorFields,
    convertInputFormToObject,
    InputForm,
    inputFormIsValid,
} from "../../../Components/Inputs";
import {locations} from "../../../Utilities/Location";
import {EDUCATIONAL_FACILITY, GRADE_LEVEL, STUDENT_STATUS, STUDENT_APPROVAL_STATUS} from "../studentConstants";
import {StudentRegistrationEligibilityRequirements} from "./StudentRegistrationEligibilityRequirements";
import {isAdmin, isFimcAdmin} from "../../../Utilities/LocalStorage/storageUtilities";
import {studentRegistrationFormVisibility} from "./StudentRegistrationFormVisibilityFactory";
import {StudentRegistrationForm} from "./StudentRegistrationForm";

export const StudentRegistrationView = ({
                                            checkForDuplicateStudents,
                                            districts,
                                            duplicateStudents,
                                            handleRegisterStudent,
                                            isEditing,
                                            isLoading,
                                            patronDistrict,
                                            patronName,
                                            patronRole,
                                            previousStudentDetails,
                                            readOnly,
                                            redirect,
                                            setWarnings,
                                            studentDetails
                                        }) => {

    const studentDetailsForm = useInputForm(studentDetails);

    const [notCertified, setNotCertified] = useState(false);

    const yesNoObject = createYesNoObject();
    const formVisibility = studentRegistrationFormVisibility({
        duplicateStudents,
        isEditing,
        previousStudentDetails,
        studentDetailsForm,
        yesNoObject
    });

    const handleClickDenyEligibility = () =>
        redirect(locations.STUDENT_MANAGEMENT.path);

    const checkAndSetError = (fields) => {
        fields.forEach((field) => {
            if (isTrimmedStringEmpty(field.value?.toString())) {
                field.setError(`Field is required.`);
            }
        });
    };

    const isFormValid = () => {
        clearInputFormErrorFields(studentDetailsForm);

        checkAndSetError([
            studentDetailsForm.lastName,
            studentDetailsForm.firstName,
            studentDetailsForm.dateOfBirth,
            studentDetailsForm.districtId,
            studentDetailsForm.educationalFacility,
            studentDetailsForm.writtenPlan,
            studentDetailsForm.isFlviRegistered,
            studentDetailsForm.isDualSensoryImpaired,
            studentDetailsForm.gradeLevel,
            studentDetailsForm.language,
            studentDetailsForm.certification,
            studentDetailsForm.hasNeurologicalImpairment,
        ]);

        const today = new Date();
        const dateOfBirth = new Date(studentDetailsForm.dateOfBirth.value);

        if (dateOfBirth > today) {
            studentDetailsForm.dateOfBirth.setError("Date of birth cannot be in the future.");
        }

        if(studentDetailsForm.isEligible.value == false && studentDetailsForm.understandsNoAphAccess.value == false){
            studentDetailsForm.isEligible.setError("Please select at least one eligibility requirement.");
            studentDetailsForm.understandsNoAphAccess.setError("Please select at least one eligibility requirement.");
        }


        if (formVisibility.showAgeOverride) {
            checkAndSetError([studentDetailsForm.gradeAgeConfirmation]);
        } else {
            studentDetailsForm.gradeAgeConfirmation.setValue('');
        }

        if (studentDetailsForm.hasNeurologicalImpairment.value && studentDetailsForm.hasNeurologicalImpairment.value?.toString() === yesNoObject.No) {
            checkAndSetError([studentDetailsForm.hasVisualImpairment]);
        }

        if (studentDetailsForm.hasVisualImpairment.value?.toString() === yesNoObject.Yes) {
            checkAndSetError([
                studentDetailsForm.distanceVisualAcuityRight,
                studentDetailsForm.distanceVisualAcuityLeft,
                studentDetailsForm.peripheralFieldLoss,
            ]);
        }

        if (studentDetailsForm.educationalFacility.value === EDUCATIONAL_FACILITY.Homeschool.id) {
            checkAndSetError([studentDetailsForm.hasHomeSchoolRequirementAgreement]);
        }

        if (studentDetailsForm.isDualSensoryImpaired.value?.toString() === yesNoObject.Yes) {
            checkAndSetError([studentDetailsForm.isFaviRegistered]);
        }

        if (studentDetailsForm.gradeLevel.value !== GRADE_LEVEL.IP.toString()) {
            checkAndSetError([
                studentDetailsForm.primaryReadingMedium,
                studentDetailsForm.secondaryReadingMedium,
                studentDetailsForm.otherReadingMedium,
            ]);
        }

        if (isEditing) {
            if (studentDetailsForm.studentStatus.value.toString() === STUDENT_STATUS.Inactive.id) {
                checkAndSetError([studentDetailsForm.studentInactiveReason]);
            }

            if (isFimcAdmin() && (studentDetailsForm.studentApprovalStatus.value.toString() === STUDENT_APPROVAL_STATUS.Approved.toString()))
                checkAndSetError([studentDetailsForm.eligibility]);

            if ((studentDetailsForm.studentApprovalStatus.value.toString() === STUDENT_APPROVAL_STATUS.Deleted.toString())
                && (studentDetailsForm.studentStatus.value.toString() === STUDENT_STATUS.Active.id)) {
                studentDetailsForm.studentStatus.setError("The student statuses are in a conflicting state.")
            } else if (isAdmin() && (studentDetailsForm.studentApprovalStatus.value.toString() === STUDENT_APPROVAL_STATUS.Approved.toString())
                && (studentDetailsForm.studentStatus.value.toString() === STUDENT_STATUS.Inactive.id)) {
                studentDetailsForm.studentStatus.setError("The student statuses are in a conflicting state.")
            }
        }

        let isValid = inputFormIsValid(studentDetailsForm);

        if (!isValid)
            setWarnings([
                `${studentDetailsForm.studentStatus.error} Missing required items.  Please review your responses and try submitting again.`,
            ]);

        return isValid;
    };

    const handleSubmit = () => {
        if (isFormValid()) {
            const student = convertInputFormToObject(studentDetailsForm);
            handleRegisterStudent(student);
        }
    };

    useEffect(() => {
        if (studentDetails.studentId !== studentDetailsForm.studentId.value)
            reinitializeInputFormFields(studentDetailsForm, studentDetails);
        // eslint-disable-next-line

        if (studentDetailsForm.certification.value?.toString() === yesNoObject.No) {
            studentDetailsForm.certification.setError("Please gather the necessary information in order to complete this registration.");
            setNotCertified(true);
        }
        else if (studentDetailsForm.certification.value?.toString() === yesNoObject.Yes){
            studentDetailsForm.certification.setError("");
            setNotCertified(false);
        }
    }, [studentDetails.studentId, studentDetailsForm.studentId.value, studentDetailsForm.certification.value]);

    return (
        <InputForm name={"studentDetails"}>
            <StudentRegistrationEligibilityRequirements
                readOnly={readOnly}
                studentDetailsForm={studentDetailsForm}
            />

            {
                (studentDetailsForm.isEligible.value || studentDetailsForm.understandsNoAphAccess.value) &&
                <StudentRegistrationForm
                    checkAndSetError={checkAndSetError}
                    checkForDuplicateStudents={checkForDuplicateStudents}
                    districts={districts}
                    duplicateStudents={duplicateStudents}
                    formVisibility={formVisibility}
                    isEditing={isEditing}
                    isLoading={isLoading}
                    patronDistrict={patronDistrict}
                    patronName={patronName}
                    patronRole={patronRole}
                    readOnly={readOnly}
                    setWarnings={setWarnings}
                    studentDetailsForm={studentDetailsForm}
                />
            }

            <GridCell>
                <GridContainer className={`horizontal-center`} hasMarginX hasMarginY>
                    {
                        !formVisibility.saveIsHidden && ((formVisibility.isOverrideOrNoDuplicate && !formVisibility.isFormDataChanged) || !isFimcAdmin()) &&
                        <GridCell medium_columns={gridConstants.column.SIX}>
                            <Button
                                isPrimary
                                label={`Submit`}
                                name={`btnSubmit`}
                                onClick={handleSubmit}
                                disabled={isLoading || notCertified || !studentDetailsForm.certification.value}
                            />
                        </GridCell>
                    }

                    <GridCell medium_columns={gridConstants.column.SIX}>
                        <Button
                            isPrimary
                            label={`Go Back`}
                            name={`btnExit`}
                            onClick={handleClickDenyEligibility}
                        />
                    </GridCell>
                </GridContainer>
            </GridCell>
        </InputForm>
    );
};

StudentRegistrationView.propTypes = {
    checkForDuplicateStudents: PropTypes.func.isRequired,
    districts: PropTypes.arrayOf(PropTypes.object),
    duplicateStudents: PropTypes.arrayOf(PropTypes.object),
    handleRegisterStudent: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    patronDistrict: PropTypes.string,
    patronName: PropTypes.string.isRequired,
    patronRole: PropTypes.number.isRequired,
    previousStudentDetails: PropTypes.object,
    readOnly: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
    studentDetails: PropTypes.object.isRequired,
};
