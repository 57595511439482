import {PageTitle} from "../../../Components/Landmarks";
import React, {useEffect, useRef, useState} from "react";
import {locations} from "../../../Utilities/Location";
import PropTypes from "prop-types";
import {GridCell} from "../../../Components/Grid";
import {emptyResourceModel} from "../../Library/libraryFactory";
import {createListFromArrayOfObjects} from "../../../Utilities/Types/listUtilities";
import {orderEditViewModel, orderRequestModel} from "./orderRequestFactory";
import {isGuidEmpty, isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {OrderRequestView} from "./OrderRequestView";
import OrderRequestApi from "./orderRequestApi";
import ShippingLocationApi from "../ShippingLocation/shippingLocationApi";
import {ORDER_STATUS, ORDER_TYPE} from "../orderConstants";

export const OrderRequestContainer = ({
                                          catalogFormatId,
                                          catalogItemId,
                                          handleApiCall,
                                          handleReturnRedirect,
                                          orderId,
                                          redirect,
                                          userId,
                                      }) => {

    const [orderRequestDetails, setOrderRequestDetails] = useState(null);
    const [students, setStudents] = useState();
    const [publishers, setPublishers] = useState();
    const [shippingLocations, setShippingLocations] = useState();
    const shippingLocationsDetails = useRef();
    const title = orderRequestDetails?.orderType === ORDER_TYPE.Order ? `Order` : `Special Order Request`;

    const handleAddLocation = () => redirect(locations.SHIPPING_LOCATION_ADD.path);

    const handleRemoveLocation = async (shippingLocationId) => {
        if (confirm(`This will remove the location from your Active Locations. Are you sure you want to continue?`))
            handleApiCall(() => ShippingLocationApi.removeShippingLocation(shippingLocationId), setShippingLocationResult);
    };

    const handleSubmitOrderRequest = async (orderRequest) => {

        const sanitizedModel = orderRequestModel(orderRequest);

        handleApiCall(() => OrderRequestApi.addOrder(sanitizedModel), () => {
            handleReturnRedirect();
        });
    };

    const handleCancelOrder = async () => {

        orderRequestDetails.queueStatus = ORDER_STATUS.OrderReceivedFromPatron;

        handleApiCall(() => OrderRequestApi.cancelOrder(orderId), () => {
            handleReturnRedirect();
        });
    }

    const loadOrderRequestDetailsResult = (result) => {

        const {
            copies,
            format,
            publisherId,
            numberOfCopiesAvailable
        } = result.formats.find(f => f.catalogFormatId === catalogFormatId);

        const orderRequestResult = orderRequestModel({
            ...result,
            shippingLocationId: ``,
            authors: result.author,
            catalogFormatId: catalogFormatId,
            format: format,
            gradeLevel: result.gradeLevel,
            isbn: result.isbn13 ? result.isbn13 : (result.isbn10 ? result.isbn10 : result.previewIsbn),
            itemName: result.title,
            orderType: copies.length === 0 ? ORDER_TYPE.SpecialOrderRequest : copies.length !== 0 && numberOfCopiesAvailable === 0 ? ORDER_TYPE.Order : ORDER_TYPE.SpecialOrderRequest,
            publisherId: publisherId,
        });

        setOrderRequestDetails(orderRequestResult);
    };

    const loadOrderResult = (result) => {
        const order = orderEditViewModel(result);
        setOrderRequestDetails(order);
    };

    const setShippingLocationResult = (results) => {
        shippingLocationsDetails.current = results;
        setShippingLocations(createListFromArrayOfObjects(results, `shippingLocationId`, `name`));
    }

    useEffect(() => {

        const getResource = () => {
            handleApiCall(() => OrderRequestApi.getResource(catalogItemId), (results = emptyResourceModel) => loadOrderRequestDetailsResult(results));
        }

        const getOrderDetails = () => {
            handleApiCall(() => OrderRequestApi.getOrder(orderId), (result = emptyResourceModel) => loadOrderResult(result));
        }

        const getStudents = () => {
            handleApiCall(() => OrderRequestApi.getStudents({
                userId
            }), (results) => {
                setStudents(createListFromArrayOfObjects(results, `studentId`, `fullName`));
            });
        };

        const getShippingLocations = () => {
            handleApiCall(() => OrderRequestApi.getShippingLocations(), setShippingLocationResult);
        }

        const getPublishers = () => {
            handleApiCall(() => OrderRequestApi.getPublishers(), (results) => {
                setPublishers(createListFromArrayOfObjects(results, `publisherId`, `name`));
            });
        }

        if (orderId && !isGuidEmpty(orderId))
            getOrderDetails();
        if (catalogItemId && !isGuidEmpty(catalogItemId) && !orderRequestDetails)
            getResource();
        else if (isGuidEmpty(catalogItemId))
            setOrderRequestDetails(orderRequestModel());

        if (!shippingLocations)
            getShippingLocations();
        if (!publishers)
            getPublishers();
        if (!students)
            getStudents();

    }, [catalogItemId, orderId]);

    return <>
        <PageTitle h1={title}/>{
        !isNullOrUndefined(orderRequestDetails) &&
        <>
            <GridCell>
                {orderRequestDetails.orderType === ORDER_TYPE.SpecialOrderRequest &&
                    <p>If you have searched for a title and you have not found the item you are looking for, you can
                        fill out this special order request form and the FIMC-VI staff will then research the book and
                        inform you of the results. <span className={`alert-color`}>Special Orders take extra time to fulfill.</span>
                    </p>
                }
                {
                    orderRequestDetails.orderType !== ORDER_TYPE.SpecialOrderRequest &&
                    <p>
                        We are currently out of copies for this item, you can fill out this form
                        to request copies and the FIMC-VI staff will inform you of the results. <span
                        className={`alert-color`}>Orders take time to fulfill.</span>
                    </p>
                }
            </GridCell>
            {
                shippingLocations && students &&
                <OrderRequestView
                    shippingLocations={shippingLocations}
                    shippingLocationsDetails={shippingLocationsDetails.current}
                    handleAddLocation={handleAddLocation}
                    handleCancelOrder={handleCancelOrder}
                    handleRemoveLocation={handleRemoveLocation}
                    handleReturn={handleReturnRedirect}
                    handleSubmitOrderRequest={handleSubmitOrderRequest}
                    publishers={publishers}
                    orderRequestDetails={orderRequestDetails}
                    students={students}
                    userId={userId}
                    viewOnly={!(isNullOrUndefined(orderId) || isGuidEmpty(orderId))}
                />
            }
        </>
    }
    </>;
};

OrderRequestContainer.propTypes = {
    catalogFormatId: PropTypes.string,
    catalogItemId: PropTypes.string.isRequired,
    handleApiCall: PropTypes.func.isRequired,
    handleReturnRedirect: PropTypes.func.isRequired,
    orderId: PropTypes.string,
    redirect: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
};