import { createListFromObject } from "../../Utilities/Types/listUtilities";

export const CHECKIN_TYPES = {
    Available: "available",
    CheckedOut: "checked-out",
    Damaged: "damaged",
    Missing: "missing",
    Purged: "purged", 
};

export const CHECKIN_TYPES_LIST = createListFromObject(CHECKIN_TYPES);