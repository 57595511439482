import PropTypes from "prop-types";
import React from "react";
import {PageTitle} from "../../Components/Landmarks";
import {locations} from "../../Utilities/Location";
import {Allow, policyEvents} from "../../Components/Authorize";
//import {LayoutMedium} from "../../Components/Layout";
import {DashboardTile} from "./DashboardTile";
import {GridContainer} from "../../Components/Grid";
import {LayoutFullBleed, LayoutMedium} from "../../Components/Layout";

export const DashboardContainer = ({header = "Dashboard", message = ""}) => {

    return (
        <>
            <PageTitle h1={header}/>
            <LayoutFullBleed className={`catalog`}>

                {message && <p className="text-center">{message}</p>}

                <LayoutMedium>
                    <header>
                        <h2>Resources</h2>
                    </header>
                    <GridContainer hasMarginX hasMarginY className={`dashboard`}>
                        <DashboardTile
                            {...locations.LIBRARY_TEXTBOOKS.link}
                            description={"Search, view, update"}
                            iconName="Textbooks"
                        />
                        <DashboardTile
                            {...locations.LIBRARY_LOAN.link}
                            description={"Search, view, update"}
                            iconName="Loan-Library"
                        />
                        <DashboardTile
                            {...locations.LIBRARY_APH.link}
                            description={"Search, view, update"}
                            iconName="APH"
                        />
                        <DashboardTile
                            {...locations.LIBRARY_BRAILLERS.link}
                            description={"Search, view, update"}
                            iconName="Braille-Machines"
                        />
                    </GridContainer>

                    <br/>
                    <br/>

                    <header>
                        <h2>Requested Resources</h2>
                    </header>
                    <GridContainer hasMarginX hasMarginY className={`dashboard`}>
                        <Allow policyEvent={policyEvents.MANAGE_LOANS}>
                            <DashboardTile
                                {...locations.LOAN_MANAGEMENT.link}
                                description={"Search, view, update"}
                                iconName="Loan-Management"
                            />
                        </Allow>
                        <Allow policyEvent={policyEvents.MANAGE_ORDER}>
                            <DashboardTile
                                {...locations.ORDER_MANAGEMENT.link}
                                description={"Manage orders and special orders"}
                                iconName="Order-Management"
                            />
                        </Allow>
                        <Allow policyEvent={policyEvents.MANAGE_ORDER}>
                            <DashboardTile
                                {...locations.CHECK_IN.link}
                                description={"Check in resources"}
                                iconName="Check-In"
                            />
                        </Allow>
                    </GridContainer>

                    <br/>
                    <br/>

                    <header>
                        <h2>Administration</h2>
                    </header>
                    <GridContainer hasMarginX hasMarginY className={`dashboard`}>
                        <Allow policyEvent={policyEvents.VIEW_STUDENTS}>
                            <DashboardTile
                                {...locations.STUDENT_MANAGEMENT.link}
                                description={"Search, view, update"}
                                iconName="Student-Management"
                            />
                        </Allow>
                        <Allow policyEvent={policyEvents.APPROVE_USERS}>
                            <DashboardTile
                                {...locations.USER_MANAGEMENT.link}
                                description={"View and manage all users"}
                                iconName="User-Management"
                            />
                        </Allow>
                        <Allow policyEvent={policyEvents.ADVANCE_SEARCH}>
                            <DashboardTile
                                {...locations.REPORTS.link}
                                description={"View reports"}
                                iconName="Reports"
                            />
                        </Allow>
                    </GridContainer>
                    <br/>
                    <br/>


                </LayoutMedium>
            </LayoutFullBleed>
        </>
    );
};

DashboardContainer.propTypes = {
    header: PropTypes.string,
    message: PropTypes.string,
    redirect: PropTypes.func.isRequired,
};
