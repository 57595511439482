import {convertToDate, getCurrentYear, getTodayDate, getYearFromDate} from "../../Utilities/Types/dateUtilities";
import {isFimcAdmin} from "../../Utilities/LocalStorage/storageUtilities";
import {getTextByValue} from "../../Utilities/Types/listUtilities";
import {
    COMPLETE_READING_MEDIUMS_LIST,
    GRADE_LEVEL_LIST,
    LANGUAGE_LIST,
    STUDENT_APPROVAL_STATUS_LIST, STUDENT_ELIGIBILITY_LIST
} from "./studentConstants";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";

export function isCensusMonthAndNotAdmin(censusEndDate) {
    return isCensusMonth(censusEndDate) && !isFimcAdmin();
}

export function isCensusMonthAndAdmin(censusEndDate) {
    return isCensusMonth(censusEndDate) && isFimcAdmin();
}

export function isCensusMonth(censusEndDate) {
    return getTodayDate().getMonth() < 2
        && (isNullOrUndefined(censusEndDate) || getTodayDate() < convertToDate(censusEndDate) || getCurrentYear() > getYearFromDate(censusEndDate));
}

export const getGradeLevelTextByValue = (value) => getTextByValue(GRADE_LEVEL_LIST, value);
export const getLanguageTextByValue = (value) => getTextByValue(LANGUAGE_LIST, value);
export const getCompleteReadingMediumsTextByValue = (value) => getTextByValue(COMPLETE_READING_MEDIUMS_LIST, value);
export const getStudentApprovalStatusTextByValue = (value) => getTextByValue(STUDENT_APPROVAL_STATUS_LIST, value);
export const getEligibilityTextByValue = (value) => getTextByValue(STUDENT_ELIGIBILITY_LIST, value);
