import PropTypes from "prop-types";
import React from "react";
import {GridCell, GridContainer} from "../Grid";
import {HelpText} from "./HelpText";
import {helpTextSuffix} from "./inputConstants";

export const InputForm = ({
                              children,
                              hasMarginX = true,
                              hasMarginY,
                              hasPaddingX,
                              hasPaddingY,
                              helpHtml,
                              name
                          }) => {
    const handleSubmit = (event) => event.preventDefault();

    return (
        <form aria-describedby={`${name}${helpTextSuffix}`} onSubmit={handleSubmit}>
            <GridContainer hasMarginX={hasMarginX} hasMarginY={hasMarginY} hasPaddingX={hasPaddingX} hasPaddingY={hasPaddingY}>
                {children}
                <GridCell>
                    <HelpText name={name} helpText={helpHtml}/>
                </GridCell>
            </GridContainer>
        </form>

    );
};

InputForm.propTypes = {
    children: PropTypes.any.isRequired,
    hasMarginX: PropTypes.bool,
    hasMarginY: PropTypes.bool,
    hasPaddingX: PropTypes.bool,
    hasPaddingY: PropTypes.bool,
    helpHtml: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    name: PropTypes.string.isRequired,
};