import {
	apiConstants,
	config,
	requestOptions,
	requestTypes,
    sendRequest,
} from "../../Utilities/Api";

class ServerCheckInApi {
    static checkInItems(checkInModel) 
    {
        return sendRequest(
            requestOptions(config.URL + `loan/return`,
                requestTypes.PUT,
                JSON.stringify(checkInModel),
                apiConstants.successMessage.checkIn.CHECKIN,
            ),
        )
    }

}

const checkInApi = ServerCheckInApi;
export {checkInApi};