import {createDescriptionObjectForList, createListFromObject} from "../../Utilities/Types/listUtilities";
import {createObjectFromObjectPropertyNames} from "../../Utilities/Types/objectUtilities";

export const EDUCATIONAL_FACILITY = {
    Public: createDescriptionObjectForList("1", "Public"),
    Charter: createDescriptionObjectForList("2", "Charter"),
    Private: createDescriptionObjectForList("3", "Private"),
    Homeschool: createDescriptionObjectForList("4", "Homeschool"),
    Agency: createDescriptionObjectForList("5", "Agency")
};

export const EDUCATIONAL_FACILITY_LIST = createListFromObject(EDUCATIONAL_FACILITY);

export const GRADE_LEVEL = {
    IP: 1,
    PS: 2,
    KG: 3,
    First: 4,
    Second: 5,
    Third: 6,
    Fourth: 7,
    Fifth: 8,
    Sixth: 9,
    Seventh: 10,
    Eighth: 11,
    Ninth: 12,
    Tenth: 13,
    Eleventh: 14,
    Twelfth: 15,
    AD: 16,
};

export const getGradeAgeRanges = () => {
    const today = new Date();
    const schoolYearBeginningMonth = 8; //javascript month is 0 based for funzies
    const schoolYearBeginningDay = 2;
    
    return {
        [GRADE_LEVEL.IP]: [new Date(today.getFullYear() - 5, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear(), schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.PS]: [new Date(today.getFullYear() - 5, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear(), schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.KG]: [new Date(today.getFullYear() - 6, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear() - 5, schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.First]: [new Date(today.getFullYear() - 7, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear() - 6, schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.Second]: [new Date(today.getFullYear() - 8, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear() - 7, schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.Third]: [new Date(today.getFullYear() - 9, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear() - 8, schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.Fourth]: [new Date(today.getFullYear() - 10, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear() - 9, schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.Fifth]: [new Date(today.getFullYear() - 11, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear() - 10, schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.Sixth]: [new Date(today.getFullYear() - 12, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear() - 11, schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.Seventh]: [new Date(today.getFullYear() - 13, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear() - 12, schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.Eighth]: [new Date(today.getFullYear() - 14, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear() - 13, schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.Ninth]: [new Date(today.getFullYear() - 15, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear() - 14, schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.Tenth]: [new Date(today.getFullYear() - 16, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear() - 15, schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.Eleventh]: [new Date(today.getFullYear() - 17, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear() - 16, schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.Twelfth]: [new Date(today.getFullYear() - 18, schoolYearBeginningMonth, schoolYearBeginningDay), new Date(today.getFullYear() - 17, schoolYearBeginningMonth, schoolYearBeginningDay - 1)],
        [GRADE_LEVEL.AD]: [new Date(0), new Date(today.getFullYear() - 18, schoolYearBeginningMonth, schoolYearBeginningDay - 1)]
    };
};

export const GRADE_LEVEL_LIST = createListFromObject(GRADE_LEVEL);

export const LANGUAGE = {
    English: 1,
    Spanish: 2,
    Other: 3,
    NotApplicable: 4,
};

export const LANGUAGE_LIST = createListFromObject(LANGUAGE);

export const PERIPHERAL_FIELD_LOSS = {
    NotApplicable: 0,
    VisualField1: 1,
    VisualField2: 2,
    VisualField3: 3,
    VisualField4: 4,
    VisualField5: 5,
    VisualField7: 7,
    VisualField8: 8,
    VisualField9: 9,
    VisualField10: 10,
    VisualField11: 11,
    VisualField12: 12,
    VisualField13: 13,
    VisualField14: 14,
    VisualField15: 15,
    VisualField16: 16,
    VisualField17: 17,
    VisualField18: 18,
    VisualField19: 19,
    VisualField20: 20,
};

export const PERIPHERAL_FIELD_LOSS_LIST = createListFromObject(PERIPHERAL_FIELD_LOSS);

export const COMPLETE_READING_MEDIUMS = {
    AuditoryReader: createDescriptionObjectForList("1", "Auditory reader"),
    BrailleReader: createDescriptionObjectForList("2", "Braille reader"),
    PreReader: createDescriptionObjectForList("3", "Pre-reader"),
    SymbolicReader: createDescriptionObjectForList("4", "Symbolic reader"),
    VisualReader: createDescriptionObjectForList("5", "Visual reader"),
    NotApplicable: createDescriptionObjectForList("6", "Not Applicable")
};

export const COMPLETE_READING_MEDIUMS_LIST = createListFromObject(COMPLETE_READING_MEDIUMS);

const PRIMARY_READING_MEDIUM = {
    AuditoryReader: COMPLETE_READING_MEDIUMS.AuditoryReader,
    BrailleReader: COMPLETE_READING_MEDIUMS.BrailleReader,
    PreReader: COMPLETE_READING_MEDIUMS.PreReader,
    SymbolicReader: COMPLETE_READING_MEDIUMS.SymbolicReader,
    VisualReader: COMPLETE_READING_MEDIUMS.VisualReader,
};

export const PRIMARY_READING_MEDIUM_LIST = createListFromObject(PRIMARY_READING_MEDIUM);

const SECONDARY_READING_MEDIUM = {
    AuditoryReader: COMPLETE_READING_MEDIUMS.AuditoryReader,
    BrailleReader: COMPLETE_READING_MEDIUMS.BrailleReader,
    VisualReader: COMPLETE_READING_MEDIUMS.VisualReader,
    NotApplicable: COMPLETE_READING_MEDIUMS.NotApplicable
};

export const SECONDARY_READING_MEDIUM_LIST = createListFromObject(SECONDARY_READING_MEDIUM);
export const OTHER_READING_MEDIUM_LIST = createListFromObject(SECONDARY_READING_MEDIUM);

export const VISUALLY_IMPAIRED_STUDENT_PLAN = {
    IEP: createDescriptionObjectForList("1", "Individual Education Plan (IEP)"),
    IFSP: createDescriptionObjectForList("2", "Individual Family Support Plan (IFSP)"),
    IRP: createDescriptionObjectForList("4", "Individual Rehabilitation Plan"), 
    ServicesPlan: createDescriptionObjectForList("6", "Services Plan"),
    TemporaryIEP: createDescriptionObjectForList("5", "Temporary IEP"),
    WrittenEducationalPlan: createDescriptionObjectForList("7", "Written educational plan"),
    Plan504: createDescriptionObjectForList("3", "504 Plan"),
};

export const VISUALLY_IMPAIRED_STUDENT_PLAN_LIST = createListFromObject(VISUALLY_IMPAIRED_STUDENT_PLAN);

export const VISUAL_ACUITY = {
    TwentyTwentyUp: createDescriptionObjectForList("1", "Between 20/20 and 20/199"),
    TwentyTwoHundredUp: createDescriptionObjectForList("2", "20/200 and above (e.g. 20/400, 20/600, etc.) "),
    Provided: createDescriptionObjectForList("3", "Anophthalmia, NLP, CF, OP, LP, HM or similar provided only "),
    NoAcuityProvided: createDescriptionObjectForList("4", "No acuities provided"),
};

export const VISUAL_ACUITY_LIST = createListFromObject(VISUAL_ACUITY);

export const STUDENT_STATUS = {
    Active: createDescriptionObjectForList("1", "Active"),
    Inactive: createDescriptionObjectForList("2", "Inactive")
};

export const STUDENT_STATUS_LIST = createListFromObject(STUDENT_STATUS);

export const STUDENT_INACTIVE_REASON = {
    Deceased: createDescriptionObjectForList("1", "Deceased"),
    Dismissed: createDescriptionObjectForList("2", "Dismissed"),
    Dropped: createDescriptionObjectForList("3", "Dropped"),
    Graduated: createDescriptionObjectForList("4", "Graduated"),
    Moved: createDescriptionObjectForList("5", "Moved"),
    NoParentalConsent: createDescriptionObjectForList("6", "No Parental Consent"),
    Withdrew: createDescriptionObjectForList("7", "Withdrew")
};

export const STUDENT_INACTIVE_REASON_LIST = createListFromObject(STUDENT_INACTIVE_REASON);

export const STUDENT_APPROVAL_STATUS = {
    Pending: 0,
    Approved: 1,
    NotApproved: 2,
    NeedInfo: 3,
    Deleted: 4,
};

export const STUDENT_APPROVAL_STATUS_LIST = createListFromObject(STUDENT_APPROVAL_STATUS);

export const STUDENT_APPROVAL_STATUS_FOR_SEARCH = {
    Pending: 0,
    Approved: 1,
    NotApproved: 2,
    NeedInfo: 3,
    PendingNeedInfo: 5
};

export const STUDENT_APPROVAL_STATUS_FOR_FIMC_ADMIN_SEARCH = {
    ...STUDENT_APPROVAL_STATUS_FOR_SEARCH,
    Deleted: 4,
};

export const STUDENT_APPROVAL_STATUS_LIST_FOR_SEARCH = createListFromObject(STUDENT_APPROVAL_STATUS_FOR_SEARCH);
export const STUDENT_APPROVAL_STATUS_LIST_FOR_FIMC_ADMIN_SEARCH = createListFromObject(STUDENT_APPROVAL_STATUS_FOR_FIMC_ADMIN_SEARCH);

export const STUDENT_APPROVAL_STATUS_NAMES = createObjectFromObjectPropertyNames(STUDENT_APPROVAL_STATUS);

export function getStudentApprovalStatusName(studentApprovalStatus) {
    switch (studentApprovalStatus) {
        case STUDENT_APPROVAL_STATUS.Pending:
            return `pending`;
        case STUDENT_APPROVAL_STATUS.Approved:
            return `approved`;
        case STUDENT_APPROVAL_STATUS.NotApproved:
            return "not-approved";
        case STUDENT_APPROVAL_STATUS.NeedInfo:
            return "need-info";
        case STUDENT_APPROVAL_STATUS.Deleted:
            return "deleted";
        default:
            return `pending`;
    }
}

export const NO_API_RESULTS = null;

export const STUDENT_ELIGIBILITY = {
    MDB: createDescriptionObjectForList("1", "MDB (meets the definition of blindness)"),
    FDB: createDescriptionObjectForList("2", "FDB (functions at the definition of blindness)"),
    IDEA: createDescriptionObjectForList("3", "IDEA"),
    NoParentalConsent: createDescriptionObjectForList("4", "No Parental Consent"),
};

export const STUDENT_ELIGIBILITY_LIST = createListFromObject(STUDENT_ELIGIBILITY);