import React, {useEffect, useState} from "react";
import {GridCell, gridConstants, GridContainer} from "../../../Components/Grid";
import UserApprovalApi from "./UserApprovalApi";
import PropTypes from "prop-types";
import {Button, ButtonMock} from "../../../Components/Inputs";
import {PageTitle} from "../../../Components/Landmarks";
import UserApprovalView from "./UserApprovalView";
import {convertToNumber, isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {userModel} from "../userFactory";
import UserApprovalSearchFormView from "./UserApprovalSearchFormView";
import {Allow, allow, policyEvents} from "../../../Components/Authorize";
import {APPROVAL_STATUS, getApprovalStatusName} from "../../Constants/approvalConstants";
import {apiConstants} from "../../../Utilities/Api";
import {approveUserModel, userApprovalUpdateResultsModel} from "./userApprovalFactory";
import {locations} from "../../../Utilities/Location";
import {isArrayNullOrEmpty} from "../../../Utilities/Types/arrayUtilities";
import { LayoutFullBleed } from "../../../Components/Layout";

export const UserApprovalContainer = ({
                                          districts,
                                          handleApiCall,
                                          handleSetUserSearchCriteria,
                                          isLoading,
                                          redirect,
                                          setNotificationBatchApiResults,
                                          userSearchCriteria,
                                          user
                                      }) => {


    const [users, setUsers] = useState([]);
    const [initialUsers, setInitialUsers] = useState([]);

    const filteredUsers = userSearchCriteria !== null && userSearchCriteria.ApplySearchAndSorting(userSearchCriteria, users);

    const onApprovalStatusClick = (userId, value) => {
        handleSetUserSearchCriteria({...userSearchCriteria, sortByColumn: ``, loadSearchResults: false});
        setUsers(users.map(user =>
            user.userId === userId
                ? {
                    ...user,
                    approvalStatus: convertToNumber(value),
                    approvalStatusName: getApprovalStatusName(convertToNumber(value))
                }
                : user
        ));
    };

    const handleApproveAllClick = (event) => {
        event.preventDefault();

        if (userSearchCriteria)
            setUsers(users.map(user => {
                const userToApprove = filteredUsers.find(filteredUser => filteredUser.userId === user.userId);
                return userToApprove ? {
                    ...user,
                    approvalStatus: APPROVAL_STATUS.Approved,
                    approvalStatusName: getApprovalStatusName(APPROVAL_STATUS.Approved)
                } : user;
            }));
    };

    const handleSaveClick = (event) => {
        event.preventDefault();

        const usersToUpdate = users.filter(user => {
            const initialUser = initialUsers.find(initial => initial.userId === user.userId);
            return initialUser && initialUser.approvalStatus !== user.approvalStatus;
        }).map(approveUserModel);

        if (usersToUpdate.length > 0) {
            handleApiCall(() => UserApprovalApi.approveUsers(usersToUpdate), (result) => loadUserApprovalUpdateResults(result, usersToUpdate));
        }
    };

    const loadUserApprovalUpdateResults = (result, updatedUsers) => {
        const userApprovalUpdateResults = userApprovalUpdateResultsModel({...result});
        
        const suffix = userApprovalUpdateResults.numberUpdated === 1 ? `` : `s`;
        setNotificationBatchApiResults(
            userApprovalUpdateResults,
            apiConstants.successMessage.approval.APPROVAL_UPDATED,
            `Successfully updated the approval status of ${userApprovalUpdateResults.numberUpdated} user${suffix}.`
        );

        setInitialUsers(users.map(user => {
            const updatedUser = updatedUsers.find(updated => updated.userId === user.userId);
            return updatedUser ? { ...user, approvalStatus: updatedUser.approvalStatus } : user;
        }));
    };

    const loadUsersResults = (result) => {
        const pendingPatrons = result.map(userModel);
        setUsers(pendingPatrons);
        setInitialUsers(pendingPatrons);
    };

    const loadUsers = () => { 
        handleApiCall(() => UserApprovalApi.getUsers({
            ...userSearchCriteria,
        }), loadUsersResults);

    }

    const buttonSharedSettings = {
        medium_columns: gridConstants.column.THREE,
        small_columns: gridConstants.column.SIX,
    };

    const noResults = isArrayNullOrEmpty(filteredUsers);

    useEffect(() => {
        if (isNullOrUndefined(districts) || districts.length === 0) return;
        
        if (userSearchCriteria.loadSearchResults)
            loadUsers(); 

    }, [districts, userSearchCriteria]);

    useEffect(() => {
        if (!allow(policyEvents.VIEW))
            redirect(locations.DASHBOARD.path);
    }, []);

    if (!userSearchCriteria) return null;

    return <>
        <PageTitle h1={`Manage Users`}/>
        <div className={`searchBox`}>
            <div className={`searchBox__title`}>Search for Users</div>
            <UserApprovalSearchFormView
                        buttonSharedSettings={buttonSharedSettings}
                        districts={districts}
                        handleSetUserSearchCriteria={handleSetUserSearchCriteria}
                        isLoading={isLoading}
                        userSearchCriteria={userSearchCriteria}
                        user = {user}
            />
        </div>
        

        <LayoutFullBleed className="catalog">
        <header>
            <h2>Search Results</h2>
          </header>
            <Allow policyEvent={policyEvents.APPROVE_USERS}>
            <GridContainer isReverseOrder className={`form__header`} hasMarginX>
                {
                    !noResults && <>
                        <GridCell {...buttonSharedSettings}>
                            <Button
                                disabled={isLoading}
                                label="Save"
                                name="btnSave"
                                onClick={handleSaveClick}
                                isPrimary
                            />
                        </GridCell>
                        <GridCell {...buttonSharedSettings}>
                            <Button
                                disabled={isLoading}
                                label="Approve all"
                                name="btnApproveAll"
                                onClick={handleApproveAllClick}
                            />
                        </GridCell>
                    </>
                }
                {
                    noResults &&
                    <GridCell><ButtonMock name={`placeholder`} label={`&nbsp;`}/></GridCell>
                }
            </GridContainer>
        </Allow>

        <UserApprovalView
            handleSetUserSearchCriteria={handleSetUserSearchCriteria}
            onApprovalStatusClick={onApprovalStatusClick}
            redirect = {redirect}
            users={filteredUsers}
            userSearchCriteria={userSearchCriteria}
        />
        </LayoutFullBleed>
       

    </>;
};

UserApprovalContainer.propTypes = {
    districts: PropTypes.array,
    handleApiCall: PropTypes.func.isRequired,
    handleSetUserSearchCriteria: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    redirect: PropTypes.func,
    setNotificationBatchApiResults: PropTypes.func.isRequired,
    userSearchCriteria: PropTypes.object,
    user: PropTypes.object
}
