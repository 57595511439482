export const checkInDetailsModel = ({
                                        copyBarcode,
                                        shelfLocation,
                                        volumes,
                                    } = {}) => ({

    copyBarcode: copyBarcode?.endString() ?? ``,
    shelfLocation: shelfLocation?.endString() ?? ``,
    volumes: Array.isArray(volumes)
        ? volumes.map(volume => volumeDetailsModel(volume))
        : [],
});


export const volumeDetailsModel = ({
    barcode,
    shelfLocation,
    status,
} = {}) => ({
    barcode: barcode ?? ``,
    shelfLocation: shelfLocation ?? ``,
    status: status ?? "available", 
});