import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {isArrayNullOrEmpty} from "../../../Utilities/Types/arrayUtilities";
import {PageTitle} from "../../../Components/Landmarks";
import {GridCell, gridConstants, GridContainer} from "../../../Components/Grid";
import {ButtonMock} from "../../../Components/Inputs";
import StudentApprovalView from "./StudentApprovalView";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import StudentApprovalApi from "./StudentApprovalApi";
import {Allow, policyEvents} from "../../../Components/Authorize";
import {
    studentModel
} from "./studentApprovalFactory";
import {locations} from "../../../Utilities/Location";
import {
    STUDENT_APPROVAL_STATUS_FOR_FIMC_ADMIN_SEARCH,
    STUDENT_APPROVAL_STATUS_FOR_SEARCH
} from "../studentConstants";
import StudentApprovalSearchFormView from "./StudentApprovalSearchFormView";
import {isFimcAdmin} from "../../../Utilities/LocalStorage/storageUtilities";
import {isCensusMonthAndAdmin} from "../studentUtilities";
import StudentCensusApi from "../studentCensusApi";
import {LayoutFullBleed} from "../../../Components/Layout";


export const StudentApprovalContainer = ({
                                             districts,
                                             handleApiCall,
                                             handleSetStudentSearchCriteria,
                                             isLoading,
                                             redirect,
                                             //setNotificationBatchApiResults,
                                             studentSearchCriteria,
                                             userId
                                         }) => {

    const [students, setStudents] = useState([]);
    const [censusEndDate, setCensusEndDate] = useState(null);
    const filteredStudents = studentSearchCriteria !== null && studentSearchCriteria.ApplySorting(studentSearchCriteria, students);

    const handleAddStudentClick = (event) => {
        event.preventDefault();
        redirect(locations.REGISTER_STUDENT.path);
    };

    const handleExportStudentsClick = (event) => {
        event.preventDefault();
        handleApiCall(() => StudentCensusApi.exportStudentsReport(studentSearchCriteria));
    };

    const handleEndCensusClick = (event) => {
        event.preventDefault();
        if (confirm(`Are you sure you wanted to end the Census, this will allow the users to add and update students?`))
            handleApiCall(() => StudentCensusApi.setManualCensusEndDate(), getCensusEndDate);
    };

    const handleBeginCensusClick = (event) => {
        event.preventDefault();
        if (confirm(`Are you sure you wanted to begin the Census. This action will update all students with "Student Status: Active" and "Student Approval Status: Approved" to "Student Approval Status: Need Info". Are you sure you want to continue?`))
            handleApiCall(() => StudentCensusApi.prepareStudentsForCensus(), loadStudents);
    };

    const getCensusEndDate = () => {
        handleApiCall(() => StudentCensusApi.getManualCensusEndDate(), result => setCensusEndDate(result.censusEndDate));
    }

    const loadStudentsResults = (result) => {
        const students = result.map(studentModel);
        setStudents(students);
    };

    const loadStudents = () => {
        handleApiCall(() => StudentApprovalApi.getStudentApprovalRequests({
            ...studentSearchCriteria,
            userId
        }), loadStudentsResults);
    };

    const buttonSharedSettings = {
        medium_columns: gridConstants.column.THREE,
        small_columns: gridConstants.column.SIX,
    };

    const noResults = isArrayNullOrEmpty(filteredStudents);

    useEffect(() => {
        if (isNullOrUndefined(districts) || districts.length === 0) return;

        getCensusEndDate();

        if (studentSearchCriteria.loadSearchResults) {
            if (!isFimcAdmin() && studentSearchCriteria.studentApprovalStatus === STUDENT_APPROVAL_STATUS_FOR_FIMC_ADMIN_SEARCH.Deleted.toString()) {
                handleSetStudentSearchCriteria({
                    ...studentSearchCriteria,
                    studentApprovalStatus: STUDENT_APPROVAL_STATUS_FOR_SEARCH.PendingNeedInfo
                });
            }
            loadStudents();
        }

        // eslint-disable-next-line
    }, [districts, studentSearchCriteria]);

    if (!studentSearchCriteria) return null;

    return (
        <>
            <PageTitle h1={`Manage Students`}/>

            <div className={`searchBox`}>
                <div className={`searchBox__title`}>
                    <span>Search for Students</span>
                    <div className="searchBox__actions">
                        {!noResults &&
                            <>
                                <button
                                    className="searchBox__beginCensus"
                                    name="btnExportStudents"
                                    onClick={handleExportStudentsClick}>
                                    Export Students
                                </button>
                                <>&nbsp; | &nbsp;</>
                            </>
                        }
                        {isCensusMonthAndAdmin(censusEndDate) && (
                            <>
                                <button
                                    className="searchBox__beginCensus"
                                    label="Begin Census"
                                    name="btnBeginCensus"
                                    onClick={handleBeginCensusClick}
                                >
                                    Begin Census
                                </button>
                                <>&nbsp; | &nbsp;</>
                            </>
                        )}
                        {isCensusMonthAndAdmin(censusEndDate) && (
                            <>
                                <button
                                    className="searchBox__endCensus"
                                    label="Begin Census"
                                    name="btnBeginCensus"
                                    onClick={handleEndCensusClick}
                                >
                                    End Census
                                </button>
                                <>&nbsp; | &nbsp;</>
                            </>
                        )}


                        <button
                            className="searchBox__addStudents"
                            onClick={handleAddStudentClick}
                            aria-label="Add Student"
                        >
                            Add Student
                        </button>
                    </div>
                </div>

                <StudentApprovalSearchFormView
                    buttonSharedSettings={buttonSharedSettings}
                    districts={districts}
                    handleSetStudentSearchCriteria={handleSetStudentSearchCriteria}
                    isLoading={isLoading}
                    studentSearchCriteria={studentSearchCriteria}
                />
            </div>

            <LayoutFullBleed className="catalog">
            <header>
            <h2>Search Results</h2>
          </header>
                <Allow policyEvent={policyEvents.APPROVE_STUDENTS}>
                    <GridContainer isReverseOrder hasMarginX>
                        {noResults && (
                            <GridCell>
                                <ButtonMock name={`placeholder`} label={`&nbsp;`}/>
                            </GridCell>
                        )}
                    </GridContainer>
                </Allow>
                <StudentApprovalView
                    handleSetStudentSearchCriteria={handleSetStudentSearchCriteria}
                    redirect={redirect}
                    students={filteredStudents}
                    studentSearchCriteria={studentSearchCriteria}
                />
            </LayoutFullBleed>
        </>
    );
};

StudentApprovalContainer.propTypes = {
    districts: PropTypes.array,
    handleApiCall: PropTypes.func.isRequired,
    handleSetStudentSearchCriteria: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
    setNotificationBatchApiResults: PropTypes.func.isRequired,
    studentSearchCriteria: PropTypes.object,
    userId: PropTypes.string.isRequired,
}