import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LayoutFullBleed} from "../../Components/Layout";
import {emptyStudentQueueModel} from "./libraryFactory";
import {getFieldArrayFromObjectArrayByField, isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import libraryQueueApi from "./libraryQueueApi";
import {locations} from "../../Utilities/Location";
import {LibraryQueueForm} from "./LibraryQueueForm";
import {useInputForm} from "../../Components/Inputs";
import OrderRequestApi from "../Order/OrderRequest/orderRequestApi";
import {createListFromArrayOfObjects} from "../../Utilities/Types/listUtilities";
import {LibraryQueueList} from "./LibraryQueueList";
import {getSystemTypeTextByValue} from "./libraryUtility";

export const LibraryQueueContainer = ({
                                          handleApiCall,
                                          redirect,
                                          systemTypeId
                                      }) => {

    const [queue, setQueue] = useState([]);
    const [selectedItems, setSelectedItems] = useState(new Set([]));
    const [students, setStudents] = useState();
    const [shippingLocations, setShippingLocations] = useState();
    const loanForm = useInputForm(emptyStudentQueueModel);
    const pendingQueue = queue.filter(q => q.student === null || q.shippingLocation === null);
    const isPendingQueueEmpty = isArrayNullOrEmpty(pendingQueue);
    const processingQueue = queue.filter(q => q.student !== null && q.shippingLocation !== null);
    const pageTitle = getSystemTypeTextByValue(systemTypeId);

    const handleSelectAll = () => {
        const queueItems = getFieldArrayFromObjectArrayByField(pendingQueue, "queueItemId");
        setSelectedItems(new Set(queueItems));
    }
    const handleDeselectAll = () => {
        setSelectedItems(new Set([]));
    }

    const handleSelectedItemsChange = (event) => {
        const item = event.target.value;
        const updatedSet = new Set(selectedItems || []);

        updatedSet.has(item)
            ? updatedSet.delete(item)
            : updatedSet.add(item);

        setSelectedItems(updatedSet);
    }

    const handleViewOrder = (orderId, catalogItemId) => redirect(locations.ORDER_VIEW.getLink([orderId, catalogItemId]).to);

    const handleGetQueue = () => {
        handleApiCall(() => libraryQueueApi.getQueue(systemTypeId), (results) => setQueue(results));
    }

    const handleRemoveFromQueue = (queuedItemId) => {
        handleApiCall(() => libraryQueueApi.removeFromQueue(queuedItemId), handleGetQueue);
    }

    const handleAssignQueueItems = async (studentQueueViewModel) => {
        studentQueueViewModel.queueItems = Array.from(selectedItems);
        handleApiCall(() => libraryQueueApi.assignQueueItems(studentQueueViewModel), handleGetQueue);
        setSelectedItems(new Set([]));
    }

    useEffect(() => {

        const getStudents = () => {
            handleApiCall(() => OrderRequestApi.getStudents(), (results) => {
                setStudents(createListFromArrayOfObjects(results, `studentId`, `fullName`));
            });
        };

        const getShippingLocations = () => {
            handleApiCall(() => OrderRequestApi.getShippingLocations(), (results) => {
                setShippingLocations(createListFromArrayOfObjects(results, `shippingLocationId`, `name`));
            });
        }

        if (!shippingLocations)
            getShippingLocations();
        if (!students)
            getStudents();

        handleGetQueue();
    }, []);

    if (!shippingLocations || !students || !loanForm) return;

    return <>
        <PageTitle h1={`${pageTitle} Queue`}/>

        <LayoutFullBleed className={`catalog`}>
            {
                pendingQueue &&
                <LibraryQueueList
                    handleDeselectAll={handleDeselectAll}
                    handleRemoveFromQueue={handleRemoveFromQueue}
                    handleSelectAll={handleSelectAll}
                    handleSelectedItemsChange={handleSelectedItemsChange}
                    queue={pendingQueue}
                    selectedItems={selectedItems}
                />
            }

            {
                !isPendingQueueEmpty &&
                <LibraryQueueForm
                    handleAssignQueueItems={handleAssignQueueItems}
                    loanForm={loanForm}
                    shippingLocations={shippingLocations}
                    students={students}
                />
            }
            {
                processingQueue &&
                <LibraryQueueList
                    handleRemoveFromQueue={handleRemoveFromQueue}
                    handleViewOrder={handleViewOrder}
                    heading={`Processing`}
                    queue={processingQueue}
                />
            }
        </LayoutFullBleed>
    </>;
};

LibraryQueueContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    systemTypeId: PropTypes.string.isRequired,
};