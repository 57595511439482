import {
    Button, checkAndSetError, clearInputFormErrorFields, convertInputFormToObject, InputForm, inputFormIsValid,
    useInputForm
} from "../../../Components/Inputs";
import {GridCell} from "../../../Components/Grid";
import PropTypes from "prop-types";
import React from "react";
import {OrderProcessingForm} from "./OrderProcessingForm";
import {orderProcessingModel} from "./orderProcessingFactory";
import {orderProcessingFormVisibility} from "./OrderProcessingVisibilityFactory";
import {getButtonLabelFromOrderStatus} from "../orderUtility";
import {HtmlDisplay} from "../../../Components/Display";
import {OrderPrintBarcode} from "./OrderPrintBarcode";

export const OrderProcessingView = ({
                                        orderDetails,
                                        handlePlaceAnOrder,
                                        handleReturn,
                                        handleReceiveOrder,
                                        handleFulfillOrder
                                    }) => {

    const formVisibility = orderProcessingFormVisibility({orderDetails});

    const model = orderProcessingModel({
        orderId: orderDetails.orderId,
        formatOrdered: orderDetails.alternateFormat,
        orderType: orderDetails.orderType,
        isOrderComplete: orderDetails.isOrderComplete
    })
    const orderProcessingForm = useInputForm(model);

    const handleSubmitClick = () => {
        clearInputFormErrorFields(orderProcessingForm);

        if (formVisibility.canPlaceAnOrder) {
            checkAndSetError([
                orderProcessingForm.alternateFormat,
                orderProcessingForm.orderStatus,
            ]);
        }

        if (formVisibility.canReceiveOrder) {
            checkAndSetError([orderProcessingForm.numberOfVolumesReceived]);

        }

        if (inputFormIsValid(orderProcessingForm)) {
            const orderProcessingData = convertInputFormToObject(orderProcessingForm);
            if (formVisibility.canPlaceAnOrder) {
                handlePlaceAnOrder(orderProcessingData);
            } else if (formVisibility.canReceiveOrder) {
                handleReceiveOrder(orderProcessingData);
            }
        }
    }

    const handleFulfillOrderClick = () => {
        handleFulfillOrder();
    }

    return <>
        {
            !formVisibility.isOrderComplete &&
            <InputForm name={`orderProcessingForm`}>

                {
                    !formVisibility.canFulfillOrder &&
                    <OrderProcessingForm
                        orderProcessingForm={orderProcessingForm}
                        orderDetails={orderDetails}
                        formVisibility={formVisibility}/>
                }
                {
                    formVisibility.displayBarcode &&
                    <OrderPrintBarcode
                        barcodes={orderDetails.volumes}/>
                }
                <GridCell className={`text-center`}>
                    {
                        !formVisibility.canFulfillOrder &&
                        <Button
                            isPrimary isCollapsed
                            label={'Save'}
                            name={`btnSubmit`}
                            onClick={handleSubmitClick}/>
                    }
                    {
                        formVisibility.canFulfillOrder &&
                        <Button
                            isPrimary isCollapsed
                            label={getButtonLabelFromOrderStatus(orderDetails.orderStatus)}
                            name={`btnFulfillOrder`}
                            onClick={handleFulfillOrderClick}/>
                    }
                    <Button
                        isCollapsed
                        label={'Go Back'}
                        name={`btnGoBack`}
                        onClick={handleReturn}/>
                </GridCell>
            </InputForm>
        }
        {
            formVisibility.isOrderComplete &&
            <GridCell className={`text-center`}>
                <HtmlDisplay html={`<br/><h3>Order successfully completed!</h3>`}/>
            </GridCell>
        }
    </>;
};

OrderProcessingView.propTypes = {
    handleReturn: PropTypes.func.isRequired,
    handlePlaceAnOrder: PropTypes.func.isRequired,
    orderDetails: PropTypes.object.isRequired,
    handleFulfillOrder: PropTypes.func.isRequired,
    handleReceiveOrder: PropTypes.func.isRequired
};